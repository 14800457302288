<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }"
         @touchstart="startTouch" 
         @touchend="endTouch">
     <div v-if="isIOSApp()" class="ios-status-bar-padding"></div>
    <v-main>


      <div v-if="isInspirationActive" class="inspiration-header">

        <v-col cols="12" class="text-left">
        <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
        <h1 class="app-title">
          <span class="underline">Perfume</span> Prophet.
        </h1>
      </v-col>
      
        <div class="inspiration-menu">
          <v-btn v-for="route in inspirationRoutes" :key="route.path"
       :to="route.path"
       class="mobile-filter-option-button"
       :class="{ active: $route.path === route.path }"
       @click="saveLastInspirationRoute(route.path)">
        <v-icon size="20" :style="{ color: $route.path === route.path ? 'white' : 'grey' }" class="icon-padding">
          {{
            route.path === '/explore-notes' ? ($route.path === route.path ? 'mdi-flower' : 'mdi-flower-outline') :
            route.path === '/explore-perfumes' ? ($route.path === route.path ? 'mdi-camera' : 'mdi-camera-outline') :
            route.path === '/explore' ? ($route.path === route.path ? 'mdi-set-center' : 'mdi-set-center') :
            ''
          }}
        </v-icon>
        {{ route.label }}
      </v-btn>

        </div>
      </div>

      <div v-if="isFavoriteActive" class="inspiration-header">

      <v-col cols="12" class="text-left">
      <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
      <h1 class="app-title">
        <span class="underline">Perfume</span> Prophet.
      </h1>
      </v-col>

      <div class="inspiration-menu">
  <v-btn v-for="route in FavoriteRoutes" 
         :key="route.path"
         :to="route.path"
         class="mobile-filter-option-button"
         :class="{ active: $route.path === route.path }"
         @click="saveLastFavoriteRoute(route.path)">

    <!-- Heart Icon with Padding -->
    <v-icon size="22px" :color="$route.path === route.path ? 'white' : 'grey'" class="icon-padding">
      {{
        route.path === '/favorites' ? ($route.path === route.path ? 'mdi-heart' : 'mdi-heart-outline') :
        route.path === '/saved-searches' ? ($route.path === route.path ? 'mdi-set-center' : 'mdi-set-center') :
        ''
      }}
    </v-icon>


    {{ route.label }}
  </v-btn>
</div>



      </div>

      <keep-alive include="ExplorePage, ExploreNotes, ExplorePerfumes, PerfumeDataTable, Favorites, SavedSearchesList">
      <router-view />
    </keep-alive>
    </v-main>

    <!-- Bottom Navigation Bar -->
<v-bottom-navigation v-if="showNavBar" app fixed grow class="bottom-nav-bar"
   :style="isIOSApp() ? 'height: 74px; padding-bottom: 92px;' : 'height: 74px;'"
>
  <!-- Inspiration Button -->
  <v-btn
    @click="navigateToLastInspirationRoute"
    class="nav-btn active-btn" style="min-width: 70px; padding: 0;"
    :class="{ active: isInspirationActive }"
  >
    <span>Inspiration</span>
    <span style="display: block; height: 3px; color: transparent;"> .</span>
    <v-icon style="font-size: 27px;">{{ isInspirationActive ? 'mdi-lightbulb' : 'mdi-lightbulb-outline' }}</v-icon>
    
    

  </v-btn>

  <!-- Search Button -->

<v-btn
  :to="{ path: '/perfumes' }"
  class="nav-btn" style="min-width: 70px; padding: 0;"
  :class="{ active: $route.path === '/perfumes' }"
>
  <span>Search</span>
  <span style="display: block; height: 3px; color: transparent;">.</span>
  <!-- Conditional rendering of SVG image or mdi icon -->
  <img v-if="$route.path === '/perfumes'" src="@/assets/icons/magnify-filled.svg" style="height: 27px; width: 27px;">
  <img 
  v-else 
  src="@/assets/icons/magnify.svg" 
  style="height: 27px; width: 27px; filter: invert(0%) opacity(0.65); margin-left: 0px" />
  
</v-btn>


<!-- Upload Shots Button -->
<v-btn
      :to="{ path: '/uploadimages' }"
      class="nav-btn" style="min-width: 70px; padding: 0;"
      :class="{ active: $route.path === '/uploadimages' }"
    >

       <span>Share Shots</span>
       <span style="display: block; height: 3px; color: transparent;"> .</span>
      <v-icon style="font-size: 27px;">{{ $route.path === '/uploadimages' ? 'mdi-camera' : 'mdi-camera-outline' }}</v-icon>
      
     
  </v-btn>

  <!-- Favorites Button -->
  <v-btn
    @click="navigateToLastFavoriteRoute"
    class="nav-btn active-btn" style="min-width: 70px; padding: 0; padding-left: 0px; "
    :class="{ active: isFavoriteActive }"
  >
     <span>Favorites</span>
     <span style="display: block; height: 3px; color: transparent;"> .</span>
    <v-icon style="font-size: 27px;">{{ isFavoriteActive ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>


  </v-btn>

 <!-- Blog Button -->
<v-btn
  v-if="!isMobile"
  @click="openBlog"
  class="nav-btn"
  style="min-width: 70px; padding: 0; padding-left: 0px;"
>
  <span>Blog</span>
  <span style="display: block; height: 3px; color: transparent;"> .</span>
  <v-icon style="font-size: 27px;">mdi-newspaper-variant-outline</v-icon>
</v-btn>


  

<v-menu
  v-model="moreMenuOpen"
  offset-y
  :close-on-content-click="false"
  @click:outside="moreMenuOpen = false"
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      v-bind="attrs"
      v-on="on"
      class="nav-btn"
      style="min-width: 70px; padding: 0; text-align: center; margin-left: -7px"
    >
      <span style="font-size: 11px;">More</span>
      <span style="display: block; height: 3px; color: transparent;"> .</span>
      <v-icon style="font-size: 27px;">mdi-menu</v-icon>
    </v-btn>
  </template>



  <v-list class="more-menu">
    <!-- Share (only in iOS App) -->
    <v-list-item v-if="isIOSApp()" @click="shareApp">
      <v-list-item-icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Share</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

        <!-- Share (only in iOS App) -->
        <v-list-item v-if="!isIOSApp() && isIOSBrowser()" @click="openAppStore">
      <v-list-item-icon>
        <v-icon>mdi-apple</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Get iOS App</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

     <!-- Blog -->
    <v-list-item v-if="isMobile" @click="openBlog">
      <v-list-item-icon>
        <v-icon>mdi-newspaper-variant-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Blog</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    
    <!-- Instagram -->
    <v-list-item @click="openInstagram">
      <v-list-item-icon>
        <v-icon>mdi-instagram</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Instagram</v-list-item-title>
      </v-list-item-content>
    </v-list-item>



    <!-- Support -->
    <v-list-item @click="toggleSupportDialog">
      <v-list-item-icon>
        <v-icon>mdi-star-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Support me</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

   <!-- Info -->
    <v-list-item @click="toggleInfoDialog">
      <v-list-item-icon>
        <v-icon>mdi-information-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="font-size: 14px;">Info</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    

  </v-list>
</v-menu>

  <!-- Overlay -->
  <div v-if="moreMenuOpen" class="menu-overlay" @click="moreMenuOpen = false"></div>


</v-bottom-navigation>

<v-dialog v-model="isInfoDialogOpen" width="650">
  <v-card class="dialog-card">
    <!-- Sticky Header with Close Button -->
    <v-card-title class="dialog-header">
      <v-spacer></v-spacer>
      <v-btn icon @click="isInfoDialogOpen = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Scrollable Content -->
    <v-card-text class="dialog-content">
      <LegalInfo />
    </v-card-text>

    <!-- Sticky Footer with OK Button -->
    <v-card-actions class="dialog-footer">
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="dialog-button" @click="isInfoDialogOpen = false">Ok</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


<!-- Info Dialog -->

<v-dialog v-model="isSupportDialogOpen" width="650">
  <v-card class="dialog-card">
    <!-- Sticky Header with Close Button -->
    <v-card-title class="dialog-header">
      <v-spacer></v-spacer>
      <v-btn icon @click="isSupportDialogOpen = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Scrollable Content -->
    <v-card-text class="dialog-content">
      <SupportInfo />
    </v-card-text>

    <!-- Sticky Footer with OK Button -->
    <v-card-actions class="dialog-footer">
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="dialog-button" @click="isSupportDialogOpen = false">Ok</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>



    <cookie-consent />

  
  </v-app>
</template>


<script>
import LegalInfo from './components/LegalInfo.vue';
import SupportInfo from './components/SupportInfo.vue';
import CookieConsent from './components/CookieConsent.vue';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';



export default {
  name: 'App',
  data() {
    return {
      isInfoDialogOpen: false,
      isSupportDialogOpen: false,
      scrollY: 0, // Track the current scroll position
      toolbarVisible: true,
      lastScrollY: 0,
      buttonBottom: '85px',
      isMobile: false,
      showNavBar: true, 
      savedScrollPositions: {}, // Map URLs to scroll positions
      startX: null, // Initial touch X position for swipe detection
      startY: null,  // Initial touch Y position for swipe detection
      moreMenuOpen: false,
      inspirationRoutes: [
        { path: '/explore', label: 'Combos' },
        { path: '/explore-perfumes', label: 'Shots' },
        { path: '/explore-notes', label: 'Notes' }
      ],
      FavoriteRoutes: [
        { path: '/favorites', label: ' Perfumes' },
        { path: '/saved-searches', label: 'Search Combos' }
      ],
      lastInspirationRoute: '/explore', // Default value
      lastFavoriteRoute: '/favorites' // Default value
    };
  },

  components: {
    LegalInfo,
    CookieConsent,
    SupportInfo
  },
  
  computed: {
    isInspirationActive() {
      return ['/explore', '/explore-perfumes', '/explore-notes'].includes(this.$route.path);
    },
    isFavoriteActive() {
      return ['/favorites', '/saved-searches'].includes(this.$route.path);
    },


     hasQueryParams() {
    // Check if there are any query parameters in the current route
    return Object.keys(this.$route.query).length > 0;
  },

  blogUrl() {
    return process.env.VUE_APP_BLOG_URL;
  },
  

  
  },

  methods: {
  // Detect the start of the touch (initial position)
   startTouch(event) {
    if (this.isIOSApp()) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      console.log('App.vue; Start touch detected: ', this.startX, this.startY); // Debugging log
    }
  },
endTouch(event) {
  if (this.isIOSApp() && this.startX !== null && this.startY !== null) {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;

    const diffX = this.startX - endX;
    const diffY = this.startY - endY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (diffX > 50) {
        console.log('App.vue; Swipe left: Go forward');  // Debugging log
        this.$router.go(1); // Go forward
      } else if (diffX < -50) {
        // Check if going back would reach the StartPage
        if (this.$route.path !== '/' || !this.isIOSApp()) {
          console.log('App.vue; Swipe right: Go back');  // Debugging log
          this.$router.go(-1); // Go back only if not at StartPage on iOS
        }
      }
    }

    // Reset the start positions
    this.startX = null;
    this.startY = null;
  }
},

  navigateToLastInspirationRoute() {
      if (this.$route.path !== this.lastInspirationRoute) {
        this.$router.push({ path: this.lastInspirationRoute });
      }
    },

    navigateToLastFavoriteRoute() {
      if (this.$route.path !== this.lastFavoriteRoute) {
        this.$router.push({ path: this.lastFavoriteRoute });
      }
    },
  
  toggleInfoDialog() {
    this.isInfoDialogOpen = !this.isInfoDialogOpen;
  },

    toggleSupportDialog() {
    this.isSupportDialogOpen = !this.isSupportDialogOpen;
  },

  
   handleScroll() {
      const currentScrollY = window.scrollY;

      // Save the scroll position for the current route
      const currentUrl = this.$route.fullPath; // Full path includes query parameters
      this.savedScrollPositions[currentUrl] = currentScrollY;

     // console.log(`Saved scroll position for ${currentUrl}: ${currentScrollY}`); //

      this.closeMoreMenuOnScroll();
    },


  onResize() {
    this.isMobile = window.innerWidth < 560;
    this.$forceUpdate();
  },
  updateNavBarVisibility() {
      if (this.isInspirationActive || this.isFavoriteActive) {
        this.showNavBar = true;
      } else {
        const allowedRoutes = ['/perfumes', '/favorites', '/uploadimages'];
        this.showNavBar = allowedRoutes.includes(this.$route.path);
      }
    },
    

saveLastInspirationRoute(path) {
      this.lastInspirationRoute = path;
      sessionStorage.setItem('lastInspirationRoute', path);
    },

  loadLastInspirationRoute() {
    const lastRoute = sessionStorage.getItem('lastInspirationRoute');
    return lastRoute ? lastRoute : '/explore';
  },

  saveLastFavoriteRoute(path) {
      this.lastFavoriteRoute = path;
      sessionStorage.setItem('lastFavoriteRoute', path);
    },

  loadLastFavoriteRoute() {
    const lastRoute = sessionStorage.getItem('lastFavoriteRoute');
    return lastRoute ? lastRoute : '/favorites';
  },


restoreScrollPosition() {
      const currentUrl = this.$route.fullPath; // Full path includes query parameters
      const savedScrollY = this.savedScrollPositions[currentUrl];

   console.log('App.vue; restoreScrollPosition called');
   console.log('App.vue; Current route:', currentUrl);
   console.log('App.vue; Saved scroll Y:', savedScrollY);

   if (
    (this.$route.path === '/perfumes' ||
     this.$route.path === '/uploadimages' ||
     this.$route.path === '/favorites' ||  
     this.$route.path === '/saved-searches' ||  
     this.$route.path === '/explore'||  
     this.$route.path === '/explore-perfumes'||
     this.$route.path === '/explore-notes') && 
    savedScrollY === undefined
  ) {
    console.log(`App.vue; Resetting scroll position to top for ${this.$route.path}`);
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      console.log('TopJump by App RestoreScrollPosition'); // Add console log
      
    });
  } else if (savedScrollY !== undefined) {
    this.$nextTick(() => {
      console.log(`App.vue; Restoring scroll to ${savedScrollY}`);
      window.scrollTo(0, savedScrollY);
    });
  } else {
    console.log('App.vue; No saved scroll position found.');
  }
},

async openBlog() {
  const blogUrl = process.env.VUE_APP_BLOG_URL;

  if (this.isIOSApp()) {
    // Open blog inside in-app browser on iOS
    await Browser.open({ url: blogUrl });
  } else {
    // Normal behavior on web
    window.location.href = blogUrl;
  }
},


isIOSApp() {
  const platform = Capacitor.getPlatform();
  // Debugging log /// console.log('Current platform: ', platform); // Debugging log
  return Capacitor.isNativePlatform() && platform === 'ios';
},

isOnlyItemsChanged(from, to) {
    // Create copies of the query objects without the 'items' parameter
    const fromQuery = {...from.query};
    const toQuery = {...to.query};
    delete fromQuery.items;
    delete toQuery.items;

    // Convert query objects to strings for comparison
    const fromQueryStr = JSON.stringify(fromQuery);
    const toQueryStr = JSON.stringify(toQuery);

    // Check if queries are the same and 'items' is the only difference
    return fromQueryStr === toQueryStr && from.query.items !== to.query.items;
  },

 async openInstagram() {
  const instagramUrl = 'https://www.instagram.com/perfume.prophet';

  if (this.isIOSApp) {
    // Open in the in-app browser on iOS/Android
    await Browser.open({ url: instagramUrl });
  } else {
    // Open in the same browser tab on web
    window.open(instagramUrl, '_self');
  }
},


shareApp() {
  if (navigator.share) {
    navigator.share({
      title: 'Check out Perfume Prophet!',
      text: 'Find your perfect fragrance with Perfume Prophet!',
      url: 'https://www.perfumeprophet.com',
    })
    .then(() => console.log('Successful share'))
    .catch((error) => console.error('Error sharing:', error));
  } else {
    alert('Sharing not supported on this device.');
  }
},

closeMoreMenuOnScroll() {
    if (this.moreMenuOpen) {
      this.moreMenuOpen = false;
      console.log("App.vue; More Menu closed due to scrolling"); // Debug log
    }

  },



},

  created() {
      this.lastInspirationRoute = sessionStorage.getItem('lastInspirationRoute') || '/explore';
      this.lastFavoriteRoute = sessionStorage.getItem('lastFavoriteRoute') || '/favorites';
  },

  mounted() {
  //console.log("App.vue; App is mounted!");  // Add this to check if logs are showing at all
  console.log('App.vue; Current route:', this.$route.fullPath);
  
  
  this.onResize();
  window.addEventListener('resize', this.onResize);
  window.addEventListener('scroll', this.handleScroll);
  window.addEventListener('scroll', this.closeMoreMenuOnScroll);

  this.updateNavBarVisibility();
  

  if (this.isIOSApp() && this.$route.path === '/') {
    this.$router.push('/perfumes');
  }

  // Force scroll to top on first load
  this.$nextTick(() => {
    setTimeout(() => {
      console.log("App.vue; Forcing scroll to top on iOS start");
      window.scrollTo(0, 0);
    }, 100); // Delay to ensure rendering
  });

  // Restore scroll position if navigating back to an Inspiration route
  if (this.isInspirationActive) {
    //console.log('App.vue; Restoring scroll position for Inspiration route');
    this.restoreScrollPosition();
  }
},

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
    
  },

  
watch: {
  $route(to, from) {
    this.updateNavBarVisibility();
 

    console.log('App.vue; From route:', from.fullPath);
    console.log('App.vue; To route:', to.fullPath);

    // Restore scroll position when the app is loaded
       // Check if the only difference in the route query is the 'items' parameter
       if (this.isOnlyItemsChanged(from, to)) {
      console.log('Change triggered by Load More button; skipping scroll restoration.');
    } else {
      this.restoreScrollPosition();
    }
    if (this.isInspirationActive) {
      this.saveLastInspirationRoute(to.path);
      }
    if (this.isFavoriteActive) {
      this.saveLastFavoriteRoute(to.path);
      }

    // Apply Cloudflare caching optimizations ONLY for `/explore-perfumes`
    if (this.isIOSApp()) {
      if (to.path === "/explore-perfumes") {
        this.enableOptimizedCaching();
      } else if (from.path === "/explore-perfumes") {
        // When leaving `/explore-perfumes`, reset cache settings
        this.resetWebViewCache();
      }
    }
    

  
  },
},


};



</script>


<style>


.bottom-nav-bar {
  padding-left: 7px;
  padding-right: 2px;
  position: fixed;
  bottom: 0 !important;
  width: 100%;
  border-top: 1px solid #ccc;
  z-index: 50 !important;
}

.nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #373737 !important;
  background-color: transparent !important;
  margin-top: 0px; /* Adjust to 15px in Dev Mode, and 0px for prod*/
  z-index: 5 !important ;
  flex: 1;
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 14px !important;
  
}




.nav-btn.active {
  color: black !important; /* Set text and icon color to black */
  font-weight: bold !important; /* Make the text bold */
  z-index: 10 !important ;
  background-color: none;
  border-radius: 0px !important;
}


.nav-btn.active::after {
  /* content: "" !important;  /* Empty content for the line */
  position: absolute !important; /* Position absolute to the button */
  bottom: 2px!important; /* Position the line below the button */
  left: 50% !important; /* Start positioning from the middle */
  transform: translateX(-50%) !important; /* Center the line horizontally */
  width: 75% !important; /* Set the width of the line */
  height: 3px !important; /* Thickness of the line */
  color: black !important; 
  background-color: black !important; /* Color of the line */
  z-index: 15 !important ;
}

/* Only apply styles to the Inspiration button when active */
.nav-btn.active-btn.active .v-icon {
  color: black !important; /* Change icon color to black */

}

.nav-btn.inspiration-btn.active span {
  color: black !important; /* Change text color to black */
}


.nav-btn span {
  font-size: 11px; /* Text size remains the same */
}

.v-bottom-navigation__btn {
  padding: 0 !important;
}

/* Dialog Card */
.dialog-card {
  border-radius: var(--radius-basic);
  max-height: 650px;
  display: flex;
  flex-direction: column;
}

/* Sticky Header */
.dialog-header {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 100;
}

/* Scrollable Content */
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  color: black;
  font-size: 16px;
  line-height: 1.5;
  background-color: white;
  max-height: 500px; /* Ensures the content area can scroll */
}

/* Sticky Footer */
.dialog-footer {
  border-top: 1px solid #ccc;
  padding: 10px 20px;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

.ios-status-bar-padding {
  height: 55px; /* Adjust height as needed */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--v-background-base); /* Match the app background */
  z-index: 100; /* Keep above content */
}

/* Dark overlay when menu is open */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
  z-index: 200; /* Below menu but above everything else */
}

/* Ensure the More menu is positioned well */
.more-menu {
  min-width: 120px;
  z-index: 999 !important; /* Above overlay */
}

.more-menu .v-list-item__icon {
  margin-right: 15px !important; /* Adjust spacing between icon and text */
}

.more-menu .v-list-item__content {
  padding-left: 0px !important; /* Reduce left padding of text */
}



.inspiration-menu {
  display: flex;
  position: sticky;
  top: 0;
  gap: 3px;
  background: white;
  z-index: 10;
  padding: 12px;
  padding-bottom: 5px;

}

.inspiration-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 15;
  padding: 3px;
  padding-left: 12px;

}

.icon-padding {
  padding-right: 4px; /* Adjust padding as needed */
}




</style>
