<template>
    <div class="legal-info">

       <h3>Support this project</h3>
      <br>
      <p>
        My goal is to keep Perfume Prophet clean, ad-free, and enjoyable to use. 
      <br> <br>
        Running this project costs around €100 per month for servers, hosting, IT expenses, and hours of development time. While affiliate links help cover some of these costs, a small donation from you would go a long way in supporting the project.
      <br><br>
  <a href="https://www.paypal.com/donate/?hosted_button_id=N8LMY4R4W3FPY" target="_blank" style="text-decoration: none;">
      <div class="email-section" style="display: flex; align-items: center; margin-top: 10px; background-color: rgba(255, 0, 0, 0.15); padding: 5px; border-radius: var(--radius-buttons); color: black; font-weight: bold; font-size: 16px;">
          <v-icon color="red" class="mr-2">mdi-heart</v-icon>
          <span style="padding: 2px 4px;">Support me</span>
      </div>
  </a>
      <br>
       <p style="font-style: italic;">
        Thank you for helping make Perfume Prophet a delightful experience for everyone!
    </p>



    
      

      <br>
      <br>
      <h3>Feedback</h3>
      <br>
      <p>
        I want Perfume Prophet to be the easiest and fastest way to find a great fragrance online. Your feedback means the a lot to me and helps make it better every day.
      <br> <br>

      <div class="email-section">
        <v-icon color="black" class="mr-2">mdi-email-outline</v-icon>
        <a href="mailto:mail@perfumeprophet.com" class="email-link">Write me a mail</a>
      </div>
      <br><br>

      
    </div>
  </template>
  
  <script>
  export default {
    name: 'SupportInfo',
  };
  </script>
  
  <style scoped>
  .legal-info {
    padding: 20px;
    color: rgb(24, 24, 24);
    border: 0px solid #ddd;
    border-radius: var(--radius-basic);
    margin-top: 5px;
  }
  .legal-info h4 {
    margin-top: 0;
  }

  .email-section {
  display: flex;
  align-items: center;
  margin-bottom: 16px; /* Add space below the section */
}

.email-link {
  color: black; /* Set text color */
  text-decoration: none; /* Remove underline from the link */
  font-weight: bold; /* Make the text bold */
  font-size: 16px; /* Adjust font size as needed */
}

  </style>
  