import { render, staticRenderFns } from "./FavoritesToggle.vue?vue&type=template&id=7407c526&scoped=true"
import script from "./FavoritesToggle.vue?vue&type=script&lang=js"
export * from "./FavoritesToggle.vue?vue&type=script&lang=js"
import style0 from "./FavoritesToggle.vue?vue&type=style&index=0&id=7407c526&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7407c526",
  null
  
)

export default component.exports