<template>

  <v-app> 
  
    <v-container>

 
  <!-- Detection for Env, Location and API
      <div    
      style="
        position: fixed;
        top: 40px;
        left: 60px;
        font-size: 32px;
        font-weight: bold;
        color: red;
        z-index: 9999;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border-radius: var(--radius-buttons);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      "
    >
    <div>Country Code: {{ userCountryCode }}</div>
      <div>API Endpoint: {{ apiUrl }}</div>
      <div>Env: {{ environment }}</div>
    </div> 
    -->

    <v-progress-circular
      v-if="isLoading"
      indeterminate
      color="primary"
       class="progress-center"
    ></v-progress-circular>

    <!-- Offline Warning (only when triggered by click) -->
    <div v-if="showOfflineWarning" class="offline-overlay" @click="showOfflineWarning = false">
      <v-card class="offline-alert" @click.stop>
        <v-icon class="offline-icon">mdi-wifi-off</v-icon>
        <span>You are offline. Please check your internet connection.</span>
      </v-card>
    </div>


    <start-instructions 
  v-if="showStartInstructions && isMobile" 
  @close-dialog="showStartInstructions = false" 
/>




        <div>
          <v-app-bar v-if="!isMobile" app color="white" elevation="0" class="app-bar-header" :style="{ paddingTop: 'env(safe-area-inset-top)' }">
            <div class="header-background"> <!-- Added this div -->
            <v-container class="header-container">
              <h1 
                  class="header-title" 
                  style="cursor: pointer;" 
                  @click="openResetDialog"
                >
                  <span class="underline">Perfume</span> Prophet.
                  <v-icon v-if="filteredPerfumes.length < perfumes.length - 1" left color="black">mdi-restart</v-icon>
                </h1>

                <!-- Reset Dialog -->
              <div v-if="showResetDialog" class="note-dropdown visible">
                <!-- Dialog Options -->
                <div class="dropdown-option" @click="confirmResetFilters">
                  <v-icon left color="white">mdi-restart</v-icon>
                  Start new search
                </div>
              
              </div>

                <!-- Overlay -->
                <div
                  v-if="showResetDialog"
                  class="dropdown-overlay"
                  @click="closeResetDialog"
                ></div>


  
              <v-container fluid>
                <v-row>
                  <v-col cols="11">
                    <v-card flat>
                    



        <v-card-text class="search-bar-container">
                <!-- Search Bar -->
                <v-text-field
                v-model="preQuery"
                type="search"
                clearable
                solo-inverted
                flat                
                hide-details
                label=""
                prepend-inner-icon="mdi-magnify"
                class="custom-search-field"
                background-color="#efefef"
                color="black"
                placeholder="Search for brand, name, or perfume notes"
                @focus="handleFocus"
                @input="onInput"
                @blur="hideInstructionDropdown"
                @keydown.enter="triggerSearch"
                ref="searchInput"
                enterkeyhint="search"
                style="border-bottom-left-radius: var(--radius-search);border-top-left-radius: var(--radius-search);"         
              
                ></v-text-field>

                <!-- Instruction Dropdown -->
                <div v-if="showInstructionDropdown" class="dropdown-option-alternative">
                  <p class="instruction-text">
                    Search your favorite brand, perfume or notes like 
                    <i class="mdi mdi-leaf" style="color:#4CAF50;"></i> <!-- Herbal (Green) --> Patchouli, 
                    <i class="mdi mdi-flower" style="color:#C62828;"></i> <!-- Floral (Rose Pink) --> Rose, or 
                    <i class="fa-solid fa-lemon" style="color:#FFD300;"></i> <!-- Citrus (Gold) --> Lemon 
                    <b> — or mix them all to find your perfect match!</b>
                </p>

                </div>

                <!-- Auto-Suggestion Dropdown -->
                <div v-if="suggestions.length && !showInstructionDropdown" class="auto-suggestion-dropdown">
                  <ul>
                    <li v-for="(suggestion, index) in suggestions" :key="index" @click="applySuggestion(suggestion.suggestion)">
                      {{ suggestion.suggestion }}
                    </li>
                  </ul>
                </div>
              </v-card-text>


                    </v-card>
                  </v-col>
                  <v-col cols="1">
                   <v-btn 
                    icon 
                    @click="toggleDrawer" 
                    class="filter-button"
                    color="white"
                    dark
                    :ripple="false"   
                   >
                    <v-icon dark>mdi-tune</v-icon>
                    <v-badge
                      v-if="activeFilterCount > 0"
                      :content="activeFilterCount"                      
                      color="#DC3545"
                      offset-y="28"
                      offset-x="1"
                      :class="{ 'blink-badge': isBlinking }"
                      
                    ></v-badge>
                  </v-btn>

                  </v-col>
                </v-row>
              </v-container>
              
            </v-container>
          </div>
          
        </v-app-bar>
        
                  
          <div v-else>

             <!-- Conditional padding for iOS Native App -->
            <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->

            <h1 class="header-title-mobile" style="cursor: pointer; padding-top: env(safe-area-inset-top);" 
                  @click="openResetDialog">
                <span class="underline">Perfume</span> Prophet. 
                <v-icon v-if="filteredPerfumes.length < perfumes.length - 1" left color="black">mdi-restart</v-icon>
              </h1>

              <!-- Reset Dialog -->
              <div v-if="showResetDialog" class="note-dropdown visible">
                <!-- Dialog Options -->
                <div class="dropdown-option" @click="confirmResetFilters">
                  <v-icon left color="white">mdi-restart</v-icon>
                  Start new search
                </div>
                
              </div>


               <!-- Overlay -->
               <div
                  v-if="showResetDialog"
                  class="dropdown-overlay"
                  @click="closeResetDialog"
                ></div>

  
  
            <v-container fluid style="padding-bottom: 0px !important">
              <v-row>
                <v-col cols="11">
                  <v-card flat>

                    
                <v-card-text class="search-bar-container-mobile">
                    
                <v-text-field
                v-model="preQuery"
                type="search"
                clearable
                solo-inverted
                flat                
                hide-details
                label=""
                prepend-inner-icon="mdi-magnify"
                class="custom-search-field"
                @focus="handleFocus"
                @input="onInput"
                @blur="hideInstructionDropdown"
                @keydown.enter="triggerSearch"
                ref="searchInput"
                enterkeyhint="search"
                placeholder="Search brand, name, or notes"
                background-color="#efefef"
                color="black"
                style="border-bottom-left-radius: var(--radius-search);border-top-left-radius: var(--radius-search);" 
                ></v-text-field>

     

                <!-- Auto-Suggestion Dropdown -->
                <div v-if="suggestions.length && !showInstructionDropdown" class="auto-suggestion-dropdown">
                  <ul>
                    <li v-for="(suggestion, index) in suggestions" :key="index" @click="applySuggestion(suggestion.suggestion)">
                      {{ suggestion.suggestion }}
                    </li>
                  </ul>
                </div>
              </v-card-text>

                  </v-card>
                </v-col>
                <v-col cols="1">
                  <v-btn 
                  icon 
                  @click="toggleDrawer" 
                  class="filter-button-mobile"
                  color="white"
                  dark
                  :ripple="false"                     
                  
                  >

                    <v-icon dark>mdi-tune</v-icon>
                    
                   <!--  <v-badge
                      v-if="activeFilterCount > 0"
                      :content="activeFilterCount"                      
                      color="#DC3545"
                      offset-y="28"
                      offset-x="1"
                      :class="{ 'blink-badge': isBlinking }"
                    ></v-badge> -->

                  </v-btn>
                </v-col>
              </v-row>

                         <!-- Instruction Dropdown -->
           <div v-if="showInstructionDropdown" class="dropdown-option-alternative-search">
                  <p class="instruction-text">
                    Search your favorite brand, perfume or notes like 
                    <i class="mdi mdi-leaf" style="color:#4CAF50;"></i> <!-- Herbal (Green) --> Patchouli, 
                    <i class="mdi mdi-flower" style="color:#C62828;"></i> <!-- Floral (Rose Pink) --> Rose, or 
                    <i class="fa-solid fa-lemon" style="color:#FFD300;"></i> <!-- Citrus (Gold) --> Lemon 
                    <b> — or be creative and mix them all!</b>
                </p>
                </div>
               
              
              <div 
                class="number-of-results-mobile" 
                v-if="filteredPerfumes.length > 0 && filteredPerfumes.length <= 3500 && filteredPerfumes.length < perfumes.length - 1"
              >                
                <span>{{ filteredPerfumes.length }} {{ filteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}</span>
                <SaveSearchCombo :currentRoute="currentRoute" />
              </div>



<!-- Selected Filters Area (Horizontally Scrollable) -->
<div :key="chipsKey"  v-if="selectedFilters.length > 0" class="selected-filters-container">
  <div class="selected-filters">
    <v-chip
      v-for="(filter, index) in selectedFilters"
      :key="index"
      class="selected-filter-chip"
      :class="{'excluded': filter.startsWith('~')}"  
      close
      @click:close="removeFilterFromChip(filter)"
    >
      <span class="chip-text" :class="{'excluded-text': filter.startsWith('~')}">
        {{ filter.replace(/~/g, '') }}  <!-- Remove ~ for display -->
      </span>
    </v-chip>
  </div>
</div>



              
            
            </v-container>
          </div>



        </div>
      </v-container>
  
    
  <!-- APP DESIGN: Mobile & Flex -->
  
  <body>
  <template>
  
    <div v-if="!isMobile" class="placeholder"></div>


    <div class="number-of-results" v-if="!isMobile && filteredPerfumes.length > 0 && filteredPerfumes.length <= 3500 && filteredPerfumes.length < perfumes.length - 1">                
                {{ filteredPerfumes.length }} {{ filteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}
                <SaveSearchCombo :currentRoute="currentRoute" />          
      </div>
      

        <div>
          

          
<div :class="isMobile ? 'perfume-container-mobile' : 'perfume-container-desktop'">

  <div :class="showDetails ? (isMobile ? 'mobile-perfume-item' : 'desktop-perfume-item') : 'perfume-item-compact'"
  v-for="(perfume, index) in paginatedItems" 
  :key="`perfume-${index}`">
      
      


  <v-progress-circular
      v-if="!loadedImages[perfume.id]"
      indeterminate
      color="primary"
       class="progress-center-rel"
    ></v-progress-circular>
  

  <!-- Normal App View -->
  

      <div v-if="showDetails" class="fade-in" :class="{ loaded: loadedImages[perfume.id] }">

  
      <!-- Perfume Name, Brand, and Rating -->
      <div class="perfume-info">


        
        <div class="perfume-info-text">
  <!-- If the name is above threshold, show name + brand in one line -->
  <template v-if="perfume.name.length > 35">
      <span
    class="perfume-name"
    :class="{ 'no-hover': isMobile }"
    @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
    :style="{ fontSize: '1.1em' }"
  >
    {{ perfume.name }}</span>
  <span style="color: grey; margin: 0 6px; font-size: 1.1em;">|</span>
  <span
    class="perfume-brand"
    :class="{ 'no-hover': isMobile }"
    @click.stop="toggleBrandDropdown($event, perfume.brand)"
    :style="{ fontSize: '1.1em', marginBottom: '2px'}"
  >
    {{ perfume.brand }}
  </span>
  </template>

  <!-- Otherwise, name and brand each on its own line -->
  <template v-else>
    <span
      class="perfume-name"
      :class="{ 'no-hover': isMobile }"
      @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
      :style="{ fontSize: '1.15em', color: '#000' }"
    >
      {{ perfume.name }}
    </span>
    <br>
    <span
      class="perfume-brand"
      :class="{ 'no-hover': isMobile }"
      @click="toggleBrandDropdown($event, perfume.brand)"
      :style="{ fontSize: '1.15em', color: 'grey' }"
    >
      {{ perfume.brand }}
    </span>
  </template>
</div>


        <!-- Brand Dropdown -->
    <div v-if="brandMenu.visible" 
        :style="{ top: isMobile ? `${brandMenu.y}px` : `${brandMenu.y}px`, left: isMobile ? '25px' : `${brandMenu.x}px` }"
        class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="filterByBrand(brandMenu.brand)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Show me all {{ brandMenu.brand }} perfumes
      </div>

    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="brandMenu.visible" class="dropdown-overlay" @click="closeBrandDropdown"></div>



    </div>
  
  <!-- Scent Types as Chips -->
<div class="mobile-scent-types">
  <!-- Individual Type Chips -->
<v-chip 
  v-for="(type, index) in perfume.combined_types.split('-')"
  :key="`type-${perfume.id}-${index}`"
  :color="getScentTypeColor(type)"
  class="mobile-chip-custom"
  @click="toggleTypeDropdown($event, type)"
  :class="{ 'chip-selected': selectedType[type] === 'selected', 'chip-enlarged': typeMenu.visible && typeMenu.type === type }"
  :style="selectedType[type] === 'selected' ? { '--chip-border-color': getScentTypeColor(type) } : {}"
>
  {{ type.toUpperCase() }}
</v-chip>

  <!-- Similar Scent Icon -->
 <!--  <v-icon
    class="similar-scent-icon"
    @click="toggleSimilarScentDropdown($event, perfume.combined_types.split('-'))"
    style="margin-left: 11px;"
  >
    
    mdi-set-center
  </v-icon> -->
</div>

<!-- Type Dropdown -->
<div
  v-if="typeMenu.visible"
  :style="{ top: isMobile ? `${typeMenu.y}px` : `${typeMenu.y}px`, left: isMobile ? '25px' : `${typeMenu.x}px` }"
  class="note-dropdown visible"
  ref="typeDropdown"
>
  
    
    <!-- Show all perfumes of this type -->
    <div class="dropdown-option" @click.stop="resetAndFilterByType(typeMenu.type)">
      <v-icon left color="white">mdi-magnify</v-icon>
      Show me all {{ typeMenu.type.toLowerCase() }} perfumes
    </div>

    <!-- Add Type Option (Plus Icon) -->
    <div 
      v-if="!selectedType[typeMenu.type] && filteredPerfumes.length < perfumes.length - 1" 
      class="dropdown-option icon-only" 
      @click.stop="addTypeFilter(typeMenu.type)"
    >
      <v-icon left color="green">mdi-plus</v-icon>
      
    </div>

    <!-- Remove Type Option (Minus Icon) -->
    <div 
      v-if="selectedType[typeMenu.type] === 'selected'" 
      class="dropdown-option icon-only" 
      @click.stop="removeImportanceFromType(typeMenu.type)"
    >
      <v-icon left color="orange">mdi-minus</v-icon>
    </div>

  </div>




<!-- Overlay for Type Dropdown -->
<div
  v-if="typeMenu.visible"
  class="dropdown-overlay"
  @click="closeTypeDropdown"
></div>


 

<!-- Similar Scent Dropdown -->
<div
  v-if="similarScentMenu.visible"
  :style="{ top: isMobile ? `${similarScentMenu.y}px` : `${similarScentMenu.y - 40}px`, left: isMobile ? '25px' : `${similarScentMenu.x - 220}px` }"
  class="note-dropdown visible"
  ref="similarScentDropdown"
>
  <div v-if="similarScentMenu.areTypesAlreadySelected" class="dropdown-option">
    <v-icon left color="grey">mdi-scent</v-icon>
    You already see similar scents
  </div>
  <div
    v-else
    class="dropdown-option"
    @click.stop="findSimilarScent(similarScentMenu.types)"
  >
    <v-icon left color="white">mdi-magnify</v-icon>
    Get me similar perfumes.

  </div>

</div>





<!-- Overlay for Similar Scent Dropdown -->
<div
  v-if="similarScentMenu.visible"
  class="dropdown-overlay"
  @click="closeSimilarScentDropdown"

></div>







<div class="mobile-ratings">
    
    




  <!-- Image -->
  <div class="image-card" :style="{ backgroundColor: 'white' }">
    <div :class="{ 'image-container-mobile': isMobile, 'image-container': !isMobile }" style="position: relative;">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" 
      class="mobile-perfume-image" @load="markImageAsLoaded(perfume.id)" 
      @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
      >
    </div>


    <FavoritesToggle :perfumeId="perfume.image_name" :top="210" :left= "10"  />

             <!-- Prices Button -->
<!-- Conditionally Render Prices Button or Amazon Link -->

  <!-- Prices Button -->
  <v-btn
    v-if="supportedCountries.includes(userCountryCode)"
    outlined
    @click="togglePriceDrawer(perfume)"
    style="
      position: absolute;
      right: 0;
      margin-top:197px;
      background-color: #efefef;
      color: black;
      border: 1px solid #efefef;
      border-radius: var(--radius-buttons2);   
      text-transform: none; /* Prevents uppercase transformation */
      display: flex;
      align-items: center;
      font-weight: normal;
      justify-content: center;
      padding: 4px 10px; /* Smaller padding for compact size */
    "
  >
    <v-icon style="margin-right: 5px; margin-left: 0px ; font-size: 19px; color: grey;">
      mdi-tag
    </v-icon>
    Prices
  </v-btn>



  <!-- Amazon Link -->
  <v-btn
    v-if="!supportedCountries.includes(userCountryCode)"
    outlined
    :href="generateAmazonLink(perfume.brand,perfume.name,perfume.concentration)"
    style="
      position: absolute;
      right: 0;
      margin-top:197px;
      background-color: white;
      color: black;
      border: 1px solid #efefef;
      border-radius: var(--radius-buttons2);  
      text-transform: none; /* Prevents uppercase transformation */
      display: flex;
      align-items: center;
      font-weight: normal;
      justify-content: center;
      padding: 4px 10px; /* Smaller padding for compact size */
    "
  >
    <v-icon style="margin-right: 5px; margin-left: 0px ; font-size: 19px; color: grey;">
      mdi-tag
    </v-icon>
    Amazon
  </v-btn>



<div class = "fade-right"></div>
</div>

<div style="height:30px;"></div>

<div class="info-area">
    <v-row>      





<!-- Mobile version -->
<div :key="chipsKey" class="scrollable-chips-container" ref="chipsContainer">   


  <div class="chips-row">
    <!-- Strong Notes -->
    <v-chip
      v-for="(note, index) in perfume.notes_strong.split(',').filter(n => n.trim() !== '')"
      :key="`strong-note-${perfume.id}-${index}`"
      class="mobile-chip-strong-notes"
      @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
    >
      {{ note.trim() }}
    </v-chip>
    <!-- Medium Notes -->
    <v-chip
      v-for="(note, index) in perfume.notes_medium.split(',').filter(n => n.trim() !== '')"
      :key="`medium-note-${perfume.id}-${index}`"
      class="mobile-chip-medium-notes"
      @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
    >
      {{ note.trim() }}
    </v-chip>
    <!-- Weak Notes -->
    <v-chip
      v-for="(note, index) in perfume.notes_weak_adj.split(',').filter(n => n.trim() !== '')"
      :key="`weak-note-${perfume.id}-${index}`"
      class="mobile-chip-weak-notes"
      @click="toggleNoteDropdown($event, note.trim(), perfume.id)"
    >
      {{ note.trim() }}
    </v-chip>
  </div>

  

  <!-- Overlay -->
  <div
    v-if="noteMenu.visible && noteMenu.perfumeId === perfume.id"
    class="dropdown-overlay"
    @click="closeNoteDropdown"
  ></div>
  
 
 <!-- Dropdown -->
 <div
  v-if="noteMenu.visible && (isMobile ? (noteMenu.perfumeId === perfume.id) : true)"
  :style="isMobile ? { top: `${noteMenu.y}px`, left: '25px' } : { top: `${noteMenu.y - 0}px`, left: `${noteMenu.x - 30}px` }"
  class="note-dropdown visible"
  ref="noteDropdown"
>

    <!-- Always Show the Magnify Option -->
    <div class="dropdown-option" @click.stop="resetAndSearch(noteMenu.note)">
    <v-icon left color="white">mdi-magnify</v-icon>
    All perfumes with {{ noteMenu.note }}
  </div>
  

  <!-- Add Note to Search -->
  <!-- Notes can not be added anymore with less than 10 results -->
  <div 
    v-if="!searchQuery.toLowerCase().includes(noteMenu.note.toLowerCase()) && filteredPerfumes.length > 1 && filteredPerfumes.length < perfumes.length - 1" 
    class="dropdown-option icon-only" 
    @click.stop="triggerSearchFromDropdown(noteMenu.note)"
  >
    <v-icon left color="green">mdi-plus</v-icon>
  </div>  

  <!-- Remove Note from Search -->
  <div 
     v-if="searchQuery.toLowerCase().includes(noteMenu.note.toLowerCase())" 
    class="dropdown-option icon-only" 
    @click.stop="removeNoteFromSearch(noteMenu.note)"
  >
    <v-icon left color="orange">mdi-minus</v-icon>
  </div> 


  <div v-if="!isMobile">
<!--  <img :src="getImageForNote(noteMenu.note)" alt="Note Image" class="note-image fade-in" @load="handleImageLoad($event)"/>  -->
  
</div>
</div>



  
  
</div>


  
<!-- Ratings Box for Longevity, Projection, and Price -->


  <v-col cols="12" class="py-1 ma-0">
    <div class="mobile-rating-box">           
      <v-row>
        <v-col cols="12" class="py-1 ma-0">          
          <!-- Row 1: Projection -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Intensity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, sillageRating(perfume.sillage))"></div>
              </div>
            </v-col>
          </v-row>               
          
          <!-- Row 2: Longevity -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Longevity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, longevityRating(perfume.longevity))"></div>
              </div>
            </v-col>
          </v-row>
  
        <!-- Row 3: Price, Concentration, and Year -->
        <v-row class="rating-row align-center">
          <v-col cols="4" class="rating-label pa-0">
            <span v-for="n in priceToNumber(perfume.price)" :key="n" class="price-symbol">$</span>
          </v-col>
          <v-col cols="4" class="text-center pa-0">
      <span :style="getConcentrationStyle(perfume.concentration)">
        {{ perfume.concentration }}
      </span>
    </v-col>

          <v-col cols="4" class="text-right pa-0" style="color:grey">
            <span>{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? parseInt(perfume.year, 10) : '' }}</span>
          </v-col>
        </v-row>


        </v-col>
      </v-row>
    </div>
  </v-col>
  
  
      </v-row>
  


</div>

</div>
</div>


  <!-- Compact View -->
  
<div v-if="!showDetails" class="compact-view-container fade-in" :class="{ loaded: loadedImages[perfume.id] }">
    <!-- Image Container -->
    <div class="compact-image-container">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="compact-perfume-image" @load="markImageAsLoaded(perfume.id)"
      >

    </div>
    <!-- Perfume Info and Scent Types -->
    <div class="compact-content">
      
      <!-- Perfume Name, Brand, and Rating -->
     
      <div class="mobile-perfume-info">
        <div v-if="isMobile">
          <span class="perfume-name no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.83em' : (perfume.name.length > 30 ? '0.86em' : '1.02em') }">{{ perfume.name }}</span>
          <br>
          <span class="perfume-brand no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.83em' : (perfume.name.length > 30 ? '0.86em' : '1.02em') }">{{ perfume.brand }}</span>
        </div>
        <div v-if="!isMobile" class="flex-perfume-info">
          <span class="perfume-name no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.86em' : (perfume.name.length > 30 ? '0.95em' : '1.10em') }">{{ perfume.name }}</span>
          <br>
          <span class="perfume-brand no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.86em' : (perfume.name.length > 30 ? '0.95em' : '1.10em') }">{{ perfume.brand }}</span>
        </div>

      </div>
  
     <!-- Scent Types as Chips -->
     <div v-if="isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          small
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
          <!-- Scent Types as Chips -->
          <div v-if="!isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
      
  
  
  
    </div>
  </div>
  
  


</div>



          </div>
          
          <transition name="fade">
      <v-btn
  fab
  fixed
  right
  :ripple="false"  
  :style="{
    top: isIOSApp() ? 'calc(' + buttonTop + ' + 35px)' : buttonTop,
    right: '20px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
  }"
  @click=scrollToTop
  class="transparent-btn"
>
  <v-icon>mdi-tune</v-icon>
</v-btn>
</transition>

         <transition name="fade">
          <v-btn
        fab fixed right
        :ripple="false"  
        :style="{
        bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 17px)' : buttonBottom,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        right: '20px'
        }"
        @click="buttonAction"
        class="transparent-btn"
        >
          <v-icon>{{ scrollY > 450 ? 'mdi-arrow-up' : (showDetails ? 'mdi-view-list' : 'mdi-view-grid') }}</v-icon>
        </v-btn>
        </transition>

        
  
        <div v-if="filteredPerfumes.length > showItemsCount">
          <div class="text-center mt-3">
            <v-btn
                @click="loadMorePerfumes"
                class="mx-auto mt-3"                
                color="white"
                large
                ripple
                style="border-radius: var(--radius-buttons); box-shadow: none" 
                
              >
                <v-icon left>mdi-chevron-down</v-icon>
                Show More Perfumes
            </v-btn>
          </div>

  <br>

      </div>

  
            

          <div v-else-if="filteredPerfumes.length === 0 && !isLoading" class="no-results-container">

    <div class="no-results-message">
      Ooops! The prophet couldn't find a perfume for you this time.
    </div>
  
    <!-- Remove Gender Filter Button -->
    <v-btn v-if="selectedScentgender.length > 0"
           @click="removeFilter('gender')"
           color="primary"
           text>Remove Gender Filter</v-btn>
  
    <!-- Remove Intensity Filter Button -->
    <v-btn v-if="selectedSillage.length && selectedSillage.toString() !== [1, 4].toString()"
           @click="removeFilter('sillage')"
           color="primary"
           text>Remove Intensity Filter</v-btn>
  
    <!-- Remove Longevity Filter Button -->
    <v-btn v-if="selectedLongevity.length && selectedLongevity.toString() !== [1, 4].toString()"
           @click="removeFilter('longevity')"
           color="primary"
           text>Remove Longevity Filter</v-btn>
  
    <!-- Remove Price Filter Button -->
    <v-btn v-if="selectedPrice.length && selectedPrice.toString() !== [1, 5].toString()"
           @click="removeFilter('price')"
           color="primary"
           text>Remove Price Filter</v-btn>
  
    <!-- Remove Gender Filter Button -->
      <v-btn v-if="selectedBrand.length > 0"
           @click="removeFilter('brand')"
           color="primary"
           text>Remove Brand Filter</v-btn>
  
  
  </div>
  
  
  </div>
      <br>
      <br>
  
    </template>
  
  </body>





  <!--FILTER SECTION-->


<div ref="filterDrawer" :class="{'filter-drawer-open': isDrawerOpen, 'flex-filter-drawer': !isMobile, 'filter-drawer': isMobile}" class="filter-section">
 

<div v-if="isMobile" @click="handleSearchDisplayClick"  style="position: absolute; margin-left: 0px; width: 86px; height: 50px; top:4px; border: none; z-index: 50;"></div>

<!-- Sticky Header -->
<div class="sticky-header"  ref="perfumeContainer">
  
  
  <div v-if="isMobile" class="drawer-notch" :style="notchStyle"></div>

   
    <div v-if="isMobile" ref="perfumeContainer"   style="position: absolute; margin-left: 100px; width: 100%; height: 76px; top:-28px; border: 2px none; z-index: 50"></div>
    <div class="close-btn-container">


  </div>
  <!-- Search Query Display -->
  <div v-if="searchQuery" @click="handleSearchDisplayClick"
     :class="isMobile ? 'search-query-display-mobile' : 'search-query-display'"
     :style="isMobile ? queryDisplayStyle : {}">
  <v-icon>mdi-magnify</v-icon>
  <span class="search-term">{{ searchQuery }}</span>
</div>



</div>


  <!-- Scrollable Content Container -->
  <div class="filter-drawer-content" ref="drawerContent">        

    <!-- ScentGender Filter Dropdown --> 
    <div>
      <h4 style="margin-bottom: 5px">Gender</h4>
      <div class="filter-options">
        <v-btn
          v-for="option in scentgn"
          :key="option"
          :class="[
            isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
            tempSelectedScentgender.includes(option) ? (isMobile ? 'mobile-filter-option-button-selected' : 'filter-option-button-selected') : ''
          ]"
          @click="toggleScentgender(option)"
          outlined
          :text="!tempSelectedScentgender.includes(option)"
          tile
        >
          {{ option }}
        </v-btn>
      </div>
    </div>

    <!-- Scenttype Filter Buttons --> 
    <div>
    <h4 style="margin-bottom: 5px">Scent Profile</h4>
    <div class="filter-options">
      <!-- Iterate over the scent types and create buttons for each -->
      <template v-for="option in type">
        <v-btn
          :key="option"
          :class="[
            isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
            isMobile && tempSelectedType[option] === 'selected' ? 'mobile-filter-option-button-selected' : '',
            !isMobile && tempSelectedType[option] === 'selected' ? 'filter-option-button-selected' : '',
            isMobile &&tempSelectedType[option] === 'excluded' ? 'mobile-filter-option-button-excluded' : '',
            !isMobile &&tempSelectedType[option] === 'excluded' ? 'filter-option-button-excluded' : ''
          ]"
          @click="toggleType(option)"
          outlined
          :text="!tempSelectedType[option]"
          tile
          style="margin-bottom: 8px;"
        >
          <span :style="tempSelectedType[option] === 'excluded' ? 'text-decoration-line: line-through; text-decoration-thickness: 2px;' : ''">
            {{ option }}
          </span>
        </v-btn>
      </template> <br>
<span style="font-size: 13px; color: black; margin-left: 1px; position: relative;">
  <v-icon left color="black" style="font-size: 14px; position: relative; top: -2px;">mdi-arrow-up</v-icon> 
  Doubletab to exclude
</span>

    </div> 
    </div>


<!-- Projection Filter with Range Slider -->
<div>
    <h4 style="margin-bottom: 12px">Intensity</h4>
    <div class="filter-options-slider">
        <VueSlider
            v-model="tempSelectedSillage"
            :min="1"
            :max="4"
            :lazy="true"
            :tooltip="'none'"
            :processStyle="{ backgroundColor: 'black' }"
            :railStyle="{ backgroundColor: '#cccccc' }"            
            :marks="sillageMarks"
        />
    </div>
</div>

<!-- Longevity Filter with Range Slider -->
<div>
    <h4 style="margin-bottom: 12px">Longevity</h4>
    <div class="filter-options-slider">
        <VueSlider
            v-model="tempSelectedLongevity"
            :min="1"
            :max="4"
            :lazy="true"
            :tooltip="'none'"
            :processStyle="{ backgroundColor: 'black' }"
            :railStyle="{ backgroundColor: '#cccccc' }"  
            :marks="longevityMarks"
        />
    </div>
</div>

    <!-- Price Filter with Range Slider -->
  <div>
    <h4 style="margin-bottom: 12px">Price</h4>
    <div class="filter-options-slider">
      <VueSlider
      v-model="tempSelectedPrice"
    :min="1"
    :max="5"
    :lazy="true"
    :tooltip="'none'"
    :processStyle="{ backgroundColor: 'black' }"
    :railStyle="{ backgroundColor: '#cccccc' }"
    :marks="priceMarks"
      />
    </div>
  </div>




    
<!-- Brand Filter Dropdown -->


      <div style="position: relative; margin-bottom: 33px">
        <h4 style="margin-bottom: 5px; cursor: pointer" @click="toggleBrandsVisibility">
          Brand
          <span :class="{'triangle-down': !showBrands, 'triangle-right': showBrands}"></span>
        </h4>
        <v-badge
          v-if="BrandactiveFilterCount > 0"
          :content="BrandactiveFilterCount"
          color="#262626"
          offset-y="-25"
          offset-x="-64"
          style="position: absolute"           
   
        ></v-badge>
        
        <div class="filter-options" v-show="showBrands">

  <v-btn
    v-for="brand in brands"
        :key="brand"
        :class="[
          isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
          tempSelectedBrand.includes(brand) ? (isMobile ? 'mobile-filter-option-button-selected' : 'filter-option-button-selected') : ''
        ]"
        @click="toggleBrand(brand)"
        outlined
        :text="!tempSelectedBrand.includes(brand)"
        tile
        style="margin-bottom: 8px;"
  >
    {{ brand }}
  </v-btn>

</div>

    </div>



    <!-- Sorting Buttons -->
<div>
  <h4 style="margin-bottom: 5px">Sort by</h4>
  <div class="filter-options">
    <v-btn
      v-for="option in ['name', 'brand', 'price', 'color']"
      :key="option"
      :class="[
        isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
        isMobile && tempSort === option ? 'mobile-filter-option-button-selected' : '',
        !isMobile && tempSort === option ? 'filter-option-button-selected' : ''
      ]"
      @click="selectTempSort(option)"
      outlined
      :text="tempSort !== option"
      tile
    >
      {{ option.charAt(0).toUpperCase() + option.slice(1) }}
    </v-btn>
  </div>
</div>



    </div>

  
<!-- Fixed Footer with buttons -->
<div class="filter-drawer-footer" :style="isIOSApp() && isMobile ? 'padding-bottom: 38px;' : ''">
  <v-btn  @click="DeleteTempFilters" class="left-button">  
    Delete all 
  </v-btn>
  
  <v-btn dark fab color="black" @click="applyFiltersAndToggleDrawer" style="box-shadow: none;" class="right-button">
    <span>
  <span v-if="previewFilteredPerfumes.length === 0">no perfumes found</span>
  <span v-else>
    Show 
    <span v-if="previewFilteredPerfumes.length > 1000">1000+</span>
    <span v-else>{{ previewFilteredPerfumes.length }}</span>
    {{ previewFilteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}
  </span>
</span>



  </v-btn>
</div>


</div>



  <!--Price Comparison Section-->

  <div :class="{'filter-drawer-open': isPriceDrawerOpen, 'flex-filter-drawer': !isMobile, 'filter-drawer': isMobile}" class="filter-section">
 
 <!-- Sticky Header -->
<div class="sticky-header" @click="togglePriceDrawer">
<!--  <div class="drawer-notch"></div>  -->
 <div class="close-btn-container">
   <v-btn icon >
     <v-icon>mdi-close</v-icon>
   </v-btn>
 </div>
</div>

 <!-- Scrollable Content Container 
 <div class="filter-drawer-content" ref="drawerPricesContent"> 
  <ComparePrices 
  :keywords="selectedPerfumeKeywords"
  :name="selectedPerfumeName"
  :brand="selectedPerfumeBrand"
  :concentration="selectedPerfumeConcentration"
   />
   </div>
 
  -->


<!-- Fixed Footer with buttons -->
<div class="filter-drawer-footer" :style="isIOSApp() && isMobile ? 'padding-bottom: 38px;' : ''">
 <v-btn  @click="DeleteTempFilters" class="left-button">  
    
 </v-btn>
 
 <v-btn dark fab color="black" @click="togglePriceDrawer" style="box-shadow: none;" class="right-button">
   <span>
   Close  
</span>
 </v-btn>


</div>


</div>



<!--GENERAL-->
<div :class="{'overlay': true, 'visible': isDrawerOpen}" @click="applyFiltersAndToggleDrawer"></div>
<div :class="{'overlay': true, 'visible': isPriceDrawerOpen}" @click="togglePriceDrawer"></div>

  </v-app>      
</template>


<script>
import axios from 'axios';
import VueSlider from 'vue-slider-component';
import { deleteAllFilters, deleteTempFilters, resetTempFilters, removeFilter } from '@/utils/FilterReset.js';
import { sortPerfumes, shuffleArray } from '../utils/sort.js';
import { getScentTypeColor } from '@/utils/colorMapping.js';
import { filterPerfumes, previewFilteredPerfumes} from '../utils/Filters.js';
//import ComparePrices from './ComparePrices.vue';
import FavoritesToggle from './FavoritesToggle.vue';
import MiniSearch from 'minisearch';
import { supportedCountries } from '@/utils/supportedCountries.js';
import StartInstructions from '@/components/StartInstructions.vue';
import SaveSearchCombo from './SaveSearchCombo.vue';




export default {
  name: 'PerfumeDataTable',
  data() {
    return {  
      
      totalVisibleRows: 20, // Initial number of items to display   
      perfumes: [],
      filteredPerfumes: [],
      brands: [],      
      type: [],
      scentgn: [], 
      search: '',
      preQuery: '', // new property for preQuery
      searchQuery: '', // for searchbar
      selectedBrand: [], 
      selectedScentgender: [],
      selectedType: {},
      selectedPrice: [1, 5],
      selectedSillage: [1, 4],
      selectedLongevity: [1, 4],
      tempSelectedScentgender: [], 
      tempSelectedType: {},
      tempSelectedBrand: [],
      timeout: null,  
      showDetails: true, // New property to control the visibility of detailed ratings
      allImages: {}, // for pictures
      currentPage: 1, // for pagination
      initialItemsPerPage: 12,
      incrementAmount: 12, // Number of items to add on each click of 'show more'
      showItemsCount: 12, // Initially show 12 items
      isDrawerOpen: false, //for sliding filter window   
      isPriceDrawerOpen: false, 
      scrollTop: 0,
      currentSort: '', // current sort parameter
      tempSort: '',
      showBrandOptions: false,
      showBrands: false,
      lastTap: 0,
      lastTappedOption: null, 
      maxExcluded: 8,
      currentLetter: 'A', // Initial letter
      scrollPosition: 0,
      isMouseDown: false,
      startX: 0,
      startY: 0,
      currentTranslateY: 0,
      scrollLeft: 0,
      scrollY: 0,
      lastScrollY: 0, 
      chipsKey: 0,
      isBlinking: false,

      noteMenu: {
        visible: false,
        perfumeId: null,
        note: '',
        x: 0,
        y: 0,
      },
      typeMenu: {
        visible: false,
        type: '',
        x: 0,
        y: 0,
      },
      similarScentMenu: {
        visible: false,
        types: [],
        x: 0,
        y: 0,
      },
        brandMenu: {
        visible: false,
        type: '',
        x: 0,
        y: 0,
      },


    showResetDialog: false, // Controls the visibility of the reset dialog
    feedbackMessages: {}, // To store feedback messages for each perfume
    feedbackMessageType: {}, // To store the type (added/removed) for each perfume
    showInstructionDropdown: false,
    suggestions: [], // Store auto-suggestions
    isPriceDialogOpen: false,
    isLoading: false, // For showing the progress spinner
    selectedPerfumeKeywords: null, // To hold the selected perfume data for price comparison
    selectedPerfumeName: null, // Name of the selected perfume
    selectedPerfumeBrand: null, // Brand of the selected perfume
    selectedPerfumeConcentration: null, // Concentration of the selected perfume
    
    userAgent: '', // Store the user's browser agent
    userCountry: '', // Store the user's country
    userCountryCode: '', // Store the user's country code

    apiUrl: process.env.VUE_APP_API_URL, // Fetch the API URL from environment variables
    environment: process.env.NODE_ENV, // Fetch the environment (e.g., development, production)

    loadedImages: {},
    showStartInstructions: false,
    showOfflineWarning: false,
      
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],

      buttonBottom: '95px', // Default bottom position for the button
      buttonTop: '45px', // Default bottom position for the button

      tempSelectedPrice: [1, 5],
      priceCategories: ['very low','low', 'medium', 'high', 'very high'],
      priceMarks: 
        { 1: '$',
        2: '$$',
        3: '$$$',
        4: '$$$$',
        5: 'Luxury'},

      tempSelectedLongevity: [1, 4],  // Default range values for longevity
      longevityCategories: ['short', 'medium', 'long', 'very long'],
      longevityMarks: {            
            1: 'Short',
            2: 'Medium',
            3: 'Long',
            4: 'Very Long'
          },

      tempSelectedSillage: [1, 4],    // Default range values for sillage
      sillageCategories: ['low', 'medium', 'strong', 'very strong'],
      sillageMarks: {
            1: 'Silent',
            2: 'Medium',
            3: 'Loud',
            4: 'Very Loud'
          },

      

    };
  },

   components: {
    VueSlider,
    //ComparePrices,
    FavoritesToggle,
    StartInstructions,
    SaveSearchCombo
  },
  
  computed: {
            
    activeFilterCount() {
    let count = 0;

    // Check if the longevity slider is set to its full range
    if (this.selectedLongevity.length > 0 && !(this.selectedLongevity[0] === 1 && this.selectedLongevity[1] === this.longevityCategories.length)) {
      count++;
    }

    // Check if the sillage slider is set to its full range
    if (this.selectedSillage.length > 0 && !(this.selectedSillage[0] === 1 && this.selectedSillage[1] === this.sillageCategories.length)) {
      count++;
    }

    // Check if the price slider is set to its full range
    if (this.selectedPrice.length > 0 && !(this.selectedPrice[0] === 1 && this.selectedPrice[1] === this.priceCategories.length)) {
      count++;
    }

    // Count other selected filters
    count += this.selectedScentgender.length;
    count += this.selectedBrand.length;
    count += Object.keys(this.selectedType).length;

    return count;
  },

  BrandactiveFilterCount() {
    return this.tempSelectedBrand ? this.tempSelectedBrand.length : 0;
  },

  //for Pagination
  paginatedItems() {
    return this.filteredPerfumes.slice(0, this.showItemsCount);
  },


//Sorting for brands filter
sortedBrands() {
    // First, sort the brands alphabetically and then return.
    return this.brands.slice().sort((a, b) => a.localeCompare(b));
  },
  firstRowBrands() {
    // Return brands for the first row (1st, 3rd, 5th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 === 0);
  },
  secondRowBrands() {
    // Return brands for the second row (2nd, 4th, 6th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 !== 0);
  },

  
  previewFilteredPerfumes,

  supportedCountries() {
      return supportedCountries;
    },

  queryDisplayStyle() {
    if (!this.searchQuery) return {};

    const length = this.searchQuery.length;
    if (length <= 15) {
      return { maxWidth: '140px' };
    } else {
      return { minWidth: '88%' };
    }
  },

  
  notchStyle() {
    if (this.searchQuery && this.searchQuery.length > 15) {
      return { bottom: '51px' }; // Move notch upwards when text is long
    } else {
      return { bottom: '39px' }; // Default position when text is short or none
    }
  },


  selectedFilters() {
    let filters = [];

    // Add selected gender filters
    filters.push(...this.tempSelectedScentgender);

    // Add included scent type filters
    for (const [type, status] of Object.entries(this.tempSelectedType)) {
      if (status === "selected") {
        filters.push(type); // Normal display for selected types
      } else if (status === "excluded") {
        filters.push(`~${type}~`); // Strikethrough representation for excluded
      }
    }

    // Add selected brands
    filters.push(...this.tempSelectedBrand);

    // Handle Price Filter
    if (this.tempSelectedPrice.length === 2) {
      const [minIndex, maxIndex] = this.tempSelectedPrice;
      if (!(minIndex === 1 && maxIndex === this.priceCategories.length)) {
        const minPrice = this.priceMarks[minIndex];
        const maxPrice = this.priceMarks[maxIndex];
        filters.push(
          minPrice === maxPrice ? `Price: ${minPrice}` : `Price: ${minPrice} - ${maxPrice}`
        );
      }
    }

    // Handle Longevity Filter
    if (this.tempSelectedLongevity.length === 2) {
      const [minIndex, maxIndex] = this.tempSelectedLongevity;
      if (!(minIndex === 1 && maxIndex === this.longevityCategories.length)) {
        const minLongevity = this.longevityMarks[minIndex];
        const maxLongevity = this.longevityMarks[maxIndex];
        filters.push(
          minLongevity === maxLongevity
            ? `Longevity: ${minLongevity}`
            : `Longevity: ${minLongevity} - ${maxLongevity}`
        );
      }
    }

    // Handle Intensity (Sillage) Filter
    if (this.tempSelectedSillage.length === 2) {
      const [minIndex, maxIndex] = this.tempSelectedSillage;
      if (!(minIndex === 1 && maxIndex === this.sillageCategories.length)) {
        const minSillage = this.sillageMarks[minIndex];
        const maxSillage = this.sillageMarks[maxIndex];
        filters.push(
          minSillage === maxSillage
            ? `Intensity: ${minSillage}`
            : `Intensity: ${minSillage} - ${maxSillage}`
        );
      }
    }

    if (this.tempSort) {
      filters.push(`Sort by: ${this.tempSort.charAt(0).toUpperCase() + this.tempSort.slice(1)}`);
    }
    

    return filters;
},

currentRoute() {
    const urlParams = new URLSearchParams(this.$route.query);
    urlParams.delete('items'); // Exclude items parameter
    const route = `/perfumes?${urlParams.toString()}`;
    console.log("[Main Component] Current route (computed):", route);
    return route;
  },

  },

  watch: {
  searchQuery(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.updateUrlWithFilters();
      
    }
  },

  '$route.query': {
    deep: true,
    handler() {
      console.log("[Main Component] Route changed, forcing update");
      this.$forceUpdate(); // Ensures reactivity updates
    }
  },

  
  $route(to) {

  console.log('PerfumeDataTable; Route change detected in PerfumeDataTable:');
  console.log('PerfumeDataTable; New route:', to.fullPath);
  console.log('PerfumeDataTable; Query parameters in the new route:', to.query);

  // Synchronize filters with the new route query
  this.syncFiltersWithRoute(to.query, false);

  console.log('PerfumeDataTable; URL parameters synchronized with new route query:', to.query);

  },
},



created() {
  this.allImages = this.importAllImages(); // Ensure error handling here if necessary
  this.fetchPerfumes()
    .then(() => {
      this.sortPerfumes();
      this.loadFiltersFromUrl();
      this.$nextTick(() => {
        console.log(this.$refs.chipsContainer);
      });
    })
    .catch(error => {
      console.error('Error during initial setup:', error);
      alert('Error initializing application.');
    });
},


     
  mounted() {
    
   // Extract `fromTile` from the query
   const fromTile = this.$route.query.fromTile === 'true'; // Explicitly check for 'true' as a string
  console.log('Mounted PerfumeDataTable. fromTile:', fromTile);
  console.log('Mounted PerfumeDataTable. Full query:', this.$route.query);

  // Remove `fromTile` from the URL after passing it to the function
  const updatedQuery = { ...this.$route.query };
delete updatedQuery.fromTile; // Remove `fromTile` from the query

if ('fromTile' in this.$route.query) {
  this.$router.replace({ query: updatedQuery }).catch((err) => {
    console.error('Error removing fromTile from URL:', err);
  });
}

  // Pass the extracted `fromTile` value to the syncFiltersWithRoute function
  this.syncFiltersWithRoute(this.$route.query, fromTile);

  window.addEventListener('scroll', this.handleScroll);    

  // Process filters and sorting
  this.filterPerfumes(false); // Apply the filters based on the URL
  this.sortPerfumes(); // Sort the perfumes based on the filters
  
  //this.getUserInfo();

  if (Object.keys(updatedQuery).length === 0) {
    this.showDialogOnStartPage();
  }


  // Force scroll to top if `showStartInstructions` is true
  if (this.showStartInstructions) {

    this.$nextTick(() => {
    setTimeout(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
    }, 50); // or 100ms if needed
  });
}

  const stickyHeader = this.$refs.perfumeContainer;
  if (stickyHeader) {
    stickyHeader.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    stickyHeader.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    stickyHeader.addEventListener('touchend', this.handleTouchEnd, { passive: false });
  }
  console.log("filteredPerfumes Length:", this.filteredPerfumes.length);
console.log("Perfumes Length:", this.perfumes.length);

},

  

  beforeDestroy() {

    window.removeEventListener('scroll', this.handleScroll);

    const stickyHeader = this.$refs.perfumeContainer;
  if (stickyHeader) {
    stickyHeader.removeEventListener('touchstart', this.handleTouchStart);
    stickyHeader.removeEventListener('touchmove', this.handleTouchMove);
    stickyHeader.removeEventListener('touchend', this.handleTouchEnd);
  }

  },



  
methods: {  

async fetchPerfumes() {
  try {
    // Define how long local storage data is valid (e.g., 3 days)
    const EXPIRATION_TIME = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

    // Retrieve stored perfumes and timestamp
    const storedPerfumes = localStorage.getItem('shuffledPerfumes');
    const storedTimestamp = localStorage.getItem('perfumeDataTimestamp');

    // Check if stored data exists and is still valid
    const isDataValid =
      storedPerfumes &&
      storedTimestamp &&
      (Date.now() - parseInt(storedTimestamp, 10)) < EXPIRATION_TIME;

    if (isDataValid) {
      console.log('Using perfumes from local storage.');
      this.perfumes = JSON.parse(storedPerfumes);
    } else {
      console.log('Fetching new perfumes from server due to expired or missing local data.');
      
      // Fetch new perfumes from the server
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes`);
      this.perfumes = response.data.map((perfume, index) => ({
        ...perfume,
        id: `perfume-${index}` // Ensure unique ID for each perfume
      }));
      
      // Shuffle the dataset
      this.perfumes = this.shuffleArray(this.perfumes);
      
      // Store the shuffled dataset and timestamp in local storage
      localStorage.setItem('shuffledPerfumes', JSON.stringify(this.perfumes));
      localStorage.setItem('perfumeDataTimestamp', Date.now().toString()); // Store current timestamp
    }

    // Process the perfumes
    this.filteredPerfumes = this.perfumes.slice(0, this.totalVisibleRows);
    this.brands = [...new Set(this.perfumes.map(perfume => perfume.brand))].sort();
    this.scentgn = [...new Set(this.perfumes.map(perfume => perfume.scentgn))].sort();
    this.longevity = ['short', 'medium', 'long', 'very long'];
    this.sillage = ['low', 'medium', 'strong', 'very strong'];
    this.price = ['low', 'medium', 'high', 'very high'];
    this.type = ['Fresh', 'Flowery', 'Sweet', 'Fruity', 'Citric', 'Woody', 'Creamy', 'Aromatic', 'Powdery', 'Green', 'Aquatic', 
                'Synthetic', 'Gourmand', 'Leathery', 'Mineral', 'Oriental', 'Smoky', 'Animalic'];

    // Apply any initial filters
    this.filterPerfumes(false);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
},


  // Show hide brands
  toggleBrandsVisibility() {
    this.showBrands = !this.showBrands;
  },



  handleSearchDisplayClick() {
    this.applyFiltersAndToggleDrawer();  // Call the method to toggle the drawer or apply filters
    this.focusSearch();
    // Check if the search input reference exists and then focus it
    if (this.$refs.searchInput) {
      this.$refs.searchInput.focus();
    }
  },

  focusSearch() {
    this.$refs.searchInput.focus(); // Focus the input field when tab on magnify
  },

 handleFocus(event) {
    this.showInstructionDropdown = true;
    this.selectAllText(event); // Call selectAllText method
  },

  selectAllText(event) {
    // Select all the text in the input field
    if (event.target) {
      event.target.select();
    }
  },
  closeKeyboard() {
    // Blur the input field to close the keyboard on mobile devices
    if (this.$refs.searchInput) {
      this.$refs.searchInput.blur();
    }
  },

  handleScroll() {
  const currentScrollY = window.scrollY;

  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '95px'; // Set the button to 45px when scrolled more than 600px
  } 

  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonTop = '45px'; // Set the button to 45px when scrolled more than 600px
  } 

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;  
},

GoLeft() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft -= 150;
  }
},
GoRight() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft += 150;
  }
},

loadMorePerfumes() {
    console.log('Before incrementing, showItemsCount is:', this.showItemsCount);  // Output the current count before incrementing
    this.showItemsCount += this.incrementAmount;
    console.log('After incrementing, showItemsCount is:', this.showItemsCount);  // Output the new count after incrementing
    this.updateUrlWithFilters()    
},


  resetPaginationAndScroll() {
    this.showItemsCount = this.initialItemsPerPage;
    this.chipsKey += 1; // Update chipsKey to reset scroll position
    console.log('resetPaginationAndScroll called: ')    
    },   


    buttonAction() {
    if (this.scrollY > 450) {
      this.scrollToTop();
    } else {
      this.showDetails = !this.showDetails;
    }
  },

  toggleDetails() {
    this.showDetails = !this.showDetails;
  },

    noteDescription(note) {
  if (note.includes(' ')) {
    // Split the note into words and join them with " AND "
    const words = note.split(' ');
    return `all perfumes that contain ${words.join(' and ')}`;
  } else {
    // For single-word notes, return the default description
    return `all perfumes that contain ${note}`;
  }
},

sendEmail() {
    const email = 'mail@perfumeprophet.com';
    window.location.href = `mailto:${email}`;
  },

  addToSearchQuery(term) {
    if (this.preQuery) {
      this.preQuery += ` ${term}`;
    } else {
      this.preQuery = term;
    }

    if (this.searchQuery) {
      this.searchQuery += ` ${term}`;
    } else {
      this.searchQuery = term;
    }

    this.filterPerfumes(true,false); // Refilter perfumes with the updated search query
  },



  triggerSearch() {
    this.searchQuery = this.preQuery; // Set the actual search query

    // Reset all filter-related states if necessary
    this.selectedBrand = [];
    this.selectedSillage = [1, 4];
    this.selectedScentgender = [];
    this.selectedLongevity = [1, 4];
    this.selectedPrice = [1, 5];
    this.selectedType = {}; 
    this.tempSelectedBrand = []; 
    this.tempSelectedSillage = [1, 4]; 
    this.tempSelectedScentgender = [];
    this.tempSelectedLongevity = [1, 4]; 
    this.tempSelectedPrice = [1, 5]; 
    this.tempSelectedType = {};
    this.tempSort = '';
    this.currentSort = '';

    // Update the URL with the new search query
    this.updateUrlWithFilters();
    
    this.closeKeyboard();
    // Call the method to filter perfumes again
    this.filterPerfumes(true,true);
    this.sortPerfumes();

    this.showInstructionDropdown = false; // Close the dropdown when a search is triggered

    this.suggestions = []; // Clear suggestions

    this.$nextTick(() => {
      console.log('TopJump by TriggerSearch'); // Add console log
      window.scrollTo(0, 0);
    });

  },

   hideInstructionDropdown() {
    // Close the dropdown when the user starts typing or clicks away
    this.showInstructionDropdown = false;
  },


onInput() {
  this.showInstructionDropdown = false; // Hide instructions when typing

  if (this.preQuery.trim()) {
    // Normalize and capitalize the input query
    const normalizedQuery = this.capitalizeWords(this.convertAbbreviations(this.preQuery.trim()));

    // Update suggestions using the normalized and capitalized query
    this.updateSuggestions(normalizedQuery);
  } else {
    this.suggestions = []; // Clear suggestions for empty input
  }
},


convertAbbreviations(query) {
  // Abbreviation map for replacements
  const abbreviations = {
    'ck': 'Calvin Klein',
    'no5': 'N°5',
    'no 5': 'N°5',
    'chanel 5': 'N°5',
    'bulgari': 'Bvlgari',
    'th': 'Tommy Hilfiger',
    'jc': 'Jimmy Choo',
    'ga': 'Giorgio Armani',
    'd&g': 'Dolce & Gabbana',
    'ysl': 'Yves Saint Laurent',
    'mj': 'Marc Jacobs',
    'jpg': 'Jean Paul Gaultier',
    'ch': 'Carolina Herrera',
    'pr': 'Paco Rabanne',
    'mm': 'Maison Margiela',
    'jml': 'Jo Malone London',
    'jm': 'Jo Malone',
    'cdg': 'Comme Des Garçons',
    'adp': 'Acqua Di Parma',
    'mfk': 'Maison Francis Kurkdjian',
    'lv': 'Louis Vuitton',
    'cc': 'Clive Christian',
    'fm': 'Frederic Malle',
    'pdm': 'Parfums De Marly',
    'tf': 'Tom Ford',
  };

  // Split the query into words and replace abbreviations
  return query
    .split(' ')
    .map(word => abbreviations[word.toLowerCase()] || word) // Replace abbreviation or keep original word
    .join(' '); // Rejoin words into a single string
},

capitalizeWords(input) {
  return input
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
},



updateSuggestions(query) {

  const miniSearch = new MiniSearch({
    fields: ['name', 'brand'],
    storeFields: ['name', 'brand'],
    combineWith: 'AND',
    prefix: true,
    searchOptions: {
      fuzzy: term => term.length <= 3 ? 0 : term.length <= 5 ? 0.20 : term.length <= 9 ? 0.30 : 0.35,
    },
    boost: {
      name: 2.35,
      brand: 12.5,
      
    },
    weights: {
      fuzzy: 0.06,
      prefix: 0.9,
    },
  });

  // Add perfumes to the search index
  miniSearch.addAll(
    this.perfumes.map(perfume => ({
      id: perfume.id,
      name: perfume.name,
      brand: perfume.brand,
      combined_types: perfume.combined_types,
    }))
  );

  // Function to get suggestions
  const getSuggestions = (searchQuery) => {
    return miniSearch
      .autoSuggest(searchQuery, {
        fuzzy: term => term.length <= 3 ? 0 : term.length <= 5 ? 0.20 : term.length <= 9 ? 0.30 : 0.35,
        prefix: term => term.length >= 2,
      })
      .filter(suggestion => suggestion.score > 1) // Filter by score
      .slice(0, 4) // Limit to top 5 results
      .map(suggestion => ({
        ...suggestion,
        suggestion: this.capitalizeWords(suggestion.suggestion),
      }));
  };

  // Step 1: Try with the original query
  let suggestions = getSuggestions(query);

  // Step 2: If no suggestions found, remove spaces and try again
  if (!suggestions.length) {
    const noSpaceQuery = query.replace(/\s+/g, '');
    suggestions = getSuggestions(noSpaceQuery);
  }

  // Update suggestions
  this.suggestions = suggestions;
},




applySuggestion(suggestion) {
    this.preQuery = suggestion; // Set the search query to the selected suggestion
    this.suggestions = []; // Clear suggestions
    this.triggerSearch(); // Trigger the search
  },

  openInstagram() {
    const instagramUrl = 'https://www.instagram.com/perfume.prophet/'; // Replace with your Instagram URL
    window.open(instagramUrl); // Open Instagram in a new tab
  },


  
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },

  updateUrlWithFilters() {
    const query = {};   

     // Include the current count of shown items in the query parameters
     query.items = this.showItemsCount.toString();
    console.log('Updating URL - showItemsCount:', this.showItemsCount);  // Log the current showItemsCount

    if (this.searchQuery) {
    query.search = this.searchQuery; // Format the search query for URL
  }

    if (this.currentSort) {  // Add sorting parameter
      query.sort = this.currentSort;
    }

    if (this.selectedScentgender.length) {
      query.gender = this.selectedScentgender.join(',');
    }
    
    // Handle selected and excluded types
    const selectedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'selected');
    const excludedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'excluded');

    if (selectedTypes.length) {
      query.type = selectedTypes.join(',');
    }
    
    if (excludedTypes.length) {
      query.excludeType = excludedTypes.join(',');
    }

    if (this.selectedBrand.length) {
      query.brand = this.selectedBrand.join(',');
    }
    
    // Add slider values to the URL query
    if (this.selectedPrice[0] !== 1 || this.selectedPrice[1] !== 5) {
      query.price = this.selectedPrice.join(',');
    }
    
    if (this.selectedLongevity[0] !== 1 || this.selectedLongevity[1] !== 4) {
      query.longevity = this.selectedLongevity.join(',');
    }
    
    if (this.selectedSillage[0] !== 1 || this.selectedSillage[1] !== 4) {
      query.intensity = this.selectedSillage.join(',');
    }

    if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
      this.$router.push({ query });
    }
    
    
  },


  loadFiltersFromUrl() {
    const query = this.$route.query;

     // Load and set the number of items to show from URL if available
     if (query.items) {
        this.showItemsCount = parseInt(query.items, 10) || this.itemsPerPage; // Default to itemsPerPage if parsing fails
    }

    if (query.search) {

    this.searchQuery = this.formatFromURL(query.search);
    this.preQuery = this.searchQuery; // Reflect it in the preQuery as well
    }

     if (query.sort) {  // Load sorting from the URL
    this.currentSort = query.sort;
    }

    if (query.gender) {
      this.selectedScentgender = query.gender.split(',');
    }
    
    if (query.type) {
      this.selectedType = query.type.split(',').reduce((acc, type) => {
        acc[type] = 'selected';
        return acc;
      }, {});
    }

    if (query.excludeType) {
      query.excludeType.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'excluded');
      });
    }

    if (query.brand) {
      this.selectedBrand = query.brand.split(',');
    }

    // Load slider values from the URL
    if (query.price) {
      this.selectedPrice = query.price.split(',').map(Number);
    }

    if (query.longevity) {
      this.selectedLongevity = query.longevity.split(',').map(Number);
    }

    if (query.intensity) {
      this.selectedSillage = query.intensity.split(',').map(Number);
    }

    this.sortPerfumes();

    // Add more filters as needed
  },



  syncFiltersWithRoute(query, scrollup = true) {
  if (query.search) {
    this.searchQuery = this.formatFromURL(query.search);
    this.preQuery = this.searchQuery;
  } else {
    this.searchQuery = '';
    this.preQuery = '';
  }

  if (query.items) {
        this.showItemsCount = parseInt(query.items, 10) || this.itemsPerPage; // Update showItemsCount based on URL
    }

     if (query.sort) {  // Sync sorting from the URL
    this.currentSort = query.sort;
    this.tempSort = query.sort;
  }


  // Process type filters (including exclusions)
  this.selectedType = {};
  if (query.type) {
    query.type.split(',').forEach(type => {
      this.$set(this.selectedType, type, 'selected');
    });
  }

  if (query.excludeType) {
    query.excludeType.split(',').forEach(type => {
      this.$set(this.selectedType, type, 'excluded');
    });
  }
  // Sync temp type filters
  this.tempSelectedType = { ...this.selectedType };

  // Process gender filters
  this.selectedScentgender = [];
  if (query.gender) {
    this.selectedScentgender = query.gender.split(',');
  }
  // Sync temp gender filters
  this.tempSelectedScentgender = [...this.selectedScentgender];

  // Process brand filters
  this.selectedBrand = [];
  if (query.brand) {
    this.selectedBrand = query.brand.split(',');
  }
  // Sync temp brand filters
  this.tempSelectedBrand = [...this.selectedBrand];

  // Update slider values for price
  this.selectedPrice = [1, 5];
  if (query.price) {
    this.selectedPrice = query.price.split(',').map(Number);
  }
  // Sync temp price filters
  this.tempSelectedPrice = [...this.selectedPrice];

  // Update slider values for longevity
  this.selectedLongevity = [1, 4];
  if (query.longevity) {
    this.selectedLongevity = query.longevity.split(',').map(Number);
  }
  // Sync temp longevity filters
  this.tempSelectedLongevity = [...this.selectedLongevity];

  // Update slider values for intensity
  this.selectedSillage = [1, 4];
  if (query.intensity) {
    this.selectedSillage = query.intensity.split(',').map(Number);
  }
  // Sync temp intensity filters
  this.tempSelectedSillage = [...this.selectedSillage];

  // Apply the filters and search
  this.filterPerfumes(false);
  this.sortPerfumes();

  if (scrollup) {
      this.$nextTick(() => {
        setTimeout(() => {
          console.log('TopJump by syncFilterswithRoute'); // Add console log
          window.scrollTo(0, 0);
        }, 10); // Delay by 10 milliseconds so that it works right after the Restored Scroll
      });
    }
  
},



formatFromURL(text) {
  return text.replace(/-/g, ' '); // Replace hyphens with spaces
},







  ratingBarStyle(perfume, ratingValue) {
    const endColor = perfume.dom_color || 'black';
    const widthPercentage = (ratingValue / 5) * 100;
    const style = {
      background: `linear-gradient(to right, #eeeeee, ${endColor})`,
      width: `${widthPercentage}%`,
      height: '100%',
      borderRadius: '2px',
    };

    return style;
  },


toggleNoteDropdown(event, note, perfumeId) {
  const { clientX: x, clientY: y } = event;

  // Check if the dropdown is already visible for the same note and perfume
  if (this.noteMenu.visible && this.noteMenu.note === note && this.noteMenu.perfumeId === perfumeId) {
    this.closeNoteDropdown(); // Close the dropdown
  } else {
    this.noteMenu = {
      visible: true,
      note,
      perfumeId, // Store the perfume ID
      x, // Use clientX directly for fixed positioning
      y, // Use clientY directly for fixed positioning
    };
      // Add scroll event listener - dissapear when scrolling!
      window.addEventListener('scroll', this.closeNoteDropdown);
  }
},


   

  triggerSearchFromDropdown(note) {
    this.addToSearchQuery(note); // Add the note to the search query
    this.blinkSearchBar(); // Trigger the blinking effect
    this.noteMenu.visible = false; // Hide the dropdown
  },
  
  closeNoteDropdown() {
    this.noteMenu.visible = false; // Close the dropdown
  },

  
removeNoteFromSearch(note) {
  // Create a regex to match the full note as a whole word, case-insensitively
  const noteRegex = new RegExp(`\\b${note}\\b`, 'gi'); // 'gi' for global and case-insensitive matching

  // Replace the note in the search query with an empty string
  this.searchQuery = this.searchQuery.replace(noteRegex, '').trim();

  // Clean up extra spaces if multiple words are removed
  this.searchQuery = this.searchQuery.replace(/\s+/g, ' ');

  // Update filters and search results
  this.updateUrlWithFilters(); // Sync with the URL

  this.filterPerfumes(true,false); 
  this.sortPerfumes(); 

  this.noteMenu.visible = false;


  this.blinkSearchBar();
},




  
  resetAndSearch(note) {
    // Reset all filters
    this.selectedBrand = [];
    this.selectedScentgender = [];
    this.selectedType = {};
    this.selectedPrice = [1, 5];
    this.selectedSillage = [1, 4];
    this.selectedLongevity = [1, 4];
  
    // Set the search query to the selected note
    this.preQuery = note;
    this.searchQuery = note;
  
    // Update the URL (if applicable) and refilter
    this.updateUrlWithFilters();
    this.filterPerfumes(true,false);
    this.sortPerfumes();
  
    // Trigger the blinking effect on the search bar
    this.blinkSearchBar();
  
    // Close the dropdown menu
    this.noteMenu.visible = false;
  },

   resetAndFilterByType(type) {
    // Reset all filters
    this.selectedBrand = [];
    this.selectedScentgender = [];
    this.selectedType = {};
    this.selectedPrice = [1, 5];
    this.selectedSillage = [1, 4];
    this.selectedLongevity = [1, 4];

    this.preQuery = '';
    this.searchQuery = '';

    // Set the type filter
    this.$set(this.selectedType, type, 'selected');
    this.tempSelectedType = { ...this.selectedType };

    // Update the URL (if applicable) and refilter
    this.updateUrlWithFilters();
    this.filterPerfumes(true,false);
    this.sortPerfumes();

    // Close the dropdown menu
    this.typeMenu.visible = false;

  },
  
  blinkSearchBar() {
    const searchBar = this.$refs.searchInput.$el.querySelector('input');
    searchBar.classList.add('blinking'); // Add the blinking class
  
    // Remove the blinking effect after the animation ends
    setTimeout(() => {
      searchBar.classList.remove('blinking');
    }, 1500); // Duration matches the blinking animation
  },



  toggleTypeDropdown(event, type) {
  const { clientX: x, clientY: y } = event;

  // Check if the dropdown is already visible for the same type
  if (this.typeMenu.visible && this.typeMenu.type === type) {
    this.closeTypeDropdown(); // Close the dropdown
  } else {
    this.typeMenu = {
      visible: true,
      type,
      x,
      y,
    };

    // Add scroll event listener - dissapear when
    window.addEventListener('scroll', this.closeTypeDropdown);
    
  }
},

toggleBrandDropdown(event, brand) {
  
  
  const { clientX: x, clientY: y } = event;

  if (this.brandMenu.visible && this.brandMenu.brand === brand) {
    this.closeBrandDropdown(); // Close the dropdown if it's already open for the same brand
  } else {
    this.brandMenu = {
      visible: true,
      brand,
      x,
      y,
    };
    window.addEventListener('scroll', this.closeBrandDropdown);
  }
},

closeBrandDropdown() {
    this.brandMenu.visible = false; // Close the dropdown
  },




  closeTypeDropdown() {
    this.typeMenu.visible = false; // Close the dropdown
  },

  filterByBrand(brandName) {
    // Set the temporary selected brand to just the clicked brand
    this.tempSelectedBrand = [brandName];

      // Reset all filters
      this.selectedBrand = [];
    this.selectedScentgender = [];
    this.selectedType = {};
    this.tempSelectedType = {};
    this.selectedPrice = [1, 5];
    this.selectedSillage = [1, 4];
    this.selectedLongevity = [1, 4];

    this.preQuery = '';
    this.searchQuery = '';

    // Update the URL (if applicable) and refilter
    this.updateUrlWithFilters();
    this.filterPerfumes(true,false);
    this.sortPerfumes();

    // Apply filters to update the displayed results according to the selected brand
    this.applyFilters();

    // Trigger a badge blink or other visual feedback to indicate that the filter has been applied
    this.triggerBadgeBlink();

    this.brandMenu.visible = false; // Close the dropdown
},



addTypeFilter(type) {
    if (!this.tempSelectedType[type]) {
      this.$set(this.tempSelectedType, type, 'selected');
    }
    this.applyFilters(); // Apply the filters to preview results
    this.typeMenu.visible = false; // Close the dropdown
    
    this.triggerBadgeBlink();
  },

  
removeImportanceFromType(type) {
  if (this.tempSelectedType[type] === 'selected') {
    this.$delete(this.tempSelectedType, type); // Remove the 'selected' filter
  }
  this.applyFilters(); // Apply the filters to preview results
  this.typeMenu.visible = false; // Close the dropdown
  
  this.triggerBadgeBlink(); // Trigger the badge blink effect
},




    excludeTypeFilter(type) {
    if (!this.tempSelectedType[type]) {
      this.$set(this.tempSelectedType, type, 'excluded');
    } else if (this.tempSelectedType[type] === 'selected') {
      this.$set(this.tempSelectedType, type, 'excluded');
    } else {
      this.$delete(this.tempSelectedType, type); // Remove exclusion
    }
    this.applyFilters(); // Apply the filters to preview results
    this.typeMenu.visible = false; // Close the dropdown
    
    this.triggerBadgeBlink();
  },

   triggerBadgeBlink() {
      this.isBlinking = true;
      setTimeout(() => {
        this.isBlinking = false;
      }, 700); // Stop blinking after 1 seconds
    },

toggleSimilarScentDropdown(event, types, perfumeId) {
  const { clientX: x, clientY: y } = event;

  // Check if the selected types match the currently applied type filters
  const areTypesAlreadySelected = types.every(
    (type) => this.selectedType[type] === 'selected'
  );

  if (
    this.similarScentMenu.visible &&
    this.similarScentMenu.types === types &&
    this.similarScentMenu.perfumeId === perfumeId
  ) {
    this.closeSimilarScentDropdown(); // Close the dropdown
  } else {
    this.similarScentMenu = {
      visible: true,
      types,
      x, // Use clientX directly for fixed positioning
      y, // Use clientY directly for fixed positioning
      perfumeId, // Associate dropdown with the specific perfume
      areTypesAlreadySelected, // Add this flag to determine the dropdown content
    };
  
    // Add scroll event listener - dissapear when scrolling!
    window.addEventListener('scroll', this.closeSimilarScentDropdown);
  
  }
},





  closeSimilarScentDropdown() {
    this.similarScentMenu.visible = false;
  },


  findSimilarScent(types) {
    // Reset all filters
    this.deleteAllFilters
    this.selectedType = {};

    // Reset the search term
    this.searchQuery = '';
    this.preQuery = '';

    // Apply filters for the selected scent types
    types.forEach((type) => {
      this.$set(this.selectedType, type, 'selected');
    });

    // Apply filters and search
    this.updateUrlWithFilters();
    this.filterPerfumes(true,false);
    this.sortPerfumes();

    // Close the dropdown
    this.closeSimilarScentDropdown();
    this.triggerBadgeBlink();

    this.$nextTick(() => { // Scroll to Top
    console.log('TopJump by findSimilarScent'); // Add console log
    window.scrollTo(0, 0); 
    });

  },


    redirectToPricesPage() {
      this.$router.push('/prices'); // Ensure router is correctly configured in your Vue app
    },

  


  generateBarContainerStyle() {
    return {
      backgroundColor: 'white',
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden', // Ensures the inner bar respects the border radius
    };
  },

    valueRating(perfume) {
      return perfume.someValue; // Replace with your logic
    },
    

  toggleDrawer() {
    if (!this.isDrawerOpen) {
        // Opening the drawer
        this.isDrawerOpen = true;
        const drawer = this.$refs.filterDrawer;
        if (drawer) {
            drawer.style.transition = 'transform 0.3s ease-out';
            drawer.style.transform = 'translateY(0%)'; // Ensure the drawer is visible
            drawer.style.zIndex = '1000'; // Ensure it’s above other elements
        }
        document.body.classList.add('no-scroll');
        document.body.style.top = `-${window.scrollY}px`;
        this.$nextTick(() => {
            if (this.$refs.drawerContent) {
                this.$refs.drawerContent.scrollTop = 0;
            }
        });
        this.resetTempFilters();
        console.log('Drawer opened');
    } else {
        // Ensure it's not already closing or closed
        this.closeDrawerCompletely();
    }
},


closeDrawerCompletely() {
    const drawer = this.$refs.filterDrawer;
    if (drawer) {
        // Move the drawer completely out of view
        drawer.style.transition = 'transform 0.19s ease-out';
        drawer.style.transform = 'translateY(100%)';
        console.log('Drawer closed completely and moved out of view');
    }

    // Resetting the internal state
    this.currentTranslateY = 0; // Reset translateY to the closed position
    this.initialTranslateY = 0;  // Reset initial translateY for the next interaction
    this.isDrawerOpen = false;   // Mark the drawer as closed
    
    setTimeout(() => {
            drawer.style.zIndex = '0'; // Lower z-index after the closing animation
        }, 200);

    // Reset scroll lock and position
    document.body.classList.remove('no-scroll');
    window.scrollTo(0, this.scrollPosition); // Restore original scroll position
     console.log('TopJump by closeDrawerCompletely'); // Add console log
    document.body.style.top = '';

    // Reset any additional state or UI components related to the drawer
    this.resetTempFilters();  // Example of resetting temporary filters
    console.log('All internal parameters have been reset.');
},




    togglePriceDrawer(perfume = null) {
  this.isPriceDrawerOpen = !this.isPriceDrawerOpen;

  if (this.isPriceDrawerOpen) {
    if (perfume) {
      this.selectedPerfume = perfume; // Set the selected perfume

      // Concatenate the name, brand, and concentration
      const keywords = `${perfume.name} ${perfume.brand} ${perfume.concentration || ''}`.trim();
      this.selectedPerfumeKeywords = keywords; // Save concatenated keywords

   // Assign properties
        this.selectedPerfumeName = perfume.name || ''; // Handle missing values
        this.selectedPerfumeBrand = perfume.brand || '';
        this.selectedPerfumeConcentration = perfume.concentration || '';

        console.log('Perfume:', perfume);
        console.log('Selected name:', this.selectedPerfumeName);
        console.log('Selected brand:', this.selectedPerfumeBrand);
        console.log('Selected concentration:', this.selectedPerfumeConcentration);
      }

        // Capture current scroll position
        this.scrollPosition = window.scrollY;
        document.body.style.top = `-${this.scrollPosition}px`;
        document.body.classList.add('no-scroll');
    
        // Scroll the filter drawer content to the top
        this.$nextTick(() => {
          if (this.$refs.drawerPricesContent) {
            this.$refs.drawerPricesContent.scrollTop = 0;
          }
        });        

      } else {
        // Reset temporary filters to actual filters
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';

        window.scrollTo(0, this.scrollPosition); // Restore scroll position 
         console.log('TopJump by togglePriceDrawer'); // Add console log      
      }
     


    },


 // Removes certain filters when pressing 'Remove Filters' buttons when no results
    removeFilter(filterType) {
      removeFilter(filterType, this);
    },

    removeFilterFromChip(filter) {
  if (this.selectedScentgender.includes(filter)) {
    this.removeFilter('gender', this);
  } else if (this.selectedBrand.includes(filter)) {
    // Remove only the specific brand instead of all
    this.selectedBrand = this.selectedBrand.filter(brand => brand !== filter);
    this.tempSelectedBrand = this.tempSelectedBrand.filter(brand => brand !== filter);
    this.filterPerfumes();
  } else if (filter in this.tempSelectedType || filter.replace(/~/g, '') in this.tempSelectedType) { 
    const cleanFilter = filter.replace(/~/g, ''); // Remove ~ if present
    this.$delete(this.tempSelectedType, cleanFilter);
    this.$delete(this.selectedType, cleanFilter);
    this.tempSelectedType = { ...this.tempSelectedType }; // Ensure reactivity
    this.selectedType = { ...this.selectedType }; // Ensure reactivity
    this.filterPerfumes();
  } else if (filter.startsWith('Price:')) {
    this.removeFilter('price', this);
  } else if (filter.startsWith('Longevity:')) {
    this.removeFilter('longevity', this);
  } else if (filter.startsWith('Intensity:')) {
    this.removeFilter('sillage', this);
  } else if (filter.startsWith('Sort by:')) {
    this.tempSort = '';
    this.currentSort = '';
  }
  
  this.updateUrlWithFilters();
},



  //Filter Reset Button & Sort when exit the filtermenu without apply
  resetTempFilters() {
    resetTempFilters(this);
  },

//Filter Reset Button & Sort (for Delete all Button in Filtermenu)
  DeleteTempFilters() {
    deleteTempFilters(this);
  },

  openResetDialog() {  
    this.showResetDialog = true;  // Check if there are any query parameters in the current URL     
     window.addEventListener('scroll', this.closeResetDialog); // Add scroll event listener - dissapear when
},


  // Hide the dialog
  closeResetDialog() {
    this.showResetDialog = false;
  },

  // Confirm reset action
  confirmResetFilters() {    
    this.showResetDialog = false; // Close the dialog

    if (Object.keys(this.$route.query).length > 0) {
        this.DeleteAllFilters()
  } else {
    console.log('Reset dialog not triggered: No URL parameters present.');
  }    
    
  },

// Click Logo to start new search
DeleteAllFilters() {
  // Clear all filters in your app
  deleteAllFilters(this);
  this.filterPerfumes(true,true);

  // Update the URL to base `/perfumes` without parameters
  this.$router.replace({
    path: '/perfumes', // Navigate to the base perfumes route
    query: {} // Clear all query parameters
  }).then(() => {
    console.log('URL reset to base /perfumes');

    // Scroll to the top of the page after URL update
    this.$nextTick(() => {
      console.log('TopJump by DeleteAllFilters'); // Add console log
      window.scrollTo(0, 0);
    });
  }).catch(err => {
    console.error('Error while navigating to /perfumes:', err);
  });
},


// Sorting
sortPerfumes() {
      this.filteredPerfumes = sortPerfumes(
        this.filteredPerfumes, 
        this.currentSort, 
        this.priceToNumber, 
        this.searchQuery
      );
    },
    
    shuffleArray(array) {
      return shuffleArray(array);
    },

  toggleScentgender(option) {
    const index = this.tempSelectedScentgender.indexOf(option);
    if (index > -1) {
      this.tempSelectedScentgender.splice(index, 1);
    } else {
      this.tempSelectedScentgender.push(option);
    }
  },

  toggleType(option) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - this.lastTap;
      const doubleTapThreshold = 210; // Adjusted threshold for double-tap detection

      // Count currently excluded items
      const excludedCount = Object.values(this.tempSelectedType).filter(state => state === 'excluded').length;

      if (this.lastTappedOption === option && tapLength < doubleTapThreshold && tapLength > 0) {
        // Double-tap detected on the same item
        if (this.tempSelectedType[option] === 'excluded') {
          this.$delete(this.tempSelectedType, option); // Bring it back to normal state
        } else {
          if (excludedCount < this.maxExcluded) {
            this.$set(this.tempSelectedType, option, 'excluded');
          } else {
            // Optional: Provide feedback to the user that they can't exclude more items
            
          }
        }
        this.lastTappedOption = null; // Reset last tapped option
      } else {
        // Single tap detected or different item tapped
        const currentState = this.tempSelectedType[option];
        const selectedCount = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'selected').length;

        if (currentState === 'selected') {
          this.$delete(this.tempSelectedType, option);
        } else if (currentState === 'excluded') {
          this.$delete(this.tempSelectedType, option);
        } else {
          // Only add a new selected if fewer than 15 are already selected
          if (selectedCount < 8) {
            this.$set(this.tempSelectedType, option, 'selected');
          }
        }
      }

      this.lastTap = currentTime; // Update last tap time
      this.lastTappedOption = option; // Update last tapped option
    },
  

toggleBrand(option) {
    const index = this.tempSelectedBrand.indexOf(option);
    if (index > -1) {
      this.tempSelectedBrand.splice(index, 1);
    } else {
      this.tempSelectedBrand.push(option);
    }
  },

  selectTempSort(option) {
    this.tempSort = this.tempSort === option ? '' : option; 
  },


applyFiltersAndToggleDrawer()
    {
      this.applyFilters();
     this.toggleDrawer(); 
    },

  
  applyFilters() {
    this.selectedScentgender = [...this.tempSelectedScentgender];
    this.selectedLongevity = [...this.tempSelectedLongevity];
    this.selectedSillage= [...this.tempSelectedSillage];
    this.selectedType = { ...this.tempSelectedType };
    this.selectedPrice = [...this.tempSelectedPrice];
    this.selectedBrand = this.tempSelectedBrand;
    this.currentSort = this.tempSort;

    // Update the URL with the selected filters
    this.updateUrlWithFilters();

    this.sortPerfumes();
    this.filterPerfumes(true, false); // Call the filter method 
  },
  

  

////APPLY FILTERS AND SEARCH /////
  filterPerfumes,



  // convert categories into numerics
  sillageRating(sillage) {
    const ratingValues = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'strong': 4,
      'very strong': 5
    };
    return ratingValues[sillage.toLowerCase()] || 0; // Default to 0 if not found
  },

  longevityRating(longevity) {
    const ratingValues = {
      'very short': 1,
      'short': 2,
      'medium': 3,
      'long': 4,
      'very long': 5
    };
    return ratingValues[longevity.toLowerCase()] || 0; // Default to 0 if not found
  }, 
 
  priceToNumber(price) {
    const priceMap = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'high': 4,
      'very high': 5
    };
    return priceMap[price.toLowerCase()] || 0;
  },


  async getUserInfo() {
      // Get user agent
      this.userAgent = navigator.userAgent;

      // Fetch country information
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Failed to fetch country information');
        const { country_name: country, country: countryCode } = await response.json();

        this.userCountry = country;
        this.userCountryCode = countryCode;

        console.log('User Info:', {
          userAgent: this.userAgent,
          userCountry: this.userCountry,
          userCountryCode: this.userCountryCode,
        });
      } catch (error) {
        console.error('Error fetching user information:', error);
      }
    },

    generateAmazonLink(brand, name, concentration) {
    const combinedName = [brand, name, concentration] // Combine brand, name, and concentration
    .filter(Boolean) // Remove any undefined or empty values
    .join(' '); // Join them with a space
    const normalizedName = combinedName

      .normalize('NFD') // Normalize accents
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/\s+/g, ' ') // Replace multiple spaces with one
      .trim() // Trim whitespace
      .toLowerCase() // Lowercase all letters
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize the first letter of each word

    return `https://www.amazon.com/s?k=${encodeURIComponent(normalizedName)}&tag=perfumeprophe-20`;
  },
  
  markImageAsLoaded(id) {
    this.$set(this.loadedImages, id, true);
  },

  handleImageLoad(event) {
    event.target.classList.add('loaded-note');
  },

getScentTypeColor, 
    
goToDetails(image_name, id, name, brand, concentration, scentgn, url_parameter) {
  
   if (!this.isOnline) {
    this.showOfflineWarning = true; // Show the warning
    return; // Prevent navigation
  }
  
  const description = this.formatForURL(`${name} ${concentration}`);
  const brand_clean = this.formatForURL(`${brand}`);
    this.$router.push({ name: 'PerfumeDetails', params: { image_name: image_name, id: id, brand:brand_clean, description: description, scentgn:scentgn, url_parameter:url_parameter } });
    },

    formatForURL(text) {
  return text.toString().toLowerCase()
    .normalize('NFD') 
    .replace(/\s+/g, '-')        // Replace spaces with hyphens
    .replace(/[^a-z0-9-]+/g, '') // Remove all non-word characters except hyphens without escaping
    .replace(/-{2,}/g, '-')      // Replace multiple hyphens with a single hyphen without escaping
    .replace(/^-+|-+$/g, '');    // Trim hyphens from the start and end without escaping
},

showDialogOnStartPage() {
  // Check if the user opted never to show the instructions again
  const doNotShow = localStorage.getItem('doNotShowStartInstructions');
  if (doNotShow === 'true') {
    this.showStartInstructions = false;
    return;
  }

  const lastShown = localStorage.getItem('startInstructionsLastShown');
  const now = Date.now();
  const oneMonth = 21 * 24 * 60 * 60 * 1000; // 21 days in milliseconds

  if (!lastShown || now - Number(lastShown) > oneMonth) {
  // Wait 1000ms before actually showing instructions
  setTimeout(() => {
    this.showStartInstructions = true;
  }, 1000);

  localStorage.setItem('startInstructionsLastShown', now);
} else {
  this.showStartInstructions = false;
}

},


  handleTouchStart(e) {
    this.startY = e.touches[0].clientY;
    this.initialTranslateY = this.currentTranslateY; // Store the initial position at the start of the touch
    console.log('Touch start Y:', Math.round(this.startY), 'Initial Translate Y:', Math.round(this.initialTranslateY));
    e.preventDefault();
  },

  handleTouchMove(e) {
    const moveY = e.touches[0].clientY;
    const diffY = moveY - this.startY;
    this.currentTranslateY = this.initialTranslateY + diffY;
    console.log('Touch move Y:', Math.round(moveY), 'Diff Y:', Math.round(diffY), 'New Translate Y:', Math.round(this.currentTranslateY));

    // Apply the movement to the drawer
    this.updateDrawerPosition();
    e.preventDefault();
  },

  handleTouchEnd() {
    console.log('Touch end. Final Translate Y:', this.currentTranslateY);
    if (this.currentTranslateY < 50) {
        // Not enough swipe down to trigger full close, reset to top
        this.currentTranslateY = 0; // Reset to the start position
        this.updateDrawerPosition();
        console.log('Drawer returned to top because Translate Y is below 50');
    } else {
        // Enough swipe down to trigger the drawer to close
        
        this.updateDrawerPosition();        
        this.applyFiltersAndToggleDrawer(); // Only toggle if it was open
        
        console.log('Drawer moved to close because Translate Y is above 50');
    }
},



    updateDrawerPosition() {
    const drawer = this.$refs.filterDrawer;
    if (drawer) {
        // Prevent the drawer from moving above its original position
        if (this.currentTranslateY < 0) {
            this.currentTranslateY = 0;
        }
        drawer.style.transition = 'none';
        drawer.style.transform = `translateY(${this.currentTranslateY}px)`;
        console.log('Drawer position updated to:', Math.round(this.currentTranslateY));
    }
},


getConcentrationStyle(concentration) {
    if (!concentration) return {};

    let styles = {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      overflow: 'hidden',
      maxWidth: '168px', // Default max width
      
      color: 'grey',
      paddingTop: '5px'
    };

    const length = concentration.length;
    if (length > 25) {
      styles.maxWidth = '0px'; // Hide text by setting maxWidth to 0
    } else if (length > 20) {
      styles.transform = 'translateX(-20%)'; // Move text to the left
    }

    return styles;
  },


  getImageForNote(note) {
    if (!note) return '';

    const normalizedNote = note
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/Absolute|Co2|Cest|Zest/gi, '')
      .trim();
    let parts = normalizedNote.split(' ');
    let usedImages = new Set(); // Assuming you have a way to persist this set if needed

    // Try to find an image starting with the full term and progressively reducing
    for (let i = 0; i < parts.length; i++) {
      for (let j = parts.length; j > i; j--) {
        let imageName = parts.slice(i, j).join('_').toLowerCase() + '.webp';
        if (!usedImages.has(imageName)) {
          try {
            const imageSrc = require(`@/assets/notes/${imageName}`);
            usedImages.add(imageName); // Mark this image as used
            return imageSrc; // Return the image source directly
          } catch (e) {
            // Continue to the next iteration if the image file is not found
            continue;
          }
        }
      }
    }
    return ''; // Return an empty string or default image path if no image found
  },
  




  },
};

</script>


<style scoped>

/* Pagination */

.custom-pagination >>> .v-pagination__item {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the color as needed */
}

.custom-pagination >>> .v-pagination__item--active {
  background-color: #000 !important; /* Active item background color */
  color: #ffffff; /* Active item text color */
  border-radius: var(--radius-basic) !important
}

.custom-pagination >>> .v-pagination__item--disabled {
  color: #b0b0b0; /* Adjust the disabled item color as needed */
}

.custom-pagination >>> .v-pagination__more {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the more button color as needed */
}

.custom-pagination >>> .v-pagination__navigation {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the arrow color as needed */
}

.custom-pagination >>> .v-pagination__navigation--disabled {
  color: #b0b0b0; /* Adjust the disabled arrow color as needed */
}


.note-dropdown {
  position: fixed;
  background-color: none;
  color: white;
  border-radius: var(--radius-basic);
  padding: 8px;
  font-size: 14px;
  box-shadow: none !important;
  z-index: 30;
  transform: translateY(5px);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.note-dropdown.visible {
  opacity: 1;
  pointer-events: auto;
}

.dropdown-option {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  padding: 10px 12px;
  margin: 5px 0;
  background-color: #222222; /* Slightly lighter than black */
  border-radius: var(--radius-buttons3);
  cursor: pointer;
  z-index: 30;
  transition: background-color 0.2s ease-in-out;
}

.dropdown-option:hover {
  background-color: #444444; /* Slightly lighter hover effect */
}

.dropdown-option span {
  color: #ffffe0; /* Bright pale yellow for the note */
}
.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.00001) !important; /* Adjusted opacity for more visibility */
  z-index: 9; /* Below the dropdown but above other content */
}


.similar-scent-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 20px;
}


@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(10px); /* Subtle rise animation */
  }
  30% {
    opacity: 1;
    transform: translateY(0); /* Back to normal */
  }
  70% {
    opacity: 1;
    transform: translateY(0); /* Pause briefly */
  }
  100% {
    opacity: 0;
    transform: translateY(-10px); /* Subtle fade out and lift */
  }
}


.scroll-icon {
  color: rgb(159, 159, 159);
  font-size: 32px; /* Icon size */
}



.instruction-text {
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  text-align: left;
}


.instruction-dropdown {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: var(--radius-basic);
  padding: 10px;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}

.auto-suggestion-dropdown {
  position: absolute; 
  top: 100%; 
  left: 0;
  width: 100%; 
  background-color: white;
  z-index: 1000; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  border-radius: var(--radius-basic);
  overflow: hidden; 
}

.auto-suggestion-dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.auto-suggestion-dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.auto-suggestion-dropdown li:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Highlight on hover */
}



/*Price Comparison Dialog/*


/* Dialog Card Styling */
.dialog-card {
  border-radius: var(--radius-basic);
  overflow: hidden;
}

/* Header Styling */
.dialog-header {
  align-items: center;
  background-color: white;
  padding: 10px 20px !important;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Content Styling */
.dialog-content {
  padding: 4px !important;
  color: black;
  font-size: 16px; /* Larger text */
  line-height: 1.5; /* Better readability */
}

/* Footer Styling */
.dialog-footer {
  border-top: 1px solid #ccc;
  padding: 10px 20px !important;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 10;
}


.fade-in {
  opacity: 0;  
}

.fade-in.loaded {
  opacity: 1;
  transition: ease-in 0.21s
}

.fade-in.loaded-note {
  opacity: 0;
  animation: fade-in-out 1.2s ease-in-out 0s forwards; /* Added delay here */
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(12px); /* Subtle rise animation */
  }
  30% {
    opacity: 0.3;
    transform: translateY(0); /* Back to normal */
  }
  70% {
    opacity: 0.5;
    transform: translateY(0); /* Pause briefly */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Pause briefly */
  }
}


.note-image {
  width: 100%; /* Adjust size as needed */
  max-width: 340px;
  border-radius: var(--radius-basic);
}



</style>