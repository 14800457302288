<template>
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main>
       
        
        <div>
          <v-list class="saved-searches-list">
            <v-list-item 
              v-for="(search, index) in savedSearches" 
              :key="index" 
              class="search-entry"  
              
            >
              <v-list-item-icon class="icon-container">
                <v-icon class="cursor-pointer bigger-icon" @click="navigateToSearch(search.url)">mdi-magnify</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title>
                  <div class="selected-filters-container-2">
                    <div class="selected-filters">
                      <v-chip 
                      v-for="(chip, chipIndex) in formatSearchAsChips(search.url)" 
                        :key="chipIndex" 
                        class="selected-filter-chip-saved"
                        :class="{ 'excluded-text': chip.excluded }"
                      >
                        <v-icon v-if="typeof chip === 'string' && chip.startsWith('Search:')" size="16" class="mr-1" color="black">
                          mdi-magnify
                        </v-icon>
                        {{ typeof chip === 'string' ? chip.replace('Search:', '').trim() : chip.text }}
                      </v-chip>
                    </div>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle class="timestamp">
                  <v-icon size="14" class="mr-1">mdi-calendar</v-icon>  {{ formatTimestamp(search.timestamp) }}
                </v-list-item-subtitle>
              </v-list-item-content>
  
              <v-list-item-icon class="icon-container">
                <v-icon 
                  class="cursor-pointer delete-icon bigger-icon"
                  @click.stop="showDeleteDialog(search.url)"
                >
                  mdi-delete-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
  
          <v-dialog v-model="deleteDialog" max-width="400">
            <v-card>
              <v-card-title class="headline">Delete Combo</v-card-title>
              <v-card-text>
                Do you really want to delete this combo?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="deleteDialog = false">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="removeFromFavorites">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        
<div v-if="isMobile && savedSearches.length === 0" style="height: 54px;"></div>
<div v-if="!isMobile && savedSearches.length === 0" style="height: 133px;"></div>

  <div v-if="savedSearches.length === 0" class="no-results-container">
    <div class="no-results-message-fav">
      You did not save any search yet! Find a search combo that you like and press 
      <v-icon color=grey >mdi-heart-outline</v-icon> to add it to your favorites.
    </div>
  </div>

      </v-main>
    </v-app>
  </template>
  
  
  <script>

  export default {
    data() {
      return {
        savedSearches: [],
        deleteDialog: false,
        searchToDelete: null,
        priceMarks: { 1: '$', 2: '$$', 3: '$$$', 4: '$$$$', 5: 'Luxury' },
        longevityMarks: { 1: 'Short', 2: 'Medium', 3: 'Long', 4: 'Very Long' },
        sillageMarks: { 1: 'Silent', 2: 'Medium', 3: 'Loud', 4: 'Very Loud' }
      };
    },
    mounted() {
      this.loadSavedSearches();
    },
    methods: {
      loadSavedSearches() {
        const storedSearches = JSON.parse(localStorage.getItem("favoriteSearches")) || [];
        this.savedSearches = storedSearches.map(url => ({
          url,
          timestamp: localStorage.getItem(`timestamp_${url}`) || Date.now()
        }));
      },

      formatSearchAsChips(url) {
            const params = new URLSearchParams(url.split('?')[1]);
            const chips = [];

            // Ensure fixed order of filters
            if (params.get("search")) chips.push(`Search: ${params.get("search").replace(/-/g, ' ')}`);

            if (params.get("type")) chips.push(...params.get("type").split(","));

            if (params.get("excludeType")) {
                params.get("excludeType").split(",").forEach(type => {
                    chips.push({ text: type, excluded: true }); 
                });
            }
            
            if (params.get("gender")) chips.push(...params.get("gender").split(","));
            
            if (params.get("intensity")) {
                const [min, max] = params.get("intensity").split(",").map(Number);
                chips.push(`Intensity: ${this.sillageMarks[min]} - ${this.sillageMarks[max]}`);
            }
            
            if (params.get("longevity")) {
                const [min, max] = params.get("longevity").split(",").map(Number);
                chips.push(`Longevity: ${this.longevityMarks[min]} - ${this.longevityMarks[max]}`);
            }

            if (params.get("price")) {
                const [min, max] = params.get("price").split(",").map(Number);
                chips.push(`Price: ${this.priceMarks[min]} - ${this.priceMarks[max]}`);
            }

            if (params.get("brand")) chips.push(...params.get("brand").split(","));

            

            return chips;
            },


    
      formatTimestamp(timestamp) {
       // return new Date(parseInt(timestamp, 10)).toLocaleDateString();
        return new Date(parseInt(timestamp, 10)).toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
      },
      showDeleteDialog(url) {
        this.searchToDelete = url;
        this.deleteDialog = true;
      },
      removeFromFavorites() {
        if (this.searchToDelete) {
          let savedSearches = JSON.parse(localStorage.getItem("favoriteSearches")) || [];
          savedSearches = savedSearches.filter(search => search !== this.searchToDelete);
          localStorage.setItem("favoriteSearches", JSON.stringify(savedSearches));
          localStorage.removeItem(`timestamp_${this.searchToDelete}`);
          this.loadSavedSearches();
          this.deleteDialog = false;
        }
      },
      navigateToSearch(url) {
      this.$router.push(url);
    },

    }
  };
  </script>
  
  <style scoped>
  .saved-searches-list {
    padding: 12px;
    -webkit-tap-highlight-color: transparent !important; /* Disables grey touch highlight on iOS/Safari */
    user-select: none !important; /* Prevents accidental text selection */
  }
  
  .search-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 2px 4px;
    min-height: 48px;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    padding: 7px 3px;
  }
  
  .bigger-icon {
    font-size: 28px;
  }
  
  .selected-filters-container-2 {
    margin-top: 5px;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding-bottom: 5px;
    align-items: center;
  }
  .selected-filters-container-2::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}
.selected-filters {
    display: flex;
    gap: 4px;
    padding: 4px 0;
    align-items: center;
  }
  
  .timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 2px;
    display: flex;
    align-items: center;
  }
  
  .timestamp v-icon {
    margin-right: 4px;
  }
  
  .delete-icon {
    color: grey;
  }

  /* Individual Chips */
.selected-filter-chip-saved {
  margin-right:6px !important; 
  text-align: center;
  font-size: 13px !important; /* Decrease the font size */
  padding: 3px 10px !important;
  max-height: 33px;
  background-color: #e8e8e8 !important;
  color: rgb(12, 12, 12) !important;
  border-radius: var(--radius-chips) !important;
  text-transform: uppercase; /* Convert text to uppercase */
  font-weight: 500;
  font-family: 'Roboto';
}
  

  </style>