<template>

  <v-app> 
  
        <!-- Offline Warning (only when triggered by click) -->
    <div v-if="showOfflineWarning" class="offline-overlay" @click="showOfflineWarning = false">
      <v-card class="offline-alert" @click.stop>
        <v-icon class="offline-icon">mdi-wifi-off</v-icon>
        <span>You are offline. Please check your internet connection.</span>
      </v-card>
    </div>


  
    <div>


      <div class="pl-6 pr-9 pt-9 pb-4">
        <v-card flat>
          <v-card-text 
            :class="isMobile ? 'search-bar-container-mobile' : 'search-bar-container-favorites'"
            v-if="perfumes.length > 0">                    
            <v-text-field
              v-model="preQuery"
              type="search"
              clearable dense solo-inverted flat                
              prepend-inner-icon="mdi-magnify"
              class="custom-search-field"
              color="black"
              background-color="#efefef"
              @click:prepend-inner="focusSearch"
              ref="searchInput"
              enterkeyhint="search"
              @keydown.enter="closeKeyboard"
              placeholder="Search in your favorites"
              style="border-radius: var(--radius-search);" 
            ></v-text-field>                    
          </v-card-text>
        </v-card>
      


      </div>
</div>


  <!-- APP DESIGN: Mobile & Flex -->
  

  
    <div v-if="!isMobile" class="placeholder"></div>
  
        <div>
          <div :class="isMobile ? 'perfume-container-mobile' : 'perfume-container-desktop'" >
            <div :class="showDetails ? (isMobile ? 'mobile-perfume-item' : 'desktop-perfume-item') : 'perfume-item-compact'"
           v-for="(perfume, index) in paginatedItems" 
           :key="`perfume-${index}`">
      
      
  <!-- Compact View -->
  
  
    <div v-if="!showDetails" class="compact-view-container">
    <!-- Image Container -->
    <div class="compact-image-container">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="compact-perfume-image">
    </div>
    <!-- Perfume Info and Scent Types -->
    <div class="compact-content">
      <!-- Perfume Name, Brand, and Rating -->
      <div class="mobile-perfume-info">
        <div v-if="isMobile">
          <span class="perfume-name no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.83em' : (perfume.name.length > 30 ? '0.86em' : '1.02em') }">{{ perfume.name }}</span>
          <br>
          <span class="perfume-brand no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.83em' : (perfume.name.length > 30 ? '0.86em' : '1.02em') }">{{ perfume.brand }}</span>
        </div>
        <div v-if="!isMobile" class="flex-perfume-info">
          <span class="perfume-name no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.86em' : (perfume.name.length > 30 ? '0.95em' : '1.10em') }">{{ perfume.name }}</span>
          <br>
          <span class="perfume-brand no-hover" :style="{ fontSize: perfume.name.length > 40 ? '0.86em' : (perfume.name.length > 30 ? '0.95em' : '1.10em') }">{{ perfume.brand }}</span>
        </div>

      </div>

   

  
      <!-- Scent Types as Chips -->
      <div v-if="isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          small
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
          <!-- Scent Types as Chips -->
          <div v-if="!isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
  
  
    </div>
  </div>
  
  
      <!-- Normal App View (non-compact)-->
  
      <div v-if="showDetails">
  
      <!-- Perfume Name, Brand, and Rating -->
      <div class="perfume-info">
  
                
        <div class="perfume-info-text">
  <!-- If the name is above threshold, show name + brand in one line -->
  <template v-if="perfume.name.length > 35">
      <span
    class="perfume-name"
    :class="{ 'no-hover': isMobile }"
    @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
    :style="{ fontSize: '1.1em' }"
  > {{ perfume.name }}</span>
  <span style="color: grey; margin: 0 6px; font-size: 1.1em;">|</span>
  <span
    class="perfume-brand"
    :class="{ 'no-hover': isMobile }"
    @click.stop="toggleBrandDropdown($event, perfume.brand)"
    :style="{ fontSize: '1.1em', marginBottom: '2px'}"
  >
    {{ perfume.brand }}
  </span>
  </template>

  <!-- Otherwise, name and brand each on its own line -->
  <template v-else>
    <span
      class="perfume-name"
      :class="{ 'no-hover': isMobile }"
      @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
      :style="{ fontSize: '1.15em', color: '#000' }"
    >
      {{ perfume.name }}
    </span>
    <br>
    <span
      class="perfume-brand"
      :class="{ 'no-hover': isMobile }"
      @click="toggleBrandDropdown($event, perfume.brand)"
      :style="{ fontSize: '1.15em', color: 'grey' }"
    >
      {{ perfume.brand }}
    </span>
  </template>
</div>



          <!-- Brand Dropdown -->
    <div v-if="brandMenu.visible" 
         :style="{ top: isMobile ? `${brandMenu.y}px` : `${brandMenu.y}px`, left: isMobile ? '25px' : `${brandMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToBrand(brandMenu.brand)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Show me all {{ brandMenu.brand }} perfumes
      </div>
    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="brandMenu.visible" class="dropdown-overlay" @click="closeBrandDropdown"></div>
  
  



    </div>


            
        <!-- Scent Types as Chips -->
        <div class="mobile-scent-types">
          <v-chip 
            v-for="(type, index) in perfume.combined_types.split('-')"
            :key="`type-${perfume.id}-${index}`"
            :color="getScentTypeColor(type)"
            class="white--text text-uppercase mobile-chip-custom"
            @click="openTypeDropdown($event, type)"
            >
            {{ type.toUpperCase() }}
          </v-chip>
        </div>

            <!-- Type Dropdown -->
    <div v-if="typeMenu.visible" 
         :style="{ top: isMobile ? `${typeMenu.y}px` : `${typeMenu.y}px`, left: isMobile ? '25px' : `${typeMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToType(typeMenu.type)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Show me all {{ typeMenu.type.toLowerCase() }} perfumes
      </div>
    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="typeMenu.visible" class="dropdown-overlay" @click="closeTypeDropdown"></div>
            
  
  
      <div class="mobile-ratings">
            
        
<!-- Image -->
<div class="image-card" :style="{ backgroundColor: '#FFFFFF' }">
  <div :class="{ 'image-container-mobile': isMobile, 'image-container': !isMobile }" style="position: relative;">
    <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="mobile-perfume-image" 
    @click="goToDetails(perfume.image_name, perfume.id, perfume.name, perfume.brand, perfume.concentration, perfume.scentgn, perfume.url_parameter)"
    >
   </div>
   <FavoritesToggle :perfumeId="perfume.image_name" :top="210" :left= "10"  />

  

  <!-- Amazon Link -->
  <v-btn
    outlined
    :href="generateAmazonLink(perfume.brand,perfume.name,perfume.concentration)"
    style="
      position: absolute;
      right: 0;
      margin-top:197px;
      background-color: white;
      color: black;
      border: 1px solid #efefef;
      border-radius: var(--radius-buttons2);   
      text-transform: none; /* Prevents uppercase transformation */
      display: flex;
      align-items: center;
      font-weight: normal;
      justify-content: center;
      padding: 4px 10px; /* Smaller padding for compact size */
    "
  >
    <v-icon style="margin-right: 5px; margin-left: 0px ; font-size: 19px; color: grey;">
      mdi-tag
    </v-icon>
    Amazon
  </v-btn>




</div>

<div style="height:30px"></div>
  
    <v-row>
  
 
  
  <!-- Notes Chips -->

<div :key="chipsKey" class="scrollable-chips-container" ref="chipsContainer">
   
   <div class="chips-row" >
     <!-- Strong Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_strong.split(',').filter(n => n.trim() !== '')"
       :key="`strong-note-${perfume.id}-${index}`"
       class="mobile-chip-strong-notes"
         @click="openNoteDropdown($event, note)"
     >
       {{ note.trim() }}
     </v-chip>
     <!-- Medium Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_medium.split(',').filter(n => n.trim() !== '')"
       :key="`medium-note-${perfume.id}-${index}`"
       class="mobile-chip-medium-notes"
         @click="openNoteDropdown($event, note)"
     >
       {{ note.trim() }}
     </v-chip>
     <!-- Weak Notes -->
     <v-chip
       v-for="(note, index) in perfume.notes_weak_adj.split(',').filter(n => n.trim() !== '')"
       :key="`weak-note-${perfume.id}-${index}`"
       class="mobile-chip-weak-notes"
         @click="openNoteDropdown($event, note)"
     >
       {{ note.trim() }}
     </v-chip>
   </div>
 
     
 </div>


    <!-- Notes Dropdown --> 
    <div v-if="noteMenu.visible" 
         :style="{ top: isMobile ? `${noteMenu.y}px` : `${noteMenu.y}px`, left: isMobile ? '25px' : `${noteMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToNote(noteMenu.note)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Get me all perfumes with {{ noteMenu.note }} 
      </div>
    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="noteMenu.visible" class="dropdown-overlay" @click="closeNoteDropdown"></div>
  
 
 


  
<!-- Ratings Box for Longevity, Projection, and Price -->


<v-col cols="12" class="py-1 ma-0">
    <div class="mobile-rating-box">           
      <v-row>
        <v-col cols="12" class="py-1 ma-0">          
          <!-- Row 1: Projection -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Intensity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, sillageRating(perfume.sillage))"></div>
              </div>
            </v-col>
          </v-row>               
          
          <!-- Row 2: Longevity -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Longevity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, longevityRating(perfume.longevity))"></div>
              </div>
            </v-col>
          </v-row>
  
        <!-- Row 3: Price, EDT, and Year -->
        <v-row class="rating-row align-center">
          <v-col cols="4" class="rating-label pa-0">
            <span v-for="n in priceToNumber(perfume.price)" :key="n" class="price-symbol">$</span>
          </v-col>
          <v-col cols="4" class="text-center" style="color:grey">
            <span :style="{ whiteSpace: 'nowrap',  textOverflow: 'ellipsis', display: 'inline-block' }">
            {{ perfume.concentration }}
            </span>
          </v-col>

          <v-col cols="4" class="text-right" style="color:grey">
            <span>{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? parseInt(perfume.year, 10) : '' }}</span>
          </v-col>
        </v-row>


        </v-col>
      </v-row>
    </div>
  </v-col>
  
      </v-row>
  
      </div>
      </div>
  
          </div>
          </div>
          
          
         
        <v-btn
          v-if="filteredPerfumes.length > 2" fab fixed right
          :style="{
          bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 18px)' : buttonBottom,
          backgroundColor: 'transparent', boxShadow: 'none',right: '20px' }"
          @click="buttonAction"
          class="transparent-btn"
          >
            <v-icon>{{ scrollY > 450 ? 'mdi-arrow-up' : (showDetails ? 'mdi-view-list' : 'mdi-view-grid') }}</v-icon>
        </v-btn>
  
  
          <div v-if="filteredPerfumes.length > itemsPerPage"> 
            <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
            class="custom-pagination"
          ></v-pagination>
          <br>
          <br>    
          </div>
  
            
          <div v-else-if="filteredPerfumes.length === 0 && preQuery ===''" class="no-results-container">
    <div class="no-results-message-fav">
      There is a great scent for everyone! Find a perfume you like and press 
      <v-icon color=grey >mdi-heart-outline</v-icon> to add it to your favorites.
    </div>
  
  </div>
  
  
  </div>
      <br>
      <br>
  


  </v-app>      
</template>


<script>
import axios from 'axios';
import { deleteAllFilters, deleteTempFilters, resetTempFilters, removeFilter } from '@/utils/FilterReset.js';
import { sortPerfumes, shuffleArray } from '../utils/sort.js';
import { getScentTypeColor } from '@/utils/colorMapping.js';
import { filterPerfumes, previewFilteredPerfumes} from '../utils/Filters.js';
import FavoritesToggle from './FavoritesToggle.vue';


export default {
  data() {
    return {  
      
      totalVisibleRows: 20, // Initial number of items to display
      incrementAmount: 20, // Number of items to add on each scroll to the bottom        
      perfumes: [],
      filteredPerfumes: [],
      brands: [],      
      type: [],
      scentgn: [], 
      search: '',
      preQuery: '', // new property for preQuery
      searchQuery: '', // for searchbar
      
      selectedBrand: [], 
      selectedScentgender: [],
      selectedType: {},
      selectedPrice: [1, 5],
      selectedSillage: [1, 4],
      selectedLongevity: [1, 4],
      tempSelectedScentgender: [], 
      tempSelectedType: {},
      tempSelectedBrand: [],
      
      timeout: null,  
      showDetails: true, // New property to control the visibility of detailed ratings
      allImages: {}, // for pictures
      currentPage: 1, // for pagination
      itemsPerPage: 12,
      isDrawerOpen: false, //for sliding filter window
      isInfoDrawerOpen: false, //for sliding filter window
      scrollTop: 0,
      currentSort: '', // current sort parameter
      tempSort: '',
      showBrandOptions: false,
      showBrands: false,
      lastTap: 0,
      lastTappedOption: null, 
      maxExcluded: 8,
      currentLetter: 'A', // Initial letter
      scrollPosition: 0,
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
      scrollY: 0,
      lastScrollY: 0, 
      chipsKey: 0,
      
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],

      buttonBottom: '95px', // Default bottom position for the button

      showOfflineWarning: false,


      tempSelectedPrice: [1, 5],
      priceCategories: ['very low','low', 'medium', 'high', 'very high'],
      priceMarks: 
        { 1: '$',
        2: '$$',
        3: '$$$',
        4: '$$$$',
        5: 'Luxury'},

      tempSelectedLongevity: [1, 4],  // Default range values for longevity
      longevityCategories: ['short', 'medium', 'long', 'very long'],
      longevityMarks: {            
            1: 'Short',
            2: 'Medium',
            3: 'Long',
            4: 'Very Long'
          },

      tempSelectedSillage: [1, 4],    // Default range values for sillage
      sillageCategories: ['low', 'medium', 'strong', 'very strong'],
      sillageMarks: {
            1: 'Silent',
            2: 'Medium',
            3: 'Loud',
            4: 'Very Loud'
          },
          brandMenu: {
            visible: false,
            brand: '',
            x: 0,
            y: 0,
          },
          typeMenu: {
            visible: false,
            type: '',
            x: 0,
            y: 0,
          },
          noteMenu: {
            visible: false,
            note: '',
            x: 0,
            y: 0,
          },
          


    };
  },


  components: {
    FavoritesToggle,
  },  

  
  computed: {
            
    activeFilterCount() {
    let count = 0;

    // Check if the longevity slider is set to its full range
    if (this.selectedLongevity.length > 0 && !(this.selectedLongevity[0] === 1 && this.selectedLongevity[1] === this.longevityCategories.length)) {
      count++;
    }

    // Check if the sillage slider is set to its full range
    if (this.selectedSillage.length > 0 && !(this.selectedSillage[0] === 1 && this.selectedSillage[1] === this.sillageCategories.length)) {
      count++;
    }

    // Check if the price slider is set to its full range
    if (this.selectedPrice.length > 0 && !(this.selectedPrice[0] === 1 && this.selectedPrice[1] === this.priceCategories.length)) {
      count++;
    }

    // Count other selected filters
    count += this.selectedScentgender.length;
    count += this.selectedBrand.length;
    count += Object.keys(this.selectedType).length;

    return count;
  },

  BrandactiveFilterCount() {
    return this.tempSelectedBrand ? this.tempSelectedBrand.length : 0;
  },

  //for Pagination
  paginatedItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredPerfumes.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.filteredPerfumes.length / this.itemsPerPage);
  },

//Sorting for brands filter
sortedBrands() {
    // First, sort the brands alphabetically and then return.
    return this.brands.slice().sort((a, b) => a.localeCompare(b));
  },
  firstRowBrands() {
    // Return brands for the first row (1st, 3rd, 5th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 === 0);
  },
  secondRowBrands() {
    // Return brands for the second row (2nd, 4th, 6th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 !== 0);
  },

  
  previewFilteredPerfumes,
  

  },

  watch: {
  preQuery(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.triggerSearch(); // Automatically trigger the search as the user types
    }
  },
 
  currentPage(newValue, oldValue) {
    if (newValue !== oldValue) {
       console.log('TopJump by Favorites currentPage'); // Add console log
      window.scrollTo(0, 0); // Scrolls to the top of the page
      this.chipsKey += 1;
    }
  },

},


  created() {
    this.allImages = this.importAllImages(); //Images
    this.fetchPerfumes().then(() => {
    this.sortPerfumes(); // Initial random sorting
  });
  },  
     
  mounted() {
    window.addEventListener('scroll', this.handleScroll);    
    this.filterPerfumes(false); // Apply the filters based on the URL
    this.sortPerfumes(); // Sort the perfumes based on the filters
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  
  methods: {
  
    async fetchPerfumes() {
    try {
      // Check if there's already a shuffled dataset in local storage
      const storedPerfumes = localStorage.getItem('shuffledPerfumes');
      
      if (storedPerfumes) {
        // If it exists, use it and filter for favorites

        this.perfumes = JSON.parse(storedPerfumes).filter(perfume => this.favorites.includes(perfume.image_name));

      } else {
        // Otherwise, fetch the data and shuffle it
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes`);
        
        const allPerfumes = response.data.map((perfume, index) => ({
          ...perfume,
          id: `perfume-${index}` // Ensure unique ID for each perfume
        }));
        
        // Shuffle the dataset and filter for favorites

        this.perfumes = this.shuffleArray(allPerfumes).filter(perfume => this.favorites.includes(perfume.image_name));
        
        // Store the shuffled dataset in local storage
        localStorage.setItem('shuffledPerfumes', JSON.stringify(allPerfumes));
      }
      
      this.filteredPerfumes = this.perfumes.slice(0, this.totalVisibleRows);
      this.brands = [...new Set(this.perfumes.map(perfume => perfume.brand))].sort();
      this.scentgn = [...new Set(this.perfumes.map(perfume => perfume.scentgn))].sort();
      this.longevity = ['short', 'medium', 'long', 'very long'];
      this.sillage = ['low', 'medium', 'strong', 'very strong'];
      this.price = ['low', 'medium', 'high', 'very high'];
      this.type = ['Fresh', 'Flowery', 'Sweet', 'Fruity', 'Citric', 'Woody', 'Creamy', 'Aromatic', 'Powdery', 'Green', 'Aquatic', 
                  'Synthetic', 'Gourmand', 'Leathery', 'Mineral', 'Oriental', 'Smoky', 'Animalic'];
      // Apply any initial filters
      this.filterPerfumes();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  
  // Show hide brands
  toggleBrandsVisibility() {
    this.showBrands = !this.showBrands;
  },

  scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
   console.log('TopJump by Favorites scrollToTop'); // Add console log
  },

  focusSearch() {
    this.$refs.searchInput.focus(); // Focus the input field when tab on magnify
  },
  closeKeyboard() {
    // Blur the input field to close the keyboard on mobile devices
    if (this.$refs.searchInput) {
      this.$refs.searchInput.blur();
    }
  },

  handleScroll() {
  const currentScrollY = window.scrollY;

  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '95px'; // Set the button to 45px when scrolled more than 600px
  }

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;  
},



    buttonAction() {
    if (this.scrollY > 450) {
      this.scrollToTop();
    } else {
      this.showDetails = !this.showDetails;
    }
  },

  toggleDetails() {
    this.showDetails = !this.showDetails;
  },

    mouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
      e.currentTarget.style.cursor = 'grabbing'; // Change cursor to grabbing
      document.body.style.userSelect = 'none'; // Prevent text selection
    },
    mouseMove(e) {
      if (!this.isMouseDown) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    mouseUp(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    mouseLeave(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    touchStart(e) {
      this.isMouseDown = true;
      this.startX = e.touches[0].pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    touchMove(e) {
      if (!this.isMouseDown) return;
      const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    touchEnd() {
      this.isMouseDown = false;
    },

    noteDescription(note) {
  if (note.includes(' ')) {
    // Split the note into words and join them with " AND "
    const words = note.split(' ');
    return `all perfumes that contain ${words.join(' and ')}`;
  } else {
    // For single-word notes, return the default description
    return `all perfumes that contain ${note}`;
  }
},

sendEmail() {
    const email = 'mail@perfumeprophet.com';
    window.location.href = `mailto:${email}`;
  },

  addToSearchQuery(term) {
    if (this.preQuery) {
      this.preQuery += ` ${term}`;
    } else {
      this.preQuery = term;
    }

    if (this.searchQuery) {
      this.searchQuery += ` ${term}`;
    } else {
      this.searchQuery = term;
    }

    this.filterPerfumes(); 
  },

  triggerSearch() {
    this.searchQuery = this.preQuery; // Set the actual search query based on preQuery
   
    this.filterPerfumes();
  },
  
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },


  ratingBarStyle(perfume, ratingValue) {
    const endColor = perfume.dom_color || 'black';
    const widthPercentage = (ratingValue / 5) * 100;
    const style = {
      background: `linear-gradient(to right, #eeeeee, ${endColor})`,
      width: `${widthPercentage}%`,
      height: '100%',
      borderRadius: '2px',
    };

    return style;
  },

  generateBarContainerStyle() {
    return {
      backgroundColor: 'white',
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden', // Ensures the inner bar respects the border radius
    };
  },

    valueRating(perfume) {
      return perfume.someValue; // Replace with your logic
    },
  

 // Removes certain filters when pressing 'Remove Filters' buttons when no results
    removeFilter(filterType) {
      removeFilter(filterType, this);
    },

  toggleBodyScroll(isOpen) {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  },

toggleFavorite(perfumeId) {
      if (this.isFavorite(perfumeId)) {
        this.favorites = this.favorites.filter(id => id !== perfumeId);
      } else {
        this.favorites.push(perfumeId);
      }
      localStorage.setItem('favorites', JSON.stringify(this.favorites));
    },
    isFavorite(perfumeId) {
      return this.favorites.includes(perfumeId);
    },

  //Filter Reset Button & Sort when exit the filtermenu without apply
  resetTempFilters() {
    resetTempFilters(this);
  },

//Filter Reset Button & Sort (for Delete all Button in Filtermenu)
  DeleteTempFilters() {
    deleteTempFilters(this);
  },

  //Click Logo to start new search
  DeleteAllFilters() {
      deleteAllFilters(this, false);
      this.filterPerfumes();
    },

// Sorting
sortPerfumes() {
      this.filteredPerfumes = sortPerfumes(
        this.filteredPerfumes, 
        this.currentSort, 
        this.priceToNumber, 
        this.searchQuery
      );
    },
    
    shuffleArray(array) {
      return shuffleArray(array);
    },

  toggleScentgender(option) {
    const index = this.tempSelectedScentgender.indexOf(option);
    if (index > -1) {
      this.tempSelectedScentgender.splice(index, 1);
    } else {
      this.tempSelectedScentgender.push(option);
    }
  },

  toggleType(option) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - this.lastTap;
      const doubleTapThreshold = 210; // Adjusted threshold for double-tap detection

      // Count currently excluded items
      const excludedCount = Object.values(this.tempSelectedType).filter(state => state === 'excluded').length;

      if (this.lastTappedOption === option && tapLength < doubleTapThreshold && tapLength > 0) {
        // Double-tap detected on the same item
        if (this.tempSelectedType[option] === 'excluded') {
          this.$delete(this.tempSelectedType, option); // Bring it back to normal state
        } else {
          if (excludedCount < this.maxExcluded) {
            this.$set(this.tempSelectedType, option, 'excluded');
          } else {
            // Optional: Provide feedback to the user that they can't exclude more items
            
          }
        }
        this.lastTappedOption = null; // Reset last tapped option
      } else {
        // Single tap detected or different item tapped
        const currentState = this.tempSelectedType[option];
        const selectedCount = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'selected').length;

        if (currentState === 'selected') {
          this.$delete(this.tempSelectedType, option);
        } else if (currentState === 'excluded') {
          this.$delete(this.tempSelectedType, option);
        } else {
          // Only add a new selected if fewer than 15 are already selected
          if (selectedCount < 8) {
            this.$set(this.tempSelectedType, option, 'selected');
          }
        }
      }

      this.lastTap = currentTime; // Update last tap time
      this.lastTappedOption = option; // Update last tapped option
    },
  

toggleBrand(option) {
    const index = this.tempSelectedBrand.indexOf(option);
    if (index > -1) {
      this.tempSelectedBrand.splice(index, 1);
    } else {
      this.tempSelectedBrand.push(option);
    }
  },

  selectTempSort(option) {
    this.tempSort = this.tempSort === option ? '' : option;  
  },
  
  applyFilters() {
    this.selectedScentgender = [...this.tempSelectedScentgender];
    this.selectedLongevity = [...this.tempSelectedLongevity];
    this.selectedSillage= [...this.tempSelectedSillage];
    this.selectedType = { ...this.tempSelectedType };
    this.selectedPrice = [...this.tempSelectedPrice];
    this.selectedBrand = this.tempSelectedBrand;
    this.currentSort = this.tempSort;

    // Update the URL with the selected filters
    this.updateUrlWithFilters();

    this.sortPerfumes();
    this.filterPerfumes(); // Call the filter method 
    this.toggleDrawer(); // Close the drawer
  },
  

  

////APPLY FILTERS AND SEARCH /////
  filterPerfumes,


  // Reset pagination, scroll to top and reset horizontal chips slider
  resetPaginationAndScroll() {
        this.currentPage = 1;
        this.chipsKey += 1; // Update chipsKey to reset scroll position       
    },    

  // convert categories into numerics
  sillageRating(sillage) {
    const ratingValues = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'strong': 4,
      'very strong': 5
    };
    return ratingValues[sillage.toLowerCase()] || 0; // Default to 0 if not found
  },

  longevityRating(longevity) {
    const ratingValues = {
      'very short': 1,
      'short': 2,
      'medium': 3,
      'long': 4,
      'very long': 5
    };
    return ratingValues[longevity.toLowerCase()] || 0; // Default to 0 if not found
  }, 
 
  priceToNumber(price) {
    const priceMap = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'high': 4,
      'very high': 5
    };
    return priceMap[price.toLowerCase()] || 0;
  },

getScentTypeColor,

GoLeft() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft -= 150;
  }
},
GoRight() {
  const container = this.$refs.chipsContainer;
  if (container) {
    container.scrollLeft += 150;
  }
},


goToDetails(image_name, id, name, brand, concentration, scentgn, url_parameter) {
  
     if (!this.isOnline) {
    this.showOfflineWarning = true; // Show the warning
    return; // Prevent navigation
  }
  
  
  const description = this.formatForURL(`${name} ${concentration}`);
  const brand_clean = this.formatForURL(`${brand}`);
    this.$router.push({ name: 'PerfumeDetails', params: { image_name: image_name, id: id, brand:brand_clean, description: description, scentgn:scentgn, url_parameter: url_parameter} });
    },

    formatForURL(text) {
  return text.toString().toLowerCase()
    .normalize('NFD') 
    .replace(/\s+/g, '-')        // Replace spaces with hyphens
    .replace(/[^a-z0-9-]+/g, '') // Remove all non-word characters except hyphens without escaping
    .replace(/-{2,}/g, '-')      // Replace multiple hyphens with a single hyphen without escaping
    .replace(/^-+|-+$/g, '');    // Trim hyphens from the start and end without escaping
},


 openBrandDropdown(event, brand) {
      this.brandMenu.visible = true;
      this.brandMenu.brand = brand;
      this.brandMenu.x = event.clientX; // Positioning for larger screens if needed
      this.brandMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeBrandDropdown);
    },
    navigateToBrand(brand) {
      console.log("Brand for Route selected:", brand);  // Log the brand to the console
      this.$router.push({ path: '/perfumes', query: { items: 12, brand: brand } });
      this.closeBrandDropdown(); // Close the dropdown after navigation
    },
    closeBrandDropdown() {
      this.brandMenu.visible = false; // Close the dropdown
    },


    openTypeDropdown(event, type) {
      this.typeMenu.visible = true;
      this.typeMenu.type = type;
      this.typeMenu.x = event.clientX; // Positioning for larger screens if needed
      this.typeMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeTypeDropdown);
    },
    navigateToType(type) {
      this.$router.push({ path: '/perfumes', query: { items: 12, type: type } });
      this.closeTypeDropdown(); // Close the dropdown after navigation
    },
    closeTypeDropdown() {
      this.typeMenu.visible = false; // Close the dropdown
    },


     openNoteDropdown(event, note) {
      this.noteMenu.visible = true;
      this.noteMenu.note = note;
      this.noteMenu.x = event.clientX; // Positioning for larger screens if needed
      this.noteMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeNoteDropdown);
    },
    navigateToNote(note) {
      this.$router.push({ path: '/perfumes', query: { items: 12, search: note } });
      this.closeNoteDropdown(); // Close the dropdown after navigation
    },
    closeNoteDropdown() {
      this.noteMenu.visible = false; // Close the dropdown
    },

        generateAmazonLink(brand, name, concentration) {
    const combinedName = [brand, name, concentration] // Combine brand, name, and concentration
    .filter(Boolean) // Remove any undefined or empty values
    .join(' '); // Join them with a space
    const normalizedName = combinedName

      .normalize('NFD') // Normalize accents
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/\s+/g, ' ') // Replace multiple spaces with one
      .trim() // Trim whitespace
      .toLowerCase() // Lowercase all letters
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize the first letter of each word

    return `https://www.amazon.com/s?k=${encodeURIComponent(normalizedName)}&tag=perfumeprophe-20`;
  },

    

    
  },
};

</script>



<style scoped>

/* Pagination */

.custom-pagination >>> .v-pagination__item {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the color as needed */
}

.custom-pagination >>> .v-pagination__item--active {
  background-color: #000 !important; /* Active item background color */
  color: #ffffff; /* Active item text color */
  border-radius: var(--radius-basic) !important
}

.custom-pagination >>> .v-pagination__item--disabled {
  color: #b0b0b0; /* Adjust the disabled item color as needed */
}

.custom-pagination >>> .v-pagination__more {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the more button color as needed */
}

.custom-pagination >>> .v-pagination__navigation {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the arrow color as needed */
}

.custom-pagination >>> .v-pagination__navigation--disabled {
  color: #b0b0b0; /* Adjust the disabled arrow color as needed */
}



</style>
