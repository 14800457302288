import { render, staticRenderFns } from "./ImageAdmin.vue?vue&type=template&id=5e3638c3&scoped=true"
import script from "./ImageAdmin.vue?vue&type=script&lang=js"
export * from "./ImageAdmin.vue?vue&type=script&lang=js"
import style0 from "./ImageAdmin.vue?vue&type=style&index=0&id=5e3638c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3638c3",
  null
  
)

export default component.exports