var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({ backgroundColor: _vm.$vuetify.theme.currentTheme.background })},[_c('v-main',[_c('v-container',{class:_vm.isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'},[_c('v-row',{class:_vm.isMobile ? 'px-2 pt-5' : 'px-0 pt-5'},_vm._l((_vm.displayedCards),function(item,index){return _c('v-col',{key:index,class:_vm.isMobile ? 'd-flex pa-2 pb-2' : 'd-flex pa-0 pb-5 image-inspiration',attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"custom-card",attrs:{"title":"","flat":""},on:{"click":function($event){return _vm.navigateToPerfumes(item.note1, item.note2, item.type1, item.type2, item.gender, item.brand, 
                                      item.intensity, item.longevity, item.price)}}},[_c('v-lazy',{attrs:{"height":360,"options":{ rootMargin: '1700px 0px', threshold: 0.01 },"transition":"fade-transition"}},[_c('v-img',{class:_vm.isMobile ? '' : 'darken-image',staticStyle:{"border-radius":"var(--radius-basic)"},attrs:{"src":_vm.getImage(item.image),"height":"360px","eager":""}})],1),_c('v-card-title',{staticClass:"custom-card-title px-0 py-0"},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-btn',{staticClass:"transparent-btn",style:({
                bottom: _vm.isIOSApp() ? 'calc(' + _vm.buttonBottom + ' + 17px)' : _vm.buttonBottom,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                right: '20px',
                display: _vm.scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
                }),attrs:{"fab":"","fixed":"","right":"","ripple":false},on:{"click":_vm.scrollToTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }