export function sortPerfumes(perfumes, currentSort, priceToNumber, searchQuery) {
  // Helper function to convert hex to RGB
  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }

  // Helper function to convert RGB to HSL
  function rgbToHsl(r, g, b) {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    return [h * 360, s * 100, l * 100]; // Convert to degrees on the color circle
  }

  // Define a color categorization function
  function categorizeColor(h, s, l) {
    if (l > 90) return 'white';   // High lightness: white
    if (l < 10) return 'black';   // Low lightness: black
    if (s < 10) return 'gray';    // Low saturation: gray
    return 'color';               // Everything else: color
  }

  if (currentSort) {
    console.log('Sorting based on user selection');  // Debugging line
    switch (currentSort) {
      case 'name':
        perfumes.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'brand':
        perfumes.sort((a, b) => a.brand.localeCompare(b.brand));
        break;
      case 'price':
        perfumes.sort((a, b) => priceToNumber(a.price) - priceToNumber(b.price));
        break;
      case 'color':
        perfumes.sort((a, b) => {
          let [h1, s1, l1] = rgbToHsl(...hexToRgb(a.dom_color));
          let [h2, s2, l2] = rgbToHsl(...hexToRgb(b.dom_color));
          let category1 = categorizeColor(h1, s1, l1);
          let category2 = categorizeColor(h2, s2, l2);
          if (category1 !== category2) {
            return category1.localeCompare(category2); // Sort by color category
          }
          // Within the same category, sort by hue, then saturation, then lightness
          return h1 - h2 || s1 - s2 || l1 - l2; 
        });
        break;
      default:
        break;
    }
  } else if (searchQuery && searchQuery.trim()) {
    perfumes.sort((a, b) => b.score - a.score);
  }
  return perfumes;
}

  
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  