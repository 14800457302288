<template>
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main>
        <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">
          
          <!-- Search Bar -->
          <v-row :class="isMobile ? 'px-1 pt-6 pl-3' : 'pb-3 pt-5'" style="background-color: none;">
            <v-col cols="12">
              <v-text-field
                v-model="searchQuery"
                dense solo flat clearable hide-details
                background-color="#efefef"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search notes..."
                :class="isMobile ? 'search-bar-mobile' : 'search-bar'"
                style="border-radius: var(--radius-search);"
                enterkeyhint="search"
                ref="searchInput"
                @keydown.enter="closeKeyboard"
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row :class="isMobile ? 'px-2' : 'px-0'">
            <v-col
              v-for="(note, index) in filteredNotes"
              :key="index"
              cols="12" sm="6" md="4" lg="3"
              :class="isMobile ? 'd-flex pa-2 pb-1' : 'd-flex pa-0 pb-4 image-inspiration'"
            >
              <v-card 
                @click="navigateToPerfumes(note)"
                class="custom-card"
                flat
                tile
                style="border-radius: var(--radius-basic);"
              >

              <v-lazy
                  :height="360"  
                  :options="{ rootMargin: '2000px 0px', threshold: 0.01 }"  
                  transition="fade-transition"
                >
                <v-img
                  :src="getImage(note)"
                  
                  class="square-image darken-image"
                  :class="isMobile ? 'square-image' : 'square-image darken-image'"
                  eager
                ></v-img>
                </v-lazy>

                <v-card-title class="custom-card-title px-0 py-0">{{ formatTitle(note) }}</v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-btn fab fixed right :ripple="false"  
              :style="{
                  bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 17px)' : buttonBottom,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  right: '20px',
                  display: scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
                  }"
              @click=scrollToTop class="transparent-btn">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>

        </v-container>
      </v-main>
    </v-app>
  </template>

  
<script>

export default {
  name: 'ExploreNotes',
  data() {
    return {
      notes: [],
      imageMap: {},
      searchQuery: sessionStorage.getItem('lastSearchQuery') || this.$route.query.search || '',
      shuffledNotes: [],
      buttonBottom: '95px', // Default bottom position for the button
      scrollY: 0,
      lastScrollY: 0, 
    };
  },
  computed: {
    filteredNotes() {
      if (!this.searchQuery) return this.shuffledNotes;
      return this.shuffledNotes.filter(note => this.formatTitle(note).toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
  },
  methods: {
    loadImages() {
      const context = require.context('@/assets/notes', false, /\.webp$/);
      const allNotes = {};
      
      context.keys().forEach((key) => {
        const baseName = key.replace('./', '').replace('.webp', '').replace(/\d+$/, '');
        if (!allNotes[baseName]) {
          allNotes[baseName] = [];
        }
        allNotes[baseName].push(key.replace('./', '').replace('.webp', ''));
      });
      
      // Select one image per note randomly but consistently each day
      const day = new Date().getDate();
      this.notes = Object.keys(allNotes).map(baseName => {
        const options = allNotes[baseName];
        return options[day % options.length];
      });
      
      this.notes.forEach((note) => {
        this.imageMap[note] = context(`./${note}.webp`);
      });

      // Shuffle notes once per day to avoid fixed alphabetical order
      this.shuffledNotes = this.shuffleWithSeed(this.notes, day);
    },
    getImage(note) {
      return this.imageMap[note] || '';
    },
    formatTitle(note) {
      return note.replace(/\d+/g, '').replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()).trim();
    },
    navigateToPerfumes(note) {
  const formattedNote = this.formatTitle(note);
  this.$router.push({ 
    path: '/perfumes', 
    query: { 
      items: 12,
      search: formattedNote,
      fromTile: 'true' // Ensure it's passed as a string
    } 
  });
},

    shuffleWithSeed(array, seed) {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(this.seededRandom(seed + i) * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    },


    seededRandom(seed) {
    let x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  },

    closeKeyboard() {
    // Blur the input field to close the keyboard on mobile devices
    if (this.$refs.searchInput) {
      this.$refs.searchInput.blur();
    }
  },
  handleScroll() {
  const currentScrollY = window.scrollY;

  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '95px'; // Set the button to 45px when scrolled more than 600px
  } 

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;  
  },

  updateSearchQuery(newQuery) {
    const currentSearch = this.$route.query.search || '';
    if (newQuery !== currentSearch) {
      this.$router.replace({
        query: { ...this.$route.query, search: newQuery || undefined }
      });
    }
    sessionStorage.setItem('lastSearchQuery', newQuery || '');
  },

  },

  watch: {
  searchQuery: {
    handler(newQuery, oldQuery) {
      // If the change comes from user input (not initial load), debounce it
      if (oldQuery !== undefined) { 
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.updateSearchQuery(newQuery);
        }, 250);
      } else {
        // First-time load from URL (oldQuery is undefined), update instantly
        this.updateSearchQuery(newQuery);
      }
    },
    immediate: true // Ensures it runs immediately when the component is loaded
  }
},



  mounted() {
    window.addEventListener('scroll', this.handleScroll);  
    this.loadImages();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },



};
</script>


  <style>
  .square-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: var(--radius-basic) !important;
  }
  
  .search-bar {
    max-width: 290px;
    margin: 0px -4px !important;
  }
  .search-bar-mobile {
    max-width: 290px;
    margin: 0 0;  
  }
  </style>
  