<template>
    <div>
      <v-btn
        icon
        @click="handleFavoriteToggle"

      >
        <div class="icon-wrapper">
          <v-icon :color="isFavorite ? 'red' : '#dcdcdc'">
            {{ isFavorite ? 'mdi-heart' : 'mdi-heart-outline' }}
          </v-icon>
        </div>
      </v-btn>
  
      <div
        v-if="feedbackMessage"
        :class="[feedbackType === 'added' ? 'favorite-added' : 'favorite-removed']"
        class="feedback-box"
        :style="`position: absolute; top: ${top + 128}px; left: ${left + 50}px;`"
      >
        {{ feedbackMessage }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentRoute: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isFavorite: false,
        feedbackMessage: '',
        feedbackType: '',
        top: 0,
        left: 0
      };
    },

    watch: {
  currentRoute: {
    immediate: true,
    handler(newRoute) {
      console.log('[Heart Component] Detected new route:', newRoute);
      this.checkIfFavorite();
    }
  }
},


    methods: {
      handleFavoriteToggle() {
    this.isFavorite = !this.isFavorite;
    this.updateFavoriteStorage();
  
    if (this.isFavorite) {
      console.log(`[Heart Component] Added route ${this.currentRoute} to storage`);
      this.showFeedback("Added search combo to your Favorites!", "added");
    } else {
      console.log(`[Heart Component] Removed route ${this.currentRoute} from storage`);
      this.showFeedback("Removed search combo from your Favorites", "removed");
    }
  },

  updateFavoriteStorage() {
    let savedSearches = JSON.parse(localStorage.getItem("favoriteSearches")) || [];

    // Parse current route and normalize it
    const url = new URL(window.location.origin + this.currentRoute);
    let queryParams = Object.fromEntries(url.searchParams.entries());

    // Ensure `items=12` is always included
    queryParams.items = queryParams.items || '12';

    // Convert queryParams into sorted object where `items` comes first
    const sortedQueryParams = Object.fromEntries(
      Object.entries(queryParams).sort(([keyA], [keyB]) => 
        keyA === "items" ? -1 : keyB === "items" ? 1 : keyA.localeCompare(keyB)
      )
    );

    // Construct normalized URL
    const normalizedRoute = `/perfumes?${new URLSearchParams(sortedQueryParams).toString()}`;

    // Save or remove from storage
    if (this.isFavorite) {
      if (!savedSearches.includes(normalizedRoute)) {
        savedSearches.push(normalizedRoute);
        localStorage.setItem(`timestamp_${normalizedRoute}`, Date.now().toString());
      }
    } else {
      savedSearches = savedSearches.filter(url => url !== normalizedRoute);
      localStorage.removeItem(`timestamp_${normalizedRoute}`);
    }

    localStorage.setItem("favoriteSearches", JSON.stringify(savedSearches));
    this.isFavorite = savedSearches.includes(normalizedRoute);

    console.log("[Heart Component] Saved searches:", savedSearches);
},



checkIfFavorite() {
    let savedSearches = JSON.parse(localStorage.getItem("favoriteSearches")) || [];

    // Normalize current route like in updateFavoriteStorage
    const url = new URL(window.location.origin + this.$route.fullPath);
    let queryParams = Object.fromEntries(url.searchParams.entries());

    // Ensure `items=12` is always included
    queryParams.items = queryParams.items || '12';

    // Sort query parameters so `items` comes first
    const sortedQueryParams = Object.fromEntries(
      Object.entries(queryParams).sort(([keyA], [keyB]) => 
        keyA === "items" ? -1 : keyB === "items" ? 1 : keyA.localeCompare(keyB)
      )
    );

    // Construct normalized URL
    const normalizedRoute = `/perfumes?${new URLSearchParams(sortedQueryParams).toString()}`;

    // Check if the normalized route is already saved
    this.isFavorite = savedSearches.includes(normalizedRoute);

    console.log("[Heart Component] Checking favorite status for:", normalizedRoute);
    console.log("[Heart Component] Current saved searches:", savedSearches);
},


  
      showFeedback(message, type) {
        this.feedbackMessage = message;
        this.feedbackType = type;
  
        setTimeout(() => {
          this.feedbackMessage = "";
          this.feedbackType = "";
        }, 1000);
      }
    }
  };
  </script>
  

  <style scoped>
.feedback-box {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 5px 0;
  background-color: #222222;
  border-radius: var(--radius-basic);
  color: white;
  font-size: 14px;
  opacity: 0;
  z-index: 1000;
  animation: fade-in-out 1.2s ease-in-out forwards;
}

.favorite-added {
  color: white;
}

.favorite-removed {
  color: white;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
}
</style>