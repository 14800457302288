<template>


<v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
  <v-main>


        <!-- Offline Warning (only when triggered by click) -->
    <div v-if="showOfflineWarning" class="offline-overlay" @click="showOfflineWarning = false">
      <v-card class="offline-alert" @click.stop>
        <v-icon class="offline-icon">mdi-wifi-off</v-icon>
        <span>You are offline. Please check your internet connection.</span>
      </v-card>
    </div>


    <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">

        <v-col cols="12" class="text-left">
              <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
              <h1 class="app-title">
                <span class="underline">Perfume</span> Prophet.
              </h1>
            </v-col>   

    <div :class="!isMobile ? 'content-container' : ''">

        <div class="submission-container">
    <!-- TITLE -->
    <h1 class="submission-title">
      Showcase Your Best Perfume Shots!
    </h1>

    <!-- SUBTITLE -->
    <p class="submission-subtitle">
      Contribute to Perfume Prophet and share your stunning fragrance photography.
    </p>

    <!-- KEY HIGHLIGHTS -->


    <ul class="submission-list">
        <li>
            <i class="mdi mdi-star-outline"></i>
            <span>The best shots will be integrated into the app for all our users to discover.</span>
        </li>
        <li>
            <i class="mdi mdi-camera-outline"></i>
            <span>We will link your Instagram profile — for you to gain new fragrance lovers as followers!</span>
        </li>
        <li>
            <i class="mdi mdi-crown-outline"></i>
            <span>Be creative and get recognized as a top perfume photographer.</span>
        </li>
    </ul>


    <!-- CALL-TO-ACTION -->
    <p class="submission-cta">
      Whether you’re a collector, a photographer, or simply love sharing your passion, we love to see your most aesthetic and creative shots! 
    </p>

<div v-if='isOnline'>
   <div v-if='isMobile' >
    <br>
    
    <div style="height: 1px; width: 95%; background-color: #dadada; margin: 0 auto;"></div>









      <p class="submission-title2">
      Example:
    </p>
  </div>
  </div>
    

  <div v-if='isMobile'>
    <router-link to="/perfume-details/sol-de-janeiro/female/sol-de-janeiro-cheirosa-87-2022">
   <img :src="imageSrc" alt="Instagram Promo" class="upload-image">
   </router-link>
   <br>
  </div>
</div>

<div style="height: 1px; width: 95%; background-color: #dadada; margin: 0 auto;"></div>
<br>


    <p class="submission-title2 ml-3 mr-3">
      Upload Images:
    </p>
        <br>
        <br>
        <v-form @submit.prevent="uploadImages">
          <!-- GENERAL INFO SECTION -->
          <v-text-field 
            label="Instagram Profile Link" 
            v-model="instagram" 
            outlined
            prepend-icon="mdi-instagram"
            @focus="ensureInstagramPrefix"
            class = "ml-3 mr-3"
          ></v-text-field>
  
          <v-text-field 
            label="Email Address" 
            v-model="email" 
            outlined
            prepend-icon="mdi-email-outline"
            class = "ml-3 mr-3"
          ></v-text-field>
          
          <!-- IMAGE UPLOAD SECTION -->
          <v-file-input
            label="Select up to 10 Photos"
            accept="image/*"
            multiple
            v-model="imageFiles"
            :prepend-icon="isFileInputFocused ? 'mdi-image-multiple' : 'mdi-image-multiple-outline'"
            outlined
            @change="previewImages"
            @focus="isFileInputFocused = true"
            @blur="isFileInputFocused = false"
            class = "ml-3 mr-3"
          ></v-file-input>

<!-- Show message if camera access is denied -->
<div v-if="cameraPermissionDenied && imagePreviews.length === 0" style="background-color: #efefef; padding: 15px 15px;"  class="bg-red-100 text-red-700 text-sm ml-3 mr-3 p-3 rounded-lg flex items-center justify-between cursor-pointer" @click="openAppSettings">
  <!-- Warning Icon & Message -->
  <p class="flex items-center">
    <v-icon color="black" class="mr-2" size="20">mdi-alert-circle</v-icon>
    You want to use your camera? Please allow access in your iPhone settings.
  </p>

    <!-- Quick Settings Path -->
    <strong class="bg-gray-200 text-gray-800 px-2 py-1 rounded-md font-mono">Settings > Perfume Prophet > Camera</strong>

</div>







  
          <!-- IMAGE PREVIEW GRID -->
          <v-row>
            <v-col 
                v-for="(image, index) in imagePreviews" 
                :key="index" 
                cols="12" sm="4" md="3" 
                class="position-relative"
            >
                <v-btn 
                icon 
                small 
                class="remove-btn"
                @click="removeImage(index)"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-img 
                :src="image.preview" 
                contain 
                aspect-ratio="1"
                class="image-preview"
                ></v-img>

                <br>

                <v-autocomplete
                        v-if="!imagePreviews[index].perfumeName"
                        v-model="imagePreviews[index].perfumeName"
                        :items="filteredPerfumes(imagePreviews[index].searchQuery)"
                        :search-input.sync="imagePreviews[index].searchQuery"
                        label="Which perfume is this?"
                        outlined
                        clearable
                        dense
                        item-value="formattedName"  
                        item-text="formattedName"
                        class="perfume-search"
                        no-data-text="Start typing to find your perfume..."
                        return-object
                        @change="resetIfEmpty(index)"
                        >
                        <template v-slot:append>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                </v-autocomplete>

            <!-- Show Selected Perfume with 'X' Button -->
                <div v-if="imagePreviews[index].perfumeName" class="selected-perfume-text">
                {{ imagePreviews[index].perfumeName.formattedName }}
                <v-btn icon small class="ml-2" @click="clearPerfumeSelection(index)">
                    <v-icon size="15" color="darkgrey">mdi-close</v-icon>
                </v-btn>

                </div>


                <br>

            </v-col>
        </v-row>

        <br>
        

        <!-- Terms & Conditions Checkbox & Dialog -->
<v-checkbox
  v-model="termsAccepted"
  class="mt-4 ml-3"
  :rules="[v => !!v || 'You must accept the Terms & Conditions to proceed.']"
>
  <template v-slot:label>
    <span>
      I have read and agree to the
      <a @click.prevent="showTermsDialog = true" class="terms-link underline curser-pointer">Terms & Conditions</a>.
    </span>
  </template>
</v-checkbox>

<!-- Terms & Conditions Dialog -->
<v-dialog v-model="showTermsDialog" max-width="600px" content-class="terms-dialog">
  <v-card>
    <!-- Fixed Header with Close Button -->
    <v-card-title class="dialog-header">
      <v-spacer></v-spacer>
      <v-btn icon class="close-icon" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Scrollable Content -->
    <v-card-text class="dialog-content">
      <TermsDialog />
    </v-card-text>

    <!-- Footer with Accept Button -->
    <v-card-actions class="dialog-footer">
      <v-btn color="primary" @click="acceptTerms">I Agree</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<br>
<!-- Submit Button (Disabled Until Terms Are Accepted) -->
<v-btn 
  :disabled="!allPerfumesSelected || !termsAccepted" 
  :loading="loading" 
  color="primary" 
  style="height: 40px; margin-top: 1rem; margin-left: 1rem; border-radius: var(--radius-buttons);"
  type="submit"
>
  <v-icon left>mdi-cloud-upload</v-icon>
  Submit Photos
</v-btn>




        </v-form>
        
  
        <v-alert v-if="successMessage" type="success" class="mt-3">
          {{ successMessage }}
        </v-alert>
      </div>
    </v-container>

</v-main>
</v-app>


</template>
  
<script>
  import axios from "axios";
  import TermsDialog from './TermsDialog.vue';
  import { Camera } from "@capacitor/camera";

  import { NativeSettings, IOSSettings } from 'capacitor-native-settings';
  
  
  export default {
     components: {
    TermsDialog
  },
    
    
    data() {
      return {
        instagram: "instagram.com/",
        email: "",
        imageFiles: [],
        imagePreviews: [],
        perfumes:[],
        loading: false,
        successMessage: "",
        imageSrc: require('@/assets/upload-page/instagram-promo.png'), // Ensure the path is correct
        showOfflineWarning: false,
        termsAccepted: false,  // User must check this to submit
        showTermsDialog: false, // Controls the Terms & Conditions modal
        cameraPermissionDenied: false,
        showDetails: false,
        isFileInputFocused: false
        
      };
    },

      
    
  async mounted() {
    await this.fetchPerfumes();
    await this.checkCameraPermissionOnMount();

    this.$nextTick(() => {
     console.log('TopJump by mounted uploadimages'); // Add console log
    window.scrollTo(0, 0);
    });

    },


    
    
    methods: {

        async fetchPerfumes() {
    try {
      const storedPerfumes = localStorage.getItem('shuffledPerfumes');
      if (storedPerfumes) {
        this.perfumes = JSON.parse(storedPerfumes);
      } else {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes`);
        this.perfumes = response.data.map((perfume, index) => ({
          ...perfume,
          id: `perfume-${index}`, // Ensure unique ID
          image_name:perfume.image_name
        }));
      }
    } catch (error) {
      console.error("Error fetching perfumes:", error);
    }
    },


      ensureInstagramPrefix() {
        if (!this.instagram.startsWith("instagram.com/")) {
          this.instagram = "instagram.com/";
        }
      },
      
      previewImages() {
  if (!this.imageFiles) return;

  const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp" ]; // Allowed formats
  const maxSizeMB = 15 * 1024 * 1024; // 15MB in bytes

  this.imageFiles.slice(0, 10 - this.imagePreviews.length).forEach((file) => {
    if (!allowedTypes.includes(file.type)) {
        alert(`Invalid file type: ${file.name}. Only JPG, PNG, GIF and WEBP are allowed.`);
        return;
    }

         if (!this.isOnline) {
    this.showOfflineWarning = true; // Show the warning
    return; // Prevent navigation
  }


    if (file.size > maxSizeMB) {
        alert(`File too large: ${file.name}. Max size is 15MB.`);
        return;
    }

    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (aspectRatio !== 1 && aspectRatio > 1) { // Accept only square or portrait
        alert(`Invalid aspect ratio: ${file.name}. Only square or portrait images are allowed.`);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
          // Prevent duplicate uploads
          if (!this.imagePreviews.some(img => img.file.name === file.name)) {
              this.imagePreviews.push({
                  preview: e.target.result,
                  file: file,
                  perfumeName: "",
                  searchQuery: "",
              });
          }
      };
      reader.readAsDataURL(file);
    };
    img.src = URL.createObjectURL(file);
  });
},



        removeImage(index) {
        // Remove the image from imagePreviews
        this.imagePreviews.splice(index, 1);

        // Also remove the corresponding file from imageFiles
        const updatedFiles = [...this.imageFiles];
        updatedFiles.splice(index, 1);
        this.imageFiles = updatedFiles;
        },


    async uploadImages() {
  if (!this.imagePreviews.length) {
    alert("Please select at least one image!");
    return;
  }

     if (!this.isOnline) {
    this.showOfflineWarning = true; // Show the warning
    return; // Prevent navigation
  }


   // Check if every uploaded image has a selected perfume
   if (!this.allPerfumesSelected) {
    alert("Please select a perfume for each image!");
    return;
  }

  this.loading = true;
  const formData = new FormData();
  this.imagePreviews.slice(0, 10).forEach((imageObj) => {
    formData.append("images", imageObj.file);
    formData.append("perfumeNames[]", imageObj.perfumeName);
    formData.append("imageNames[]", imageObj.perfumeName.image_name); // Pass image_name

  // Debugging: Print image_name for each uploaded image

    console.log(`   - File Name: ${imageObj.file.name}`);
    console.log(`   - Perfume Name: ${imageObj.perfumeName.formattedName || "Unknown Perfume"}`);
    console.log(`   - Image Name: ${imageObj.perfumeName.image_name}`);

  });

  
  formData.append("instagram", this.instagram);
  formData.append("email", this.email);

  try {
    const response = await axios.post(`${process.env.VUE_APP_IMG_API_URL}/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    console.log("Uploaded files:", response.data.images); // Debugging

    this.successMessage = response.data.message || "Images uploaded successfully!";
    this.imagePreviews = [];



    this.instagram = "instagram.com/";
    this.email = "";
  } catch (error) {
    alert("Error uploading images.");
    console.error(error);
  }

  this.loading = false;
},


      resetIfEmpty(index) {
    if (!this.imagePreviews[index].perfumeName) {
      this.imagePreviews[index].perfumeName = null;
    }
  },

  updatePerfumeName(index, selectedPerfume) {
    if (selectedPerfume) {
      this.imagePreviews[index].perfumeName = selectedPerfume.formattedName;
    }
  },

  clearPerfumeSelection(index) {
    this.$set(this.imagePreviews, index, {
      ...this.imagePreviews[index],
      perfumeName: null,  // Remove selection
      searchQuery: "",  // Reset search input field
    });
  },


   acceptTerms() {
    this.termsAccepted = true; // Mark checkbox as checked
    this.showTermsDialog = false; // Close the dialog
  },

  closeDialog() {
    this.showTermsDialog = false; // Close the dialog
    },

 // Check camera permission on component mount
 async checkCameraPermissionOnMount() {
      if (!this.isIOSApp()) {
        return; // Do nothing if it's not an iOS app
      }

      const permissionStatus = await Camera.checkPermissions();
      if (permissionStatus.camera === "denied" || permissionStatus.camera !== "granted") {
        this.cameraPermissionDenied = true;
      }
    },

    // Check camera permission before allowing image upload
    async checkCameraPermissionBeforeUpload(event) {
      if (!this.isIOSApp()) {
        return;
      }

      const permissionStatus = await Camera.checkPermissions();

      if (permissionStatus.camera === "denied") {
        event.preventDefault();
        alert("Camera access is denied. Please enable it in settings.");
        this.openAppSettings();
        return;
      }

      if (permissionStatus.camera !== "granted") {
        event.preventDefault();
        const request = await Camera.requestPermissions();
        if (request.camera === "denied") {
          alert("Camera access is required. Please enable it in settings.");
          this.openAppSettings();
          return;
        }
      }
    },


    // Function to open app settings
    async openAppSettings() {
      try {
        await NativeSettings.openIOS({
          option: IOSSettings.App,
        });
      } catch (error) {
        console.error('Error opening app settings:', error);
      }
    }



},


    computed: {
        filteredPerfumes() {
    return (search) => {
      if (!search) return [];

      const normalizeText = (text) =>
        text
          ? text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          : "";

      const normalizedSearch = normalizeText(search);

      return this.perfumes
        .filter(p =>
          normalizeText(p.name).includes(normalizedSearch) ||
          normalizeText(p.brand).includes(normalizedSearch)
        )
        .map(p => ({
          formattedName: `${p.name}${p.concentration ? ' ' + p.concentration : ''} (${p.brand})`,
          name: p.name,
          image_name: p.image_name, // Include image_name
        }));
    };
  },

  allPerfumesSelected() {
    return this.imagePreviews.every(image => image.perfumeName && image.perfumeName.formattedName);
  },


},


  };
  
</script>
  

<style scoped>
  .content-container {
    max-width: 1200px; /* Restrict width for cleaner layout */
    margin: 0 auto; /* Center the content */
  }
  .v-text-field, .v-file-input {
    max-width: 600px; /* Reduce input field width */
    margin: 0 auto; /* Center input fields */
  }

  
  .v-container {
    max-width: 600px;
  }
  .image-preview {
    border-radius: var(--radius-basic);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  }
  .position-relative {
  position: relative;
    }

.remove-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
}

.no-shadow {
  box-shadow: none !important; /* Removes the box shadow */
}

.selected-perfume-text {
  margin-top: 5px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: regular;
}




/* Container Styling */
.submission-container {
  margin: 0 auto;
  padding: 40px 20px;
}

/* Title Styling */
.submission-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 35px;
}

/* Subtitle Styling */
.submission-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.submission-title2 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 0px;
}




.emoji {
  font-size: 1.3rem;
  margin-right: 8px;
}

/* Call-to-Action */
.submission-cta {
  font-size: 1.1rem;
  font-weight: 300;
  margin-top: 25px;
}


.showcase {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.showcase h1 {
    text-align: center;
}

.showcase p {
    text-align: center;
    margin-bottom: 20px;
}

.submission-list {
    list-style: none;
    padding: 0;
}

.submission-list li {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-bottom: 10px; /* Space between items */
}

.submission-list li i {
    font-size: 24px; /* Icon size */
    color: #555; /* Icon color */
    margin-right: 10px; /* Space between icon and text */
}

.submission-list li span {
    flex: 1; /* Allows text to fill the space after the icon */
}

.upload-image {
  width: 90vw;
  max-width: 400px; 
  height: auto; 
  padding: 0 15px;
  border-radius: var(--radius-basic);
  display: block; 
  margin: 0 auto; 
}

.terms-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.terms-dialog {
  background-color: white !important;
}

.dialog-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  height: 52px;
}

.dialog-content {
  max-height: 580px; /* Adjust as needed */
  overflow-y: auto;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  padding: 16px;
}



</style>
  