<template>
  <div v-if="isVisible" class="start-instructions-wrapper">
    
    <!-- Overlay with the circular cutout -->
    <div class="tutorial-overlay" :style="maskStyle"></div>

    <!-- Instructional text that moves with the circle -->
    <div class="tutorial-text" :style="textStyle">
      <!-- Use typedText rather than steps[currentStep].text -->
      {{ typedText }}
    </div>

    <!-- Next button fixed at the bottom center -->
    <button class="next-button" @click="nextStep">
  <span v-if="currentStep < steps.length - 1">
    Next <i class="mdi mdi-chevron-right" style="font-size: 1rem;"></i>
  </span>
  <span v-else>
    Let's go!
  </span>
</button>


<div class="do-not-show">
  <label class="checkbox-container">
    <input type="checkbox" id="doNotShow" v-model="doNotShowAgain" />
    <span class="checkmark"></span>
    Don't show this again.
  </label>
</div>


  </div>
</template>

<script>
export default {
  name: "StartInstructionsTest",
  data() {
    return {
      isVisible: true,
      currentStep: 0,
      doNotShowAgain: false,
      typedText: "", 
      // Updated steps array:
      steps: [
        // Step 1: Welcome (no circle)
        { showCircle: false, text: 'Welcome to\nPerfume Prophet!' },
        // Step 2
        { showCircle: true, x: '210px', y: '240px', text: 'Click on the colorful buttons to get started.', radius: '105px' },
        // Step 3
        { showCircle: true, x: '56px', y: '86px', text: 'Use our magic searchbar to look for specific notes or unique combos.', radius: '50px' },
        // Step 4
        { showCircle: true, x: 'calc(100% - 50px)', y: '86px', text: 'Use the advanced filters to refine your search.', radius: '60px' },
        // Step 5
        { showCircle: true, x: '54px', y: '502px', text: 'Save perfumes to your favorites.', radius: '50px' },
        // Step 6
        { showCircle: true, x: '35px', y: 'calc(100% - 35px)', text: 'Get fresh inspiration every day!', radius: '70px' }
      ],

    // For steps 2–6, set text positions (note: index 0 here corresponds to step 2)
    textPositions: [
      // Step 1: Welcome
      { left: '50%', top: 'calc(20% + 60px)', transform: 'translateX(-50%)', fontSize: '2.2rem', textAlign: 'center' },
      // Step 2
      { left: '50%', top: '355px', transform: 'translateX(-50%)' },
      // Step 3
      { left: '50%', top: '190px', transform: 'translateX(-50%)' },
      // Step 4
      { left: '50%', top: '190px', transform: 'translateX(-50%)' },
      // Step 5
      { left: '50%', top: '360px', transform: 'translateX(-50%)' },
      // Step 6
      { left: '50%', top: '360px', transform: 'translateX(-50%)' }
    ]
    };
  },
  computed: {
    // Compute the CSS mask to cut out a circular area based on the current step's position

  maskStyle() {
  const step = this.steps[this.currentStep];
  if (!step.showCircle) {
    return { background: 'rgba(0,0,0,0.7)' };
  }

  // base X and Y from step
  let { x, y, radius = '100px' } = step;

  // If you want to offset the circle for iOS, do something like:
  if (this.isIOSApp() && this.currentStep !== 5) {
    y = `calc(${y} + 40px)`;
  }

  return {
    '-webkit-mask': `radial-gradient(circle ${radius} at ${x} ${y}, transparent 0%, transparent ${radius}, black calc(${radius} + 1px))`,
    'mask':        `radial-gradient(circle ${radius} at ${x} ${y}, transparent 0%, transparent ${radius}, black calc(${radius} + 1px))`
  };



    },
    


    textStyle() {
  // The base style comes from your textPositions array
  const basePosition = this.textPositions[this.currentStep];

  // Determine the top value
  // If you’re on iOS, add 40px to whatever was in `top`.
  let topVal = basePosition.top;
  if (this.isIOSApp()) {
    // Wrap the existing top in calc(...) + 40px
    // e.g. "calc(190px + 40px)" or "calc(calc(100% - 285px) + 40px)"
    topVal = `calc(${topVal} + 40px)`;
  }

  return {
    position: 'fixed',
    fontSize: '1.15rem',
    fontWeight: '500',
    color: '#fff',
    whiteSpace: 'pre-line',
    zIndex: 10001,
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: '315px',
    padding: '1.1rem',
    borderRadius: 'var(--radius-basic)',
    left: basePosition.left,
    transform: basePosition.transform,
    ...this.textPositions[this.currentStep],
    top: topVal,
  };
}

  },
  watch: {
    // Whenever the step changes, start re-typing the new text
    currentStep() {
      this.startTypingEffect();
    }
  },
  methods: {
    
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      } else {
        // If the user checked the "do not show" box, set a flag in localStorage.
        if (this.doNotShowAgain) {
          localStorage.setItem('doNotShowStartInstructions', 'true');
        }
        // Restore scrolling when closing the overlay
        document.body.style.overflow = '';
        document.documentElement.style.overflow = '';
        document.removeEventListener('touchmove', this.preventScroll);
        this.isVisible = false;
        this.$emit('close-dialog');
      }
    },


    preventScroll(e) {
      e.preventDefault();
    },

    // Start typing text for the current step
    startTypingEffect() {
      clearInterval(this.typingInterval);
      const text = this.steps[this.currentStep].text || "";
      this.typedText = "";
      let index = 0;
      const speed = 45; // Adjust for faster/slower typing

      this.typingInterval = setInterval(() => {
        if (index < text.length) {
          this.typedText += text.charAt(index);
          index++;
        } else {
          clearInterval(this.typingInterval);
        }
      }, speed);
    }
  


  },
mounted() {
  setTimeout(() => {
    // 1. Strongly force the page to scroll up
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: 'instant' });
    
    // 2. Disable scrolling on the main app
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    // Prevent touchmove events (for mobile scrolling)
    document.addEventListener('touchmove', this.preventScroll, { passive: false });
    
    // 3. Start typing effect (or any other logic)
    this.startTypingEffect();
  }, 50); // 50ms or 100ms is usually enough
},

  beforeDestroy() {
    // Restore scrolling when the component is destroyed
    clearInterval(this.typingInterval);
    document.body.style.overflow = '';
    document.documentElement.style.overflow = '';
    document.removeEventListener('touchmove', this.preventScroll);
  }
};
</script>

<style scoped>
.start-instructions-wrapper {
  pointer-events: auto;
}

/* Full-screen overlay with a dark background and circular cutout */
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8) !important;
  pointer-events: auto; /* Block interactions with the underlying app */
  z-index: 10000;
  transition: all 0.5s ease;
  
}

/* Instructional text remains unclickable */
.tutorial-text {
  pointer-events: none;
  
}

/* Next button: the only interactive element */
.next-button {
  pointer-events: auto;
  position: fixed;
  bottom: 100px;
  left: 50%;
  width: 100px;
  transform: translateX(-50%);
  background: #fff;
  color: #000;
  border: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
  border-radius: var(--radius-buttons);
  font-size: 1rem;
  z-index: 10001;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

/* Style for the "Do not show again" checkbox */
.do-not-show {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: grey;
  font-size: 0.9rem;
  z-index: 10001;
  width: 290px;
  text-align: center; /* centers the inline content */
  padding: 10px; /* optional, for extra spacing */
}

.do-not-show label {
  margin-left: 6px;
}

/* Container for the custom checkbox */
.checkbox-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background-color: grey;
}

/* The custom checkmark box */
.checkmark {
  position: relative;
  height: 18px;
  width: 18px;
  margin-right: 8px; /* spacing between box and text */
  background-color: #fff;
  border: 2px solid grey; /* black border */
  border-radius: 4px; /* optional rounding */
  box-sizing: border-box; /* ensure border is included in width/height */
  background-color: grey;
}

/* When the checkbox is checked, add a black background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #000;
  border: 2px solid black; /* black border */
}

/* Create the checkmark/tick using a pseudo-element */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 1px;
  width: 3px;
  height: 9px;
  border: solid #fff; /* white tick */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}
</style>
