<template>

  <div>

    
    <v-btn icon @click="navigateBack" class="floating-button">
          <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    
    <v-container class="header-area">
      <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
            <h1 class="header-title-mobile">
                  <span class="underline">Perfume</span> Prophet.
              </h1>
    </v-container>
   
    <br>


  <div class="image-card" >
    <div class="img-container">
      <!-- Image: Different for Desktop & Mobile -->
      <template v-if="!isMobile">
        <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" 
             :class="{'fade-in': true, 'show': isImageLoaded}"
              alt="Perfume" 
             class="perfume-img"
             @load="markImageAsLoaded">
      </template>
      <template v-else>
        <ImageCarousel3 :imagePrefix="perfume.image_name"></ImageCarousel3>
      </template>

      <!-- Upload Images Button -->
      <v-btn class="upload-button" icon @click="openUploadDropdown">
        <v-icon>mdi-upload</v-icon>
      </v-btn>

      <!-- Upload Dropdown -->
      <div v-if="uploadMenu.visible" 
           :style="{ top: `${uploadMenu.y}px`, left: isMobile ? '25px' : `${uploadMenu.x}px` }"
           class="note-dropdown visible">
        <div class="dropdown-option-alternative" @click="navigateToUpload">
          <v-icon left color="white">mdi-star</v-icon>
          Show us your perfume shots. The best photos will be promoted in the app. Click to learn more!
        </div>
      </div>

      <!-- Overlay for Closing Dropdown -->
      <div v-if="uploadMenu.visible" class="dropdown-overlay" @click="closeUploadDropdown"></div>

      <!-- Favorites Toggle -->
      <FavoritesToggle :perfumeId="perfume.image_name" :top="283" :left="-10" />
    </div>
  </div>



<br>
 

  <div class="title-container">

   <span class="perfume-name no-hover" :style="{ fontSize: perfumeFontSize }"> {{ perfume?.name || 'Unknown Perfume' }}
</span>


    <span class="perfume-brand" :class="{ 'no-hover': isMobile }" :style="{ fontSize: perfumeFontSize }" @click="openBrandDropdown($event, perfume.brand)" >{{ perfume.brand }}</span>
  
  </div>


      <!-- Brand Dropdown -->
    <div v-if="brandMenu.visible" 
         :style="{ top: isMobile ? `${brandMenu.y}px` : `${brandMenu.y}px`, left: isMobile ? '25px' : `${brandMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToBrand(brandMenu.brand)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Show me all {{ brandMenu.brand }} perfumes
      </div>
    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="brandMenu.visible" class="dropdown-overlay" @click="closeBrandDropdown"></div>

    
    <br>

  <div v-if ="!isMobile" class="scent-types-detail">
     <v-chip 
      v-for="(type, index) in combinedTypesArray"
      :key="`type-${perfume.id}-${index}`"
      :color="getScentTypeColor(type)"          
      class="mobile-chip-custom-detail"
      @click="openTypeDropdown($event, type)"
    >
    </v-chip>
  </div>

  <!-- Type Dropdown -->
    <div v-if="typeMenu.visible" 
         :style="{ top: isMobile ? `${typeMenu.y}px` : `${typeMenu.y}px`, left: isMobile ? '25px' : `${typeMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToType(typeMenu.type)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Show me all {{ typeMenu.type.toLowerCase() }} perfumes
      </div>
    </div>

    <!-- Overlay for Brand Dropdown -->
    <div v-if="typeMenu.visible" class="dropdown-overlay" @click="closeTypeDropdown"></div>

    


    <br>
    <div class="perfume-description">
    <PerfumeDescription :perfume="perfume" />
    </div>
    <br>    

  
    <div class="perfume-notes-list">
    <PerfumeNotesList :perfume="perfume" />
    </div>

    <br>


    <div v-if = "isMobile">
    <ImageCarousel :notes="combinedNotes" :mobile="isMobile" />
      </div>
      <div v-if= "!isMobile" style="user-select: none;">
    <ImageCarousel2 :notes="combinedNotes"/>
    </div>
    <br><br>



<div v-if="isMobile" class="mobile-gradient-background">
  <br>
    <v-row>      

  
<!-- Ratings Box for Longevity, Projection, and Price -->


  <v-col cols="12" class="py-1 ma-0">
    <div class="mobile-rating-box">           
      <v-row>
        <v-col cols="12" class="py-1 ma-0">          
          <!-- Row 1: Projection -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Intensity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, sillageRating(perfume.sillage))"></div>
              </div>
            </v-col>
          </v-row>               
          
          <!-- Row 2: Longevity -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Longevity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, longevityRating(perfume.longevity))"></div>
              </div>
            </v-col>
          </v-row>
  
        <!-- Row 3: Price, Concentration, and Year -->
        <v-row class="rating-row align-center">
          <v-col cols="4" class="rating-label pa-0">
            <span v-for="n in priceToNumber(perfume.price)" :key="n" class="price-symbol">$</span>
          </v-col>
          <v-col cols="4" class="text-center pa-0">
      <span :style="getConcentrationStyle(perfume.concentration)">
        {{ perfume.concentration }}
      </span>
    </v-col>

          <v-col cols="4" class="text-right pa-0" style="color:grey">
            <span>{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? parseInt(perfume.year, 10) : '' }}</span>
          </v-col>
        </v-row>


        </v-col>
      </v-row>
    </div>
  </v-col>
  
  
</v-row>
<br>
</div>


  <br><br><br>

<div class="footer">
<v-btn 
  class="amazon-btn" 
  :href="generateAmazonLink(perfume.brand, perfume.name, perfume.concentration)"
  :style="{ backgroundColor: 'black', color: '#FFFFFF' }"
  icon
>
  <v-icon size="22px" style="margin-right: 5px; color: white;">mdi-tag</v-icon>
  Check Amazon
</v-btn>


</div>




  </div>

  

</template>

<script>
import axios from 'axios';
import PerfumeDescription from './PerfumeDescription.vue';
import PerfumeNotesList from './PerfumeNotesList.vue';
import ImageCarousel from './ImageCarousel.vue';
import ImageCarousel2 from './ImageCarousel2.vue';
import ImageCarousel3 from './ImageCarousel3.vue';
import FavoritesToggle from './FavoritesToggle.vue';
import { getScentTypeColor } from '@/utils/colorMapping.js';


export default {
  props: ['image_name', 'id', 'brand' ,'description', 'scentgn', 'url_parameter'],
  components: {
    PerfumeDescription,
    ImageCarousel,
    ImageCarousel2,
    ImageCarousel3,
    PerfumeNotesList,
    FavoritesToggle
  },
  data() {
    return {
      perfume: {},
      allImages: {}, // for pictures
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],
      
      brandMenu: {
            visible: false,
            brand: '',
            x: 0,
            y: 0,
          },
          typeMenu: {
            visible: false,
            type: '',
            x: 0,
            y: 0,
          },
          uploadMenu: {
            visible: false,
            type: '',
            x: 0,
            y: 0,
          },
          isImageLoaded: false, // Tracks carousel load status
    };
  },
  
  created() {
    this.fetchPerfumeDetails();
    this.allImages = this.importAllImages(); //Images
    window.scrollTo(0, 1);
  },

  mounted() {

    this.$nextTick(() => {
  requestAnimationFrame(() => {
    window.scrollTo(0, 1);
  });
});


    },

    computed: {
  combinedNotes() {
    
    // Split 'perfume.notes' and check the number of items
    const allNotes = (this.perfume.notes || '').split(', ').filter(n => n.trim() !== '');
    
    // Split 'notes_strong' to check if it has 4 or more items
    const strongNotes = this.perfume.notes_strong ? this.perfume.notes_strong.split(', ').filter(n => n.trim() !== '') : [];

    // Use 'notes_strong' exclusively if it has 4 or more items
    if (strongNotes.length >= 4) {
      return strongNotes.slice(0, 5); // Limit to the first 5 items
    }

    // Use 'perfume.notes' if it has less than 6 items
    if (allNotes.length < 6) {
      return allNotes.slice(0, 5); // Still apply slice to maintain consistency, though not necessary
    }

    // Split 'notes_medium' in case we need to combine it with 'strong'
    const mediumNotes = this.perfume.notes_medium ? this.perfume.notes_medium.split(', ').filter(n => n.trim() !== '') : [];

    // Combine 'strong' and 'medium' notes and limit to 5 items
    return [...strongNotes, ...mediumNotes].slice(0, 5);
  },

  perfumeFontSize() {
  return (this.perfume?.name?.length > 30 || this.perfume?.brand?.length > 30) ? '1em' : '1.25em';
},

combinedTypesArray() {
    return this.perfume.combined_types ? this.perfume.combined_types.split('-') : [];
  }


},



  methods: {
    async fetchPerfumeDetails() {
  console.log("Attempting to fetch details for:", this.url_parameter); 

  try {
    // Retrieve stored perfumes from localStorage
    const storedPerfumes = localStorage.getItem('shuffledPerfumes');

    if (storedPerfumes) {
      const perfumes = JSON.parse(storedPerfumes);

      // Find the perfume in local storage
      const foundPerfume = perfumes.find(perfume => 
        perfume.id === this.url_parameter || 
        perfume.image_name === this.image_name
      );

      if (foundPerfume) {
        console.log("Loaded perfume from local storage:", foundPerfume);
        this.perfume = foundPerfume;
        return; // Exit early since we have the data
      }
    }

    // Fallback to API request if not found in local storage
    console.log("Fetching perfume details from API...");
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes/${this.url_parameter}`);
    this.perfume = response.data;
    console.log("Received data from API:", this.perfume);

  } catch (error) {
    console.error('Failed to fetch perfume details:', error);
  }
},


    
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },

    
// NavigateBack method inside your PerfumeDetails.vue component's script
  navigateBack() {
    const lastVisited = sessionStorage.getItem('lastVisited');
    console.log('details Page: Stored last visited was', lastVisited);
    if (lastVisited && (lastVisited.startsWith('/perfumes') || lastVisited.startsWith('/uploadimages') || lastVisited.startsWith('/explore-perfumes') || lastVisited.startsWith('/favorites'))) {
      this.$router.go(-1); // Acts as a browser back button if there's a history
      console.log('details Page: Function as back button!');
    } else {
      this.$router.push('/perfumes'); // Redirects to '/perfumes' if accessed directly via link
      console.log('details Page: Function as route to perfumes');
    }
  },


    generateAmazonLink(brand, name, concentration) {
    const combinedName = [brand, name, concentration] // Combine brand, name, and concentration
    .filter(Boolean) // Remove any undefined or empty values
    .join(' '); // Join them with a space
    const normalizedName = combinedName

      .normalize('NFD') // Normalize accents
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      .replace(/\s+/g, ' ') // Replace multiple spaces with one
      .trim() // Trim whitespace
      .toLowerCase() // Lowercase all letters
      .replace(/\b\w/g, (c) => c.toUpperCase()); // Capitalize the first letter of each word

    return `https://www.amazon.com/s?k=${encodeURIComponent(normalizedName)}&tag=perfumeprophe-20`;
  },


  getScentTypeColor,


 openBrandDropdown(event, brand) {
      this.brandMenu.visible = true;
      this.brandMenu.brand = brand;
      console.log("Brand selected:", brand);  // Log the brand to the console
      this.brandMenu.x = event.clientX; // Positioning for larger screens if needed
      this.brandMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeBrandDropdown);
    },
    navigateToBrand(brand) {
      console.log("Brand for Route selected:", brand);  // Log the brand to the console
      this.$router.push({ path: '/perfumes', query: { items: 12, brand: brand } });
      this.closeBrandDropdown(); // Close the dropdown after navigation
    },
    closeBrandDropdown() {
      this.brandMenu.visible = false; // Close the dropdown
    },


    openTypeDropdown(event, type) {
      this.typeMenu.visible = true;
      this.typeMenu.type = type;
      this.typeMenu.x = event.clientX; // Positioning for larger screens if needed
      this.typeMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeTypeDropdown);
    },
    navigateToType(type) {
      this.$router.push({ path: '/perfumes', query: { items: 12, type: type } });
      this.closeTypeDropdown(); // Close the dropdown after navigation
    },
    closeTypeDropdown() {
      this.typeMenu.visible = false; // Close the dropdown
    },



    openUploadDropdown(event, type) {
      this.uploadMenu.visible = true;
      this.uploadMenu.type = type;
      this.uploadMenu.x = event.clientX; // Positioning for larger screens if needed
      this.uploadMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeUploadDropdown);
    },
    navigateToUpload() {
      this.$router.push('/uploadimages');
      this.closeUploadDropdown(); // Close the dropdown after navigation
    },
    closeUploadDropdown() {
      this.uploadMenu.visible = false; // Close the dropdown
    },




  generateBarContainerStyle() {
    return {
      backgroundColor: 'white',
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden', // Ensures the inner bar respects the border radius
    };
  },

    valueRating(perfume) {
      return perfume.someValue; // Replace with your logic
    },

     ratingBarStyle(perfume, ratingValue) {
    const endColor = perfume.dom_color || 'black';
    const widthPercentage = (ratingValue / 5) * 100;
    const style = {
      background: `linear-gradient(to right, #eeeeee, ${endColor})`,
      width: `${widthPercentage}%`,
      height: '100%',
      borderRadius: '2px',
    };

    return style;
  },


  // convert categories into numerics
  sillageRating(sillage) {
  if (!sillage) return 0; // Return a default rating when undefined
  const ratingValues = {
    'very low': 1,
    'low': 2,
    'medium': 3,
    'strong': 4,
    'very strong': 5
  };
  return ratingValues[sillage.toLowerCase()] || 0;
},


  longevityRating(longevity) {
    if (!longevity) return 0; // Return a default rating when undefined
    const ratingValues = {
      'very short': 1,
      'short': 2,
      'medium': 3,
      'long': 4,
      'very long': 5
    };
    return ratingValues[longevity.toLowerCase()] || 0; // Default to 0 if not found
  }, 
 
  priceToNumber(price) {
    if (!price) return 0; // Return a default rating when undefined
    const priceMap = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'high': 4,
      'very high': 5
    };
    return priceMap[price.toLowerCase()] || 0;
  },

  getConcentrationStyle(concentration) {
    if (!concentration) return {};

    let styles = {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      overflow: 'hidden',
      maxWidth: '168px', // Default max width
      
      color: 'grey',
      paddingTop: '5px'
    };

    const length = concentration.length;
    if (length > 25) {
      styles.maxWidth = '0px'; // Hide text by setting maxWidth to 0
    } else if (length > 20) {
      styles.transform = 'translateX(-20%)'; // Move text to the left
    }

    return styles;
  },

  markImageAsLoaded() {
    // Set the state to true only after all images have loaded
    this.isImageLoaded = true;
  },

  darkenColor(hex, percent = 12) {
    if (!hex) return '#333333'; // Default to dark gray if no color is set

    // Ensure it's a valid hex color
    hex = hex.replace('#', '');
    
    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Darken the color by blending with black
    r = Math.round(r * (1 - percent / 100));
    g = Math.round(g * (1 - percent / 100));
    b = Math.round(b * (1 - percent / 100));

    // Convert back to hex
    const darkenedHex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

    return darkenedHex;
  }


   

    

  }
};



</script>



<style scoped>

.img-container {
    max-width: 100%;
    display: flex;
    justify-content: center; 
    overflow: visible;
    border-radius: var(--radius-basic);
    position: relative;
  }
  
  .perfume-img {
    height: 320px;
    transform: translateY(-3%); /* Adjust position for small screens */
    border-radius: var(--radius-basic);
  }

  .title-container {
  display: flex;
  flex-direction: column; /* This aligns children vertically */
  align-items: center; /* This centers them horizontally in the container */
  justify-content: center; /* This centers them vertically in the container */
}

.perfume-name {
  font-weight: bold;
  font-size: 1.25em;
}

.perfume-brand {
  color: grey;
  font-weight: normal;
  font-size: 1em;
}

.perfume-description {
  color: #333; /* Dark grey for better readability */
  text-align: justify; /* Justify text for a clean look */
  line-height: 1.5; /* Improve readability */
  margin: 0 auto; /* Center align the text box */
  max-width: 860px; /* Maximum width of 900px */
  padding: 0 35px; /* Consistent padding for small screens, adjust as needed */
  hyphens: auto; /* Enables automatic hyphenation */
}


.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 85px;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional shadow for better visibility */
  z-index: 500;
}

.perfume-notes-list {
  display: flex;          /* Using flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center;    /* Center-align items horizontally in the flex container */
  justify-content: center;/* Center-align items vertically if there is extra space */
  text-align: center;     /* Ensures text within each child is centered as well */
  width: 100%;            /* Take full width of the container it's within */
  margin-top: 20px;       /* Optional: adds some space above the list */
  margin-bottom: 20px;    /* Optional: adds some space below the list */
}

.amazon-btn {
  background-color: black;
  color: white !important;
  width: 198px;
  height: 39px;
  border-radius: var(--radius-buttons2);
}

.scent-types-detail {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;  
    justify-content: center;
    border-radius: var(--radius-basic) !important;
  }

  .mobile-chip-custom-detail {
    font-size: 0.9em; 
    color: white; 
    width: 28px !important;
    height: 28px !important;    
    text-align: center; 
    justify-content: center;
    border-radius: var(--radius-chips) !important;
    opacity: 100%;
  }


/* Example to hide dots - adjust the selector based on actual implementation */
.vue-concise-slider .dots-selector {
    display: none;
}


.header-area:hover {
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
}


.mobile-gradient-background {
  background-color: #efefef;
  padding: 20px;
}

.upload-button {
  position: absolute;
  top: 15px;
  right: -8px;
  height: 28px;
  width: 24px;
  background-color:white; /* Semi-transparent background */
  border-radius: var(--radius-buttons);
}

.fade-in {
  opacity: 0;
  transform: scale(0.95); /* Start smaller */
  transition: opacity 0.45s ease-in-out, transform 0.45s ease-in-out;
}

.fade-in.show {
  opacity: 1;
  transform: scale(1); /* Grows to full size */
}



</style>