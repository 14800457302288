<template>
   <div id="example" v-if="filteredSlides.length >= 3">
    <carousel-3d ref="carousel" :count="filteredSlides.length" :border=0 :width=340 :height=333 :space=330 :perspective=0
                  :animationSpeed=500 :loop="true" :controls-visible=!mobile @after-slide-change="updateActiveIndex">
      <slide v-for="(slide, index) in filteredSlides" :index="index" :key="index">
        
        <figure @click="openNoteDropdown($event, extractFilename(slide.src))">
        <img :src="slide.src" alt="Slide image">
        </figure>
        <figcaption @click="openNoteDropdown($event, extractFilename(slide.src))" v-bind:style="{ 'opacity': isActive(index) ? '1' : '0', 'transform': isActive(index) ? 'translateY(0)' : 'translateY(100%)' }">
  {{ extractFilename(slide.src) }} 
</figcaption>
      </slide>
      
    </carousel-3d>



     <!-- Notes Dropdown -->
    <div v-if="noteMenu.visible" 
         :style="{ top: isMobile ? `${noteMenu.y}px` : `${noteMenu.y}px`, left: isMobile ? '25px' : `${noteMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToNote(noteMenu.note)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Get me all perfumes with {{ noteMenu.note }}
      </div>
    </div>

    <!-- Overlay for Notes Dropdown -->
    <div v-if="noteMenu.visible" class="dropdown-overlay" @click="closeNoteDropdown"></div>
    
  </div>



  
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
components: {
  Carousel3d,
  Slide
},
data() {
    return {
      startY: 0,
      startX: 0,
      activeIndex: 0, // Initial active index
      noteMenu: {
        visible: false,
        note: '',
        x: 0,
        y: 0,
      },

    };
  },

props: ['notes', 'mobile'],
computed: {


  filteredSlides() {
    const usedImages = new Set();  // Set to track images already used

    return this.notes.map(note => {
      // Normalize the note by removing diacritics and 'Absolute', then trim
      let normalizedNote = note
        .normalize('NFD') // Normalize to decompose special characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/Absolute/gi, '') // Remove 'Absolute' if present
        .replace(/Co2/gi, '') // Remove 'Co2' if present
        .replace(/Cest/gi, '') // Remove 'Cest' if present
        .replace(/Zest/gi, '') // Remove 'Zest' if present
        .replace(/Bud/gi, '') // Remove 'Zest' if present
        .trim();
      let parts = normalizedNote.split(' ');

      // Try to find an image starting with the full term and progressively reducing
      for (let i = 0; i < parts.length; i++) {
        let imageName = parts.slice(i).join('_').toLowerCase() + '.webp';
        if (!usedImages.has(imageName)) { // Check if the image has not been used yet
          try {
            const imageSrc = require(`@/assets/notes/${imageName}`);
            usedImages.add(imageName); // Mark this image as used
            return { src: imageSrc };
          } catch (e) {
            // Continue to the next iteration if the image file is not found
            continue;
          }
        }
      }
      return null; // Return null if no images are found for any reduced terms
    }).filter(slide => slide !== null); // Filter out null values
  }



},

mounted() {

    this.$nextTick(() => {
    this.preventScrollOnSwipe();
  });
  },

  
  methods: {

    extractFilename(path) {
    let filename = path.split('/').pop(); // Get the filename part of the path
    filename = filename.substring(0, filename.indexOf('.')); // Remove extension
    filename = filename.replace(/_/g, ' '); // Replace underscores with spaces
    return filename.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Capitalize each word
  },

  isActive(index) {
      return index === this.activeIndex; // Check if the index is the active index
    },

    updateActiveIndex(index) {
      this.activeIndex = index; // Update active index when the slide changes
    },

    preventScrollOnSwipe() {
  if (this.filteredSlides.length < 3) return; // Do nothing if carousel is not rendered
  this.$nextTick(() => {
    const carouselRef = this.$refs.carousel;
    if (carouselRef) {
      const carouselElement = carouselRef.$el || carouselRef;
      carouselElement.addEventListener('touchstart', this.handleTouchStart);
      carouselElement.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    } else {
      console.warn('Carousel ref is undefined.');
    }
  });
},


  handleTouchStart(e) {
    this.startX = e.touches[0].clientX;
    this.startY = e.touches[0].clientY;
    //console.log('Touch start X:', this.startX, 'Y:', this.startY);
  },

  handleTouchMove(e) {
    const moveX = e.touches[0].clientX;
    const moveY = e.touches[0].clientY;
    //console.log('Touch move X:', moveX, 'Y:', moveY);

    const diffX = moveX - this.startX;
    const diffY = moveY - this.startY;

    //console.log(`Horizontal movement: ${diffX}, Vertical movement: ${diffY}`);
    if (Math.abs(diffX) > Math.abs(diffY)) {
      //console.log('Preventing default behavior due to horizontal swipe');
      e.preventDefault();
    } else {
      //console.log('Allowing vertical movement');
    }
  },

   openNoteDropdown(event, note) {
      this.noteMenu.note = note;
      this.noteMenu.visible = true;
      this.noteMenu.x = event.clientX; // Positioning for larger screens if needed
      this.noteMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeNoteDropdown);
    },
    navigateToNote(note) {
      console.log("Navigating to note:", note); // Debugging: Confirm navigation target
      this.$router.push({ path: '/perfumes', query: { items: 12, search: note } });
      this.closeNoteDropdown(); // Close the dropdown after navigation
    },
    closeNoteDropdown() {
      this.noteMenu.visible = false; // Close the dropdown
    },

  }

};

</script>

<style>
#example .carousel-3d-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjusted to fit design needs */
  border-radius: 0px;
  background-color: white;
}

#example img {
  width: 100%; /* Ensures the image attempts to fill the entire container width */
  height: 100%; /* Ensures the image attempts to fill the entire container height */
  object-fit: cover; /* Covers the area, cropping as needed without distorting */
  position: absolute; /* Allows the image to be positioned over the background */
  top: 50%; /* Centers the image vertically */
  left: 50%; /* Centers the image horizontally */
  transform: translate(-50%, -50%); /* Corrects the centering of the image */
  padding-bottom:25px;
  border-radius: 0px;
  background-color: white;
}

.carousel-3d-container figure {
  margin:0;
  padding-bottom:48px;
  background-color: white;
  border-radius: 0px;
}

.carousel-3d-container figcaption {
  position: absolute;
  color: rgb(0, 0, 0);
  bottom: -1px;
  font-size: 16px;
  min-width: 100%;
  box-sizing: border-box;
  opacity: 0.1;  /* Start with hidden text */
  transform: translateY(100%); /* Start by moving the text down */
  transition: opacity 0.75s ease;/* Apply transitions */

}

</style>
