
import Vue from 'vue';
import App from './App.vue'; // Import App.vue
import vuetify from './plugins/vuetify';
import router from './router';
import globalMethods from './globalMethods';

import '@mdi/font/css/materialdesignicons.css'; // Add MDI Icon CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Add Awesome Icon CSS
import "bootstrap-icons/font/bootstrap-icons.css"; // Add Bootstrap Icon CSS
import 'slick-carousel/slick/slick.css'; // Add Slick CSS
import 'slick-carousel/slick/slick-theme.css'; // Add Slick Theme CSS
import '@/assets/styles/global-styles.css';


Vue.config.productionTip = false;


Vue.mixin(globalMethods);

console.log('Starting Vue.js application...');

new Vue({
  vuetify,
  router,
  render: h => h(App) // Render App as the root component
}).$mount('#app');

console.log('Vue.js application mounted.');
