<template>
  <!-- Make a div wrapped slider,set height and width -->
  <div style="width:78%;margin:20px auto;height:420px" v-if="filteredSlides.length >= 3">
     <!-- Using the slider component -->
     <slider ref="slider" :options="options">
         <!-- slideritem wrapped package with the components you need -->
         <slideritem v-for="(slide, index) in filteredSlides" :key="index" :style="{ width: '490px'}" class="sitem">
        
          <div class="slide-image">
          <img :src="slide.src" :alt="`Image for ${slide.name}`" class="image">
        </div>

        <div class="image-label">{{ formatImageName(slide.src) }}
        </div>
        hello
      </slideritem>

         <!-- Customizable loading -->
         <div slot="loading">loading...</div>
     </slider>

     <div class="mdi-button" @click="slidePrev">
      <i class="mdi mdi-chevron-left"></i>
    </div>
    <!-- Right arrow for navigation -->
    <div class="mdi-button" @click="slideNext">
      <i class="mdi mdi-chevron-right"></i>
    </div>

  </div>
</template>


<script>
  // import slider components
  import { slider, slideritem } from 'vue-concise-slider'
  export default {
  
  data () {
     return {
      isSliding: false,
      debounceInterval: 335, // milliseconds (350 ms was a secure setting, but less reactive)
       //Slider configuration [obj]
       options: {
         currentPage: 0,
         speed: 300,
         itemAnimation: true,
         thresholdDistance: 100,
         thresholdTime: 250,
         slidesToScroll: 1,
         pagination:false,
         preventDocumentMove:false,
         loop:true,
         //centeredSlides:true,
         loopedSlides:3,
         //autoplay:4000,
         
       }
     }
   },
   components: {
     slider,
     slideritem
   },

   props: ['notes', 'mobile'],
  computed: {


  filteredSlides() {
    const usedImages = new Set();  // Set to track images already used

    return this.notes.map(note => {
      // Normalize the note by removing diacritics and 'Absolute', then trim
      let normalizedNote = note
        .normalize('NFD') // Normalize to decompose special characters
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
        .replace(/Absolute/gi, '') // Remove 'Absolute' if present
        .replace(/Co2/gi, '') // Remove 'Absolute' if present
        .replace(/Cest/gi, '') // Remove 'Cest' if present
        .replace(/Zest/gi, '') // Remove 'Zest' if present
        .replace(/Bud/gi, '') // Remove 'Zest' if present
        .trim();
      let parts = normalizedNote.split(' ');

      // Try to find an image starting with the full term and progressively reducing
      for (let i = 0; i < parts.length; i++) {
        let imageName = parts.slice(i).join('_').toLowerCase() + '.webp';
        if (!usedImages.has(imageName)) { // Check if the image has not been used yet
          try {
            const imageSrc = require(`@/assets/notes/${imageName}`);
            usedImages.add(imageName); // Mark this image as used
            return { src: imageSrc };
          } catch (e) {
            // Continue to the next iteration if the image file is not found
            continue;
          }
        }
      }
      return null; // Return null if no images are found for any reduced terms
    }).filter(slide => slide !== null); // Filter out null values
  }
},

   methods: {

    slideNext() {
      if (this.isSliding) return;
      this.isSliding = true;
      this.$refs.slider.$emit('slideNext');
      setTimeout(() => { this.isSliding = false; }, this.debounceInterval);
    },
    slidePrev() {
      if (this.isSliding) return;
      this.isSliding = true;
      this.$refs.slider.$emit('slidePre');
      setTimeout(() => { this.isSliding = false; }, this.debounceInterval);
    },
    extractFilename(path) {
    let filename = path.split('/').pop(); // Get the filename part of the path
    filename = filename.substring(0, filename.indexOf('.')); // Remove extension
    filename = filename.replace(/_/g, ' '); // Replace underscores with spaces
    return filename.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Capitalize each word
  },
  formatImageName(src) {
    let name = src.split('/').pop().split('.')[0]; // Extract the file name without extension
    return name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Format name
  }

  },
  
  }


</script>




<style>
   
   .sitem {
  margin: -33px ; /* Reduced margin */
  width: 490px;
}

.slide-image {
  width: 100%;
  height: 100%;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: var(--radius-basic);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 40px;
}

.image-label {
  position: absolute;
  text-align: left;
  bottom: 0px;
  left: -9px;
  color: #000000;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  background-color: white;
  padding: 5px 10px;
  width: 100%;
}

  
  .mdi-button {
    position: absolute;
    bottom: 22%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 40px; /* Size of the icon */
    color: #000000; /* Color of the icon */
    z-index: 10; /* Ensure the buttons are above the slides */
    user-select: none; /* This will prevent text selection */
  }

  .mdi-button .mdi {
    background: rgba(255, 255, 255, 0.824); /* Semi-transparent background for better visibility */
    border-radius: 25%; /* Circular background */
    padding: 10px; /* Padding around the icon */
  }

  .mdi-button:hover .mdi {
    background: rgba(255, 255, 255, 0.8); /* Darker background on hover */
  }

  .mdi-button:nth-child(2) { /* Left arrow specifics */
    left: 60px;
  }

  .mdi-button:nth-child(3) { /* Right arrow specifics */
    right: 60px;
  }

</style>
