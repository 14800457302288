<template>
  <v-card class="terms-card">
    <v-card-title class="terms-title">

      <span class="terms-title-text">Terms & Conditions</span>
    </v-card-title>

    <v-card-text>
      <p class="terms-intro">
        By uploading images to Perfume Prophet, you agree to the following terms:
      </p>

      <v-divider class="mb-3"></v-divider>

      <ul class="terms-list">
        <li>
          <v-icon class="terms-bullet">mdi-camera</v-icon>
          <div>
            <strong>Ownership:</strong> You retain full ownership of your images.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-handshake</v-icon>
          <div>
            <strong>Usage Rights:</strong> Perfume Prophet receives a <em>non-exclusive, royalty-free, worldwide</em> right to display and use your images in the app.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-email</v-icon>
          <div>
            <strong>Withdrawal of Rights:</strong> You may withdraw usage rights at any time by contacting us at 
            <a href="mailto:mail@perfumeprophet.com" class="terms-link">mail@perfumeprophet.com</a>.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-block-helper</v-icon>
          <div>
            <strong>Forbidden Content:</strong> Do not upload copyrighted, offensive, or misleading images.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-account-check</v-icon>
          <div>
            <strong>Permission:</strong> If your image features a person, you confirm that you have obtained their consent.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-scale-balance</v-icon>
          <div>
            <strong>Legal Compliance:</strong> Your images must comply with applicable copyright, privacy, and data protection laws.
          </div>
        </li>
        <li>
          <v-icon class="terms-bullet">mdi-pencil-ruler</v-icon>
          <div>
            <strong>Editing Rights:</strong> We may resize or enhance images for improved display.
          </div>
        </li>
      </ul>
      
      <v-divider class="mt-3"></v-divider>

      <p class="terms-footer">
        <br>
        By submitting, you confirm that you have the legal right to share these images and grant these rights.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.terms-card {
  padding: 20px;
  background-color: none !important;
  box-shadow: none !important;
}

.terms-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
}

.terms-title-text {
  color: black;
}

.terms-icon {
  font-size: 1.8rem;
  color: #0f65bb;
}

.terms-intro,
.terms-list li div,
.terms-footer {
  font-size: 1rem;
  line-height: 1.4;
  color: black;
}

.terms-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.terms-list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 14px;
  color: black;
}

.terms-list li div {
  flex: 1;
  line-height: 1.4;
  color: black;
}

.terms-bullet {
  color: #0f65bb;
  font-size: 20px;
  flex-shrink: 0;
}

.terms-link {
  color: black;
  text-decoration: none;
}

.terms-link:hover {
  text-decoration: underline;
}
</style>
