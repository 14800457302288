<template>
    <v-container v-if="isAuthenticated">
      <h2>Which images do you want to integrate next?</h2>
      <br>
      <br>
      <v-row>
        <v-col
          v-for="(image, index) in images"
          :key="index"
          cols="12" sm="6" md="4" lg="3"
        >


        <!-- Display Image Name -->
        <div class="image-name" style="text-align: center; color: grey;">
          {{ imageDetails[imageName_old[index]] || 'Unknown Image' }}
        </div>

          <v-card :class="{ 'grey-out': processingImage === image }" @click="openDialog(image)">

            <v-progress-circular
              v-if="processingImage === image && isLoading"
              :size="70"
              color="primary"
              indeterminate
              position = "absolute"
              z-index= 5
            ></v-progress-circular>

            <v-img
              :src="image"
              height="300px"
            ></v-img>
            
            <v-card-actions>
              <v-btn color="green" text @click.stop="integrateImage(image)">
                <v-icon left>mdi-check</v-icon>
                Integrate in App
              </v-btn>
              <v-btn color="red" text @click.stop="skipImage(image)">
                <v-icon left>mdi-close</v-icon>
                Skip
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- Image Dialog -->
      <v-dialog v-model="dialog" width="800px">
  <v-card>
    <v-toolbar dense flat>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-img
      :src="selectedImage"
      contain
      aspect-ratio="1"
      class="image-fill-dialog"
    ></v-img>

    <v-card-actions class="justify-center">
      <v-btn color="green" text @click="integrateImage(selectedImage)">
        <v-icon left>mdi-check</v-icon>
        Integrate in App
      </v-btn>
      <v-btn color="red" text @click="skipImage(selectedImage)">
        <v-icon left>mdi-close</v-icon>
        Skip
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    </v-container>

     <!-- Simple Password Login -->
  <v-container v-else>
    <v-card class="pa-5" width="400" outlined>
      <v-card-title>Enter Password</v-card-title>
      <v-card-text>
        <v-text-field v-model="password" label="Password" type="password"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="checkPassword">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>



  </template>
  

  
<script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        images: [],
        imageName_old: [],
        imageDetails: {}, // Object to store mapping of image paths to image names
        dialog: false,
        selectedImage: '',
        processingImage: null, // Track the image currently being processed
        isLoading: false, // Track if a request is in progress
        isAuthenticated: false
      };
    },
    created() {
      this.fetchImages();
      this.fetchImageData();
      this.checkAuth();
    },

    methods: {
      
      async fetchImages() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_IMG_API_URL}/images`);
          const backendImages = (Array.isArray(response.data.images) ? response.data.images : [])
            .map(img => `${process.env.VUE_APP_IMG_API_URL}${img}`); // Construct full URL for backend images
          
            this.images = backendImages;
            this.processImageFilenames(); // Call
        } catch (error) {
          console.error('Error fetching images:', error);
          return []; 
        }
      },
      
      async fetchImageData() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_IMG_API_URL}/image-db`);
    this.imageDetails = response.data.reduce((acc, item) => {
      // Extract filename from path
      const filename = item.imagePath.split('/').pop();
      acc[filename] = item.imageName; // Map filename to its name
      return acc;
    }, {});
    // Output the actual data to the console
    console.log("Image Details Mapping:", JSON.parse(JSON.stringify(this.imageDetails)));
  } catch (error) {
    console.error('Failed to fetch image data:', error);
  }
},

processImageFilenames() {
      if (this.images.length > 0) {
        this.imageName_old = this.images.map(image => {
          const urlParts = image.split('/');
          return urlParts[urlParts.length - 1]; // Extracts the filename part of the URL
        });
        console.log('Processed Image Filenames:', this.imageName_old); // Log the filenames to verify
      }
    },





    openDialog(image) {
      if (this.processingImage !== image) { // Only open dialog if not processing
        this.selectedImage = image;
        this.dialog = true;
      }
    },
    processImage(image, endpoint) {
      this.processingImage = image; // Set the processing image
      this.isLoading = true; // Start loading
      const imageName = image.split('/').pop(); // Extract filename

      axios.post(`${process.env.VUE_APP_IMG_API_URL}/${endpoint}`, { imageName })
        .then(response => {
          console.log("Server response:", response.data.message);
          this.dialog = false;
          this.fetchImages(); // Refetch images to effectively remount/reset the component state
        })
        .catch(error => {
          console.error("Error processing image:", error);
          this.dialog = false;
        })
        .finally(() => {
          this.processingImage = null; // Reset processing image
          this.isLoading = false; // End loading
        });
    },
    integrateImage(image) {
      this.processImage(image, 'process-image');
    },
    skipImage(image) {
      this.processImage(image, 'skip-image');
    },

    checkPassword() {
      if (this.password === "pp22") {
        this.isAuthenticated = true;
        localStorage.setItem("authenticated", "true"); // Store auth state
        this.fetchImages(); // Load images after login
      } else {
        alert("Incorrect password!");
      }
    },
    checkAuth() {
      // Check if the user is already authenticated
      if (localStorage.getItem("authenticated") === "true") {
        this.isAuthenticated = true;
        this.fetchImages();
      }
    },
  }
   

  };
</script>
  
<style scoped>
  .image-fill-dialog {
  height: calc(100vh - 120px); /* Adjust based on your actual toolbar and actions height */
  max-height: 600px; /* or any appropriate max-height */
}
.grey-out {
  opacity: 0.6;
  pointer-events: none; /* Disable clicking and interactions */
}


</style>
  