<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
    <v-main>
      <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">     
            <!--    
          <v-col cols="12" class="text-left">
            <h1 class="daily-header">
              Hey, how would you like to smell today?
            </h1>
          </v-col> -->

        <!-- Display 6 random tiles -->
        <v-row :class="isMobile ? 'px-2 pt-5' : 'px-0 pt-5'">
          <v-col
            v-for="(item, index) in displayedCards"
          :key="index"            
            cols="12"
            sm="6" md="4" lg="3"
            :class="isMobile ? 'd-flex pa-2 pb-2' : 'd-flex pa-0 pb-5 image-inspiration'"
          >
            <v-card 
              class="custom-card"
              title
              flat
              @click="navigateToPerfumes(item.note1, item.note2, item.type1, item.type2, item.gender, item.brand, 
                                        item.intensity, item.longevity, item.price)"
            >
            <v-lazy
                  :height="360"  
                  :options="{ rootMargin: '1700px 0px', threshold: 0.01 }"  
                  transition="fade-transition"
                >
              <v-img
                :src="getImage(item.image)"
                
                height="360px"
                :class="isMobile ? '' : 'darken-image'"
                style="border-radius: var(--radius-basic);"
                eager
              ></v-img>
              </v-lazy>
              
              <v-card-title class="custom-card-title px-0 py-0">{{ item.title }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-btn fab fixed right :ripple="false"  
              :style="{
                  bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 17px)' : buttonBottom,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  right: '20px',
                  display: scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
                  }"
              @click=scrollToTop class="transparent-btn">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>


      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'; // Import axios for HTTP requests



export default {
  name: 'ExplorePage',
  data() {
    return {
      exploreData: [], // Array to hold explore data
      imageMap: {}, // Object to map image names to image URLs
      displayedCards: [], // Array to hold the 6 random tiles
      defaultImage: require('@/assets/explore_images/explore_image1.webp'), // Default image
      isMobile: window.innerWidth < 560, // Initialize based on screen size
      buttonBottom: '95px', // Default bottom position for the button
      scrollY: 0,
      lastScrollY: 0, 
    };
  },
  methods: {
    async fetchExploreData() {
      const cacheKey = 'exploreDataCache';
      const cacheTimestampKey = 'exploreDataTimestamp';
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const now = new Date().getTime();

      const cachedData = JSON.parse(localStorage.getItem(cacheKey));
      const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

      if (cachedData && cachedTimestamp && (now - cachedTimestamp) < oneDay) {
        // Use cached data if it exists and is less than one day old
        this.displayedCards = cachedData;
      } else {
        // Fetch new data and cache it
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/explore`);
          const filteredData = response.data.filter(item => item.title !== 'title');
          const randomData = filteredData.sort(() => Math.random() - 0.5).slice(0, 12);
          
          this.displayedCards = randomData;
          this.exploreData = filteredData;

          // Cache the data and timestamp
          localStorage.setItem(cacheKey, JSON.stringify(randomData));
          localStorage.setItem(cacheTimestampKey, now);
        } catch (error) {
          console.error('Error fetching explore data:', error);
        }
      }

      console.log('Try to fetch data from:', `${process.env.VUE_APP_API_URL}/explore`);
      console.log('Current Env:', `${process.env.NODE_ENV}`);

    },

    loadImages() {
      const context = require.context('@/assets/explore_images', false, /\.webp$/);
      context.keys().forEach((key) => {
        const imageName = key.match(/[^/]+(?=\.webp$)/)[0]; // Extract the image name without the extension
        this.imageMap[imageName] = context(key);
      });
    },

    getImage(imageName) {
      // Return the mapped image or the default image if not found
      return this.imageMap[imageName] || this.defaultImage;
    },

  navigateToPerfumes(note1, note2, type1, type2, gender, brand, intensity, longevity, price) {
  let query = {}; // Initialize an empty query object

  // Build the search query from note1 and note2
  const searchTerms = [note1, note2].filter(Boolean).join(' '); // Combine notes without converting to lowercase
  if (searchTerms) query.search = searchTerms;

  // Format types with capitalized first letter
  const types = [];
  if (type1) types.push(this.capitalizeFirstLetter(type1));
  if (type2) types.push(this.capitalizeFirstLetter(type2));
  if (types.length) query.type = types.join(',');

  // Add gender if it exists
  if (gender) query.gender = gender.toLowerCase(); 

  // Directly use the brand as it appears in the datasource
  if (brand) query.brand = brand.split(',').map(b => b.trim()).join(',');

  // Add intensity if it exists
  if (intensity) query.intensity = intensity.split(',').map(i => i.trim()).join(',');

  // Add longevity if it exists
  if (longevity) query.longevity = longevity.split(',').map(l => l.trim()).join(',');

  // Add price to the query, ensuring it includes both min and max
  if (price) {
    const priceValues = price.split(',').map(p => parseInt(p.trim(), 10));
    query.price = `${Math.min(...priceValues)},${Math.max(...priceValues)}`;
  }

  // Ensure default items and fromTile are always included
  query.items = '12';
  query.fromTile = 'true';

  console.log('ExplorePage; Navigating with query (before sorting):', query);

  // Sort the query parameters by key to maintain order consistency
  query = Object.keys(query)
    .sort()
    .reduce((obj, key) => {
      if (query[key] !== undefined) {  // Remove undefined values
        obj[key] = query[key];
      }
      return obj;
    }, {});

  console.log('ExplorePage; Navigating with sorted query:', query);

  // Push the sorted query to Vue Router
  this.$router.push({
    path: '/perfumes',
    query,
  }).then(() => {
    console.log('ExplorePage; Navigated to PerfumeDataTable with query:', query);
    window.scrollTo(0, 0); // Scroll to top after navigation
    console.log('ExplorePage; Scrolled to top after navigation');
  }).catch((error) => {
    console.error('ExplorePage; Error navigating to PerfumeDataTable:', error);
  });
},

  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    handleScroll() {
  const currentScrollY = window.scrollY;

    if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
      // Scrolling down and past 600px
      this.buttonBottom = '95px'; // Set the button to 45px when scrolled more than 600px
    } 

    // Update the last scroll position
    this.lastScrollY = currentScrollY;
    this.scrollY = currentScrollY;  
  },






  },


  
  mounted() {
    this.fetchExploreData(); // Load data when component mounts
    this.loadImages(); // Load images when component mounts
    window.addEventListener('scroll', this.handleScroll);  
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  

  
};
</script>


