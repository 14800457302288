<template>
  <div>
    <div v-for="(note, index) in notesArray" :key="index" class="note-item" @click="openNoteDropdown($event, note.trim())">
      {{ note.trim() }} <!-- Trimming to remove any accidental whitespace -->
    </div>

    <!-- Notes Dropdown -->
    <div v-if="noteMenu.visible" 
         :style="{ top: isMobile ? `${noteMenu.y}px` : `${noteMenu.y}px`, left: isMobile ? '25px' : `${noteMenu.x}px` }"
         class="note-dropdown visible">
      <div class="dropdown-option" @click.stop="navigateToNote(noteMenu.note)">
        <v-icon left color="white">mdi-magnify</v-icon>
        Get me all perfumes with {{ noteMenu.note }}
      </div>
    </div>

    <!-- Overlay for Notes Dropdown -->
    <div v-if="noteMenu.visible" class="dropdown-overlay" @click="closeNoteDropdown"></div>
  </div>
</template>

<script>
export default {
  props: ['perfume'],
  data() {
    return {
      noteMenu: {
        visible: false,
        note: '',
        x: 0,
        y: 0,
      },

    };
  },
  
  computed: {
    // Computes an array of notes from the perfume.notes string
    notesArray() {
      if (this.perfume && this.perfume.notes) {
        return this.perfume.notes.split(','); // Split the notes string into an array by comma
      }
      return []; // Return an empty array if no notes are available
    }
  },
  methods: {
    openNoteDropdown(event, note) {
      this.noteMenu.note = note;
      this.noteMenu.visible = true;
      this.noteMenu.x = event.clientX; // Positioning for larger screens if needed
      this.noteMenu.y = event.clientY; // Adjust if you need the menu below the icon
      window.addEventListener('scroll', this.closeNoteDropdown);
    },
    navigateToNote(note) {
      console.log("Navigating to note:", note); // Debugging: Confirm navigation target
      this.$router.push({ path: '/perfumes', query: { items: 12, search: note } });
      this.closeNoteDropdown(); // Close the dropdown after navigation
    },
    closeNoteDropdown() {
      this.noteMenu.visible = false; // Close the dropdown
    },


  },


};
</script>

<style scoped>
.note-item {
  cursor: default; /* Default cursor when not hovering */

  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.note-item:hover {
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-decoration: underline; /* Underlines the text on hover */
}
</style>

