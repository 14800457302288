<template>
  <div class="slide-frame" ref="carousel" :class="{'fade-in': true, 'show': isCarouselLoaded}">

    <!-- Spinner (Shown While Carousel is Loading) -->
    <v-progress-circular
      v-if="!isCarouselLoaded"
      indeterminate
      color="grey lighten-2"
      class="carousel-spinner"
    ></v-progress-circular>

    <img 
  v-if="!shouldShowCarousel && slides.length === 1" 
  :src="slides[0].asset || slides[0].matchedImagePath" 
  alt="Single Image Slide" 
  class="slide-image single-image" @load="markCarouselAsLoaded"
/>

<div v-else>

    <vue-slick-carousel v-if="slides.length > 0"  ref="slick" v-bind="settings">
  <div
        v-for="(slide, index) in slides"
        :key="index"
        class="slide-content"
      >
        <!-- If it's the local asset slide -->
        <div v-if="slide.type === 'local'">
          <img :src="slide.asset" alt="Local Asset Slide" class="slide-image" @load="markCarouselAsLoaded" />
        </div>

        <!-- Otherwise it's an API slide -->
        <div v-else>
          
          <!-- Instagram handle + icon -->
          <span class="instagram-info">
            <a
              v-if="getInstaHandle(slide.instagram)"
              :href="`https://instagram.com/${getInstaHandle(slide.instagram)}`"
              rel="noopener"
            >
              @{{ getInstaHandle(slide.instagram) }}
            </a>
            <i v-if="getInstaHandle(slide.instagram)" class="mdi mdi-instagram"></i>
          </span>

          
          <img :src="slide.matchedImagePath" alt="API Slide" class="slide-image" />
        </div>
      </div>
    </vue-slick-carousel>
</div>

  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import axios from 'axios';

export default {
  name: 'UsedImagesCarousel',
  components: {
    VueSlickCarousel
  },
  props: ['imagePrefix'],
  data() {
    return {
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 190,
        swipe: true,
        swipeToSlide: true,
        touchThreshold: 60,
      },
      filteredImages: [],   // images from /image-db where used === 'yes'
      combinedImages: [],   // actual image paths from /images-used
      slides: [],           // final merged array (local first, then API slides)
      startY: 0,
      startX: 0,
      isCarouselLoaded: false, // Tracks carousel load status
    };
  },

  computed: {
  shouldShowCarousel() {
    return this.slides.length > 1; // Show carousel only if more than one image exists
  }
  },

  methods: {
    /** * 1) Fetch from /image-db API, filter for used === 'yes'*/
    async fetchUsedImages() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_IMG_API_URL}/image-db`);
    // Only keep images where used === 'yes' and imageName equals the imagePrefix prop.
    this.filteredImages = response.data.filter(
      image => image.used === 'yes' && image.imageName === this.imagePrefix
    );
    console.log('Filtered Used Images:', this.filteredImages);
  } catch (error) {
    console.error('Error fetching images:', error);
  }
},


    /* 2) Fetch from /images-used API to get all possible image URLs */
    async fetchCombinedImages() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_IMG_API_URL}/images-used`);
        this.combinedImages = response.data.images; // 'images' array
        //console.log('Combined Images:', this.combinedImages);
      } catch (error) {
        //console.error('Error fetching combined images:', error);
      }
    },

    /** 3) Return the local asset image using require, based on imagePrefix */
    getAssetImage() {
      const assetImage = require(`@/assets/images/${this.imagePrefix}.webp`);
      console.log('Asset image:', assetImage);
      return assetImage;
    },

    /**
     * 4) Build the final slides array:
     */

buildSlides() {
  const slides = [];

  // (A) Add the local asset slide
  try {
    const localAsset = this.getAssetImage();
    if (localAsset) {
      slides.push({
        type: 'local',
        asset: localAsset
      });
    }
  } catch (err) {
    console.warn('Local asset not found or error loading:', err);
  }

  // (B) Add API slides that have a matching image in combinedImages
  const apiBaseUrl = process.env.VUE_APP_IMG_API_URL;
  
  const apiSlides = this.filteredImages
    .map(image => {
      const baseName = image.combinedName.replace(/\.[^/.]+$/, '');
      const matchingImagePath = this.combinedImages.find(url => url.includes(baseName));
      
      console.log(`Matching image name: ${baseName}`);
      console.log(`Matching image path:`, matchingImagePath);
      
      // If no matching path is found, we skip this image (return null)
      if (!matchingImagePath) {
        return null;
      }

      // Construct full image path using the environment variable
      const fullImagePath = `${apiBaseUrl}${matchingImagePath}`;
      console.log(`Full image path: ${fullImagePath}`);

      // Return an object with the matched path
      return {
        ...image,
        type: 'api',
        matchedImagePath: fullImagePath
      };
    })
    .filter(slide => slide !== null); // remove null slides

  // Merge them together: local slide first, then the rest
  slides.push(...apiSlides);

  this.slides = slides;
  console.log('Merged slides array:', this.slides);

  // Log a readable table for slide details
  console.table(
    this.slides.map((slide, index) => ({
      Index: index + 1,
      Type: slide.type,
      'Image Path': slide.type === 'api' ? slide.matchedImagePath : slide.asset,
      Instagram: slide.instagram || 'N/A'
    }))
  );
},


      getInstaHandle(fullUrl) {
      if (!fullUrl) return '';
      // Remove potential protocol, "www.", and "instagram.com/" parts
      return fullUrl
        .replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/^instagram\.com\//, '')
        .replace(/\/+$/, ''); // remove trailing slash if any
    },



    preventScrollOnSwipe() {
      const carouselElement = this.$refs.carousel;
      if (carouselElement) {
        carouselElement.addEventListener('touchstart', this.handleTouchStart);
        carouselElement.addEventListener('touchmove', this.handleTouchMove, { passive: false });
      }
    },
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      const moveX = e.touches[0].clientX;
      const moveY = e.touches[0].clientY;
      if (Math.abs(moveX - this.startX) > Math.abs(moveY - this.startY)) {
        e.preventDefault();
      }
    },

    markCarouselAsLoaded() {
    // Set the state to true only after all images have loaded
    this.isCarouselLoaded = true;
  },

  },

  async mounted() {
    // Fetch the two sets of data in parallel
    await Promise.all([
      this.fetchUsedImages(),
      this.fetchCombinedImages()
    ]);


    this.buildSlides();

    this.$nextTick(() => {
      this.preventScrollOnSwipe();
    });

    console.log('Final slides to display:', this.slides);
  }
};
</script>

<style scoped>

.slide-frame{
width: 320px; 
margin: 25px auto; 
height: 350px; 
background-color: none;
border-radius: var(--radius-basic);
}


.slick-slide {
  height: 100%;
  color: #fff;
  font-size: 20px;
  background-color: none;
  border-radius: var(--radius-basic);
}

.slide-image {
  width: 100%; /* Make image take full width of the slide */
  max-height: 320px; /* Ensure image does not overflow slide's height */
  object-fit: cover !important; /* Ensure aspect ratio is maintained without cropping */
  object-position: center; /* Center the image inside the box */
  border-radius: var(--radius-basic);
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-number {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

/* Style the link to appear clickable */
.instagram-info {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px; /* Ensures the space remains even if no text */
  font-size: 14px;
  color: black;
}


.fade-in {
  opacity: 0;
  transform: scale(0.93); /* Start slightly smaller */
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.fade-in.show {
  opacity: 1;
  transform: scale(1); /* Grows to full size */
}



.carousel-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}



</style>