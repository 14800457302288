// globalMethods.js
import { Capacitor } from '@capacitor/core';
export default {
  data() {
    return {
      isOnline: navigator.onLine, // Detect initial online state
      isMobile: false,
    };
  },
  methods: {
    navigateToPerfumesStartpage() {
      this.$router.push('/perfumes');
    },
    async checkOnlineStatus() {
      const wasOffline = !this.isOnline;
      
      // First check navigator.onLine (quick check)
      this.isOnline = navigator.onLine;
      
      // Double-check by making a real network request
      if (this.isOnline) {
        try {
          await fetch('https://www.google.com/favicon.ico', { mode: 'no-cors' });
          this.isOnline = true;
        } catch (error) {
          this.isOnline = false;
        }
      }
    
      console.log(`Network status changed: ${this.isOnline ? 'Online' : 'Offline'}`);
    
      // If the app was offline and now is online, reload
      if (wasOffline && this.isOnline) {
        console.log('Network restored. Reloading app...');
        window.location.reload();
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      console.log('TopJump by ScrollToTop button'); // Add console log
      },

      onResize() {
        this.isMobile = window.innerWidth < 560; // Define mobile Device
      this.$forceUpdate();
      },

      isIOSApp() {
        return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
      },

      isIOSBrowser() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIOS = /iphone|ipad|ipod/.test(userAgent);
        const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
        return isIOS && !isStandalone; // True if it's an iOS browser but NOT a PWA or installed app
      },
    
      openAppStore() {
        window.location.href = "https://apps.apple.com/app/id6670541251"; // Correct App Store link
      },

     
  },
  mounted() {
    this.onResize(); // Set initial state based on current window size
    window.addEventListener('resize', this.onResize);
    window.addEventListener('online', this.checkOnlineStatus);
    window.addEventListener('offline', this.checkOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('online', this.checkOnlineStatus);
    window.removeEventListener('offline', this.checkOnlineStatus);
  }
};
