<template>
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main>

        
        <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">

          <v-row :class="isMobile ? 'px-2 pt-5' : 'px-0 pt-5'">

            
            <v-col v-for="(item, index) in perfumeImages"
              :key="index"
              cols="12"
              sm="6" md="4" lg="3"
              :class="isMobile ? 'd-flex pa-2 pb-2' : 'd-flex pa-0 pb-3 image-inspiration'"
            >
              <v-card 
                v-if="!item.isShareSlide && item.imageExists"
                class="custom-card"
                flat
                tile
                style="border-radius: var(--radius-basic);"
              >
              <v-lazy
                  :height="360"  
                  :options="{ rootMargin: '1900px 0px', threshold: 0.01 }"  
                  transition="fade-transition"
                >
                <v-img
                  :src="getImageUrl(item.combinedName)"
                  @click="goToDetails(item.image_name, item.id, item.name, item.brand, item.concentration, item.scentgn, item.url_parameter)"
                  :class="isMobile ? 'perfume-image' : 'perfume-image darken-image'"
                  eager
                ></v-img>
              </v-lazy>
                <v-card-title class="custom-card-title px-0 py-0">
                  <span class="instagram-info" v-if="item.imageExists && getInstaHandle(item.instagram)">

                  <a
                    :href="`https://instagram.com/${getInstaHandle(item.instagram)}`"
                    rel="noopener"
                    target="_blank"
                  >
                    @{{ getInstaHandle(item.instagram) }}
                  </a>
                  <i class="mdi mdi-instagram"></i>
                </span>
              </v-card-title>
              </v-card>

              <v-card 
                v-else
                class="custom-card share-card"
                flat
                tile
                style="border-radius: var(--radius-basic) !important; border: 1px solid  rgb(220, 220, 220); display: flex; align-items: center; justify-content: center; text-align: center; cursor: pointer;"
                @click="openSharePage"
              >
                <v-container>
                  <v-icon size="48" class="share-icon">mdi-camera</v-icon>
                  <p class="share-title">Share Your Shots</p>
                  <p class="share-subtitle">Show us your best perfume pictures and get featured in the app</p>
                </v-container>
              </v-card>


            </v-col>


          </v-row>

          <v-btn fab fixed right :ripple="false"  
              :style="{
                  bottom: isIOSApp() ? 'calc(' + buttonBottom + ' + 17px)' : buttonBottom,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  right: '20px',
                  display: scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
                  }"
              @click=scrollToTop class="transparent-btn">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>


            <!-- Offline Warning -->
            <div v-if="!isOnline" class="offline-overlay">
              <v-card class="offline-alert">
                <v-icon class="offline-icon">mdi-wifi-off</v-icon>
                <span>You are offline. Please check your internet connection.</span>
              </v-card>
          </div>


        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from 'axios';


export default {
  name: 'ExplorePerfumes',
  data() {
    return {
      perfumeImages: [],
      perfumeData: {},
      isMobile: window.innerWidth < 560,
      buttonBottom: '95px', // Default bottom position for the button
      scrollY: 0,
      lastScrollY: 0, 
    };
  },
  methods: {
   
    async fetchPerfumeImages() {
  const cacheKey = 'perfumeImagesCache';
  const cacheTimestampKey = 'perfumeImagesCacheTimestamp';
  const cacheDuration = 4 * 60 * 60 * 1000; // 4 hours in milliseconds


  try {
    // Check if cache exists and is still valid
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
    
    if (cachedData && cachedTimestamp) {
      const elapsedTime = Date.now() - parseInt(cachedTimestamp, 10);
      
      if (elapsedTime < cacheDuration) {
        // Use cached data
        this.perfumeImages = JSON.parse(cachedData);
        console.log('Using cached perfume images');
        return;
      }
    }

    // Fetch new data if cache is expired or does not exist
    console.log('Fetching new perfume images from API');
    const response = await axios.get(`${process.env.VUE_APP_IMG_API_URL}/image-db`);
    const allItems = response.data.filter(item => item.used === 'yes');

    // Fetch corresponding perfume details
    const perfumeDetails = await Promise.all(
      allItems.map(async (item) => {
        try {
          const perfumeResponse = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes-by-image/${item.imageName}`);
          return { ...item, ...perfumeResponse.data };
        } catch {
          return null;
        }
      })
    );

    // Validate images before adding them to the list
    const validatedItems = await Promise.all(
      perfumeDetails.map(async (item) => {
        if (!item) return null;
        const imageUrl = this.getImageUrl(item.combinedName);
        try {
          await axios.head(imageUrl);
          item.imageExists = true;
          return item;
        } catch {
          return null;
        }
      })
    );

    // Filter out invalid items
    this.perfumeImages = validatedItems
  .filter(item => item !== null)
  .sort((a, b) => {
    const dateA = new Date(a.timestamp.replace(/-/g, ':').replace(/^(\d{4}):(\d{2}):(\d{2}):/, '$1-$2-$3T'));
    const dateB = new Date(b.timestamp.replace(/-/g, ':').replace(/^(\d{4}):(\d{2}):(\d{2}):/, '$1-$2-$3T'));
    return dateB - dateA;
  })
  .slice(0, 48) // Only take the first 48 images
  .concat([{ isShareSlide: true }]); 


    // Store the new data in localStorage with a timestamp
    localStorage.setItem(cacheKey, JSON.stringify(this.perfumeImages));
    localStorage.setItem(cacheTimestampKey, Date.now().toString());

  } catch (error) {
    console.error('Error fetching perfume image data:', error);
  }
},


    getImageUrl(combinedName) {
      return `${process.env.VUE_APP_IMGUSED_API_URL}/${combinedName}`;
    },
    
    goToDetails(image_name, id, name, brand, concentration, scentgn, url_parameter) {
      if (!this.isOnline) {
        this.showOfflineWarning = true; // Show the warning
        return; // Prevent navigation
      }
      const description = this.formatForURL(`${name} ${concentration}`);
      const brand_clean = this.formatForURL(`${brand}`);
      this.$router.push({ name: 'PerfumeDetails', params: { image_name, id, brand: brand_clean, description, scentgn, url_parameter } });
    },
    formatForURL(string) {
      return string.toLowerCase().replace(/\s+/g, '-');
    },

  getInstaHandle(fullUrl) {
      if (!fullUrl) return '';
      return fullUrl
        .replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/^instagram\.com\//, '')
        .replace(/\/+$/, '');
    },

    handleScroll() {
  const currentScrollY = window.scrollY;

  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '95px'; // Set the button to 45px when scrolled more than 600px
  } 

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;  
},

openSharePage() {
    this.$router.push({ path: '/uploadimages' });
  },


  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);  
    this.fetchPerfumeImages();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },



};
</script>
  
  <style>
  .perfume-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: var(--radius-basic) !important;
  }
  .instagram-info a {
  color: black;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 14px;
}
.share-card {
  background-color: rgb(255, 255, 255) !important;
  min-height: 360px;
}

.share-icon {
  color: black !important;
  margin-bottom: 10px;
}

.share-title {
  font-weight: bold;
  font-size: 18px;
  color: black;
}

.share-subtitle {
  font-size: 14px;
  color: gray;
  margin: 5px;
}

  </style>
  