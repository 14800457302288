<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
    <v-main>
      <v-container fluid class="d-flex align-center justify-center startpage-container">
        <v-row>
          <!-- Instagram Icon -->
          <v-col cols="12" class="text-right">
            <a href="https://www.instagram.com/perfume.prophet/" target="_blank" class="instagram-link">
              <v-icon size="30px" color="black">mdi-instagram</v-icon>

            </a>
          </v-col>

          <v-col cols="12" class="text-left">
            <div v-if="isIOSApp()" style="height: 40px;"></div> <!-- Adjust the height as necessary -->
            <h1 class="app-title">
              <span class="underline">Perfume</span> Prophet.
            </h1>
          </v-col>

          <v-col cols="12" md="6" class="d-flex justify-center pr-md-10">            
           
            <div v-if="isMobile" class="slide-frame">
            <vue-slick-carousel  ref="slick" v-bind="settings">
              <div v-for="(image, index) in carouselImages" :key="index" class="slick-slide">
                <img :src="image" alt="Fragrance Image" class="slide-image">
              </div>
            </vue-slick-carousel>
          </div>

          <div v-if="!isMobile" class="image-container-start" @click="handleTap">
              <img
                :src="carouselImages[carouselIndex]"
                alt="Fragrance Image"
                class="rounded-image"
              />
              <div class="dots">
                <span
                  v-for="(image, index) in carouselImages"
                  :key="index"
                  class="dot"
                  :class="{ active: index === carouselIndex }"
                  @click="setCarouselIndex(index)"
                ></span>
              </div>
            </div>

      
          </v-col>



          <v-col cols="12" md="6" class="text-left pl-md-10">
            <h1 class="main-title">Discover your perfect perfume</h1>

<div v-if="isMobile">
<p class="description" style="display: flex; align-items: baseline;">
  <i class="mdi mdi-star" style="font-size: 1.4rem; margin-right: 25px; color: black;"></i>
  <span>
    <span class="highlight">Find your dream fragrance</span> within more than 3000 popular perfumes.
  </span>
</p>

<p class="description" style="display: flex; align-items: baseline;">
  <i class="mdi mdi-magnify" style="font-size: 1.4rem; margin-right: 25px; color: black;"></i>
  <span>
    <span class="highlight">Search and filter</span> by scent profile, notes, intensity, and discover millions of combinations.
  </span>
</p>

<p class="description" style="display: flex; align-items: baseline;">
  <i class="mdi mdi-heart" style="font-size: 1.3rem; margin-right: 25px; color: black;"></i>
  <span>
    <span class="highlight">Save your favorite perfumes</span> to your Favorites list.
  </span>
</p>

<p class="description" style="display: flex; align-items: baseline;">
  <i class="mdi mdi-lightbulb" style="font-size: 1.3rem; margin-right: 25px; color: black;"></i>
  <span>
    <span class="highlight">Get inspiration</span> and discover new perfumes that match to your personal taste.
  </span>
</p>

<p class="description" style="display: flex; align-items: baseline;">
  <i class="mdi mdi-camera" style="font-size: 1.3rem; margin-right: 25px; color: black;"></i>
  <span>
    <span class="highlight">Upload stunning perfume shots</span> and get promoted.
  </span>
</p>
</div>

<div v-if='!isMobile'>

       <p class="description"><span class="highlight"></span> <span class="highlight"> Find your dream fragrance </span>within more than 3000 popular perfumes.</p>              
            <p class="description"><span class="highlight">Search and filter</span> by scent profile, notes, intensity, and discover millions of combinations.</p>             
            <p class="description"><span class="highlight">Save your favorite perfumes </span> to your Favorites list. <span class="mdi mdi-heart" style="color:rgb(188, 188, 188);"></span> </p>
            <p class="description"><span class="highlight"> Get inspiration</span> and discover new perfumes that match to your personal taste.</p>
            <p class="description"><span class="highlight"> Upload your stunning perfume shots</span> and get promoted.</p>


</div>
<br>
<p class="description">
  <span 
    class="highlight clickable" 
    @click="showDemo = true"
  >
    <v-icon style="color:black">mdi-video</v-icon>&nbsp;Play demo 
  </span>
</p>



                  <!-- Added modal for video -->
        <v-dialog
          v-model="showDemo"
          max-width="800"
          class="demo-dialog"
          persistent
          @click:outside="showDemo = false"
        >
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showDemo = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div v-if="isMobile" class="video-container">
                <video
                  src="@/assets/startpage_images/full_vid_mobile2.mp4"
                  autoplay muted loop 
                  controlslist="nodownload nofullscreen noremoteplayback"
                  class="demo-video"
                ></video>
              </div>
              <div v-else class="video-container">
                <video
                  src="@/assets/startpage_images/full_vid.mp4"
                  autoplay muted loop playsinline poster
                  disablepictureinpicture
                  controlslist="nodownload nofullscreen noremoteplayback"
                  class="demo-video"
                ></video>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        
            <br>
            <img src="@/assets/icons/ios_white.svg" style="height: 60px; width: 177px; margin: 10px 0px;" @click="openAppStore">
            <br>
  
            <v-btn dark fab color="black" class="explore-button" @click="navigateToApp">
              <span>Start Now</span>
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
            
    
            <br>
          </v-col>
        </v-row>
        <br>
        
      </v-container>
      <br>

      <v-dialog v-model="showiOSDownloadPrompt" max-width="340">
  <v-card class="ios-download-card">
    <img src="@/assets/icons/ios_black.svg" style="height: 65px; width: 230px; margin: 20px 0px;" @click="openAppStore">
    
    <v-card-text class="ios-download-text">
      Enjoy a faster, smoother experience by using our iOS app.
    </v-card-text>
    <v-card-actions class="ios-download-actions">
      <v-btn color="black" @click="openAppStore" class="ios-download-btn">Download</v-btn>
      <v-btn text @click="dismissiOSPrompt">Not now</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>




    </v-main>
    

  </v-app>
</template>




<script>
import { Capacitor } from '@capacitor/core';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'StartPage',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      isMobile: window.innerWidth < 560, // Initialize based on screen size
      carouselIndex: 0,
      carouselImages: [
        require('@/assets/startpage_images/startpage_image1.webp'),
        require('@/assets/startpage_images/startpage_image2.webp'),
        require('@/assets/startpage_images/startpage_image3.webp'),
        require('@/assets/startpage_images/startpage_image4.webp'),
      ],
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 210,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        swipe: true,
        swipeToSlide: true,
        touchThreshold: 60,
      },
      showDemo: false, // Controls the visibility of the video modal
      carouselInterval: null, // Store interval for automatic cycling
      showiOSDownloadPrompt: false,
    };
  },
  methods: {
    isIOSApp() {
      return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    },
    navigateToApp() {
      this.$router.push({ name: 'PerfumeDataTable' });
    },
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.carouselIndex =
          (this.carouselIndex + 1) % this.carouselImages.length;
      }, 4000); // Change image every 4 seconds
    },
    stopCarousel() {
      clearInterval(this.carouselInterval);
    },
    setCarouselIndex(index) {
      this.carouselIndex = index;
      this.stopCarousel();
    },
    handleTap() {
      this.carouselIndex =
        (this.carouselIndex + 1) % this.carouselImages.length;
      this.stopCarousel();
    },


  dismissiOSPrompt() {
    sessionStorage.setItem("dismissedIOSPrompt", "true");
    this.showiOSDownloadPrompt = false;
  }
  
  },
  mounted() {
    if (this.isIOSApp()) {
      console.log('Running on iOS Native App');
    }
    this.startCarousel();

    if (this.isIOSBrowser() && !sessionStorage.getItem("dismissedIOSPrompt")) {
    setTimeout(() => {
      this.showiOSDownloadPrompt = true;
    }, 5000); // Show after 1 second
  }

  },
  
  beforeDestroy() {
    this.stopCarousel();
  },
  
};
</script>


<style scoped>
.startpage-container {
  min-height: 100vh;
  padding: 20px;
}

.rounded-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;

}

.image-container-start {
  width: 100%;
  overflow: hidden;
  border-radius: var(--radius-basic);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: relative;
  cursor: pointer; /* Add pointer cursor for tap indication */

  display: flex;
  justify-content: center; 
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 9px;
  height: 9px; /* Corrected height value */
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: white;
}


.app-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000;
}

.main-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 55px;
  line-height: 1.22;
}

.description {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: rgb(188, 188, 188);
  font-weight: bold;
  line-height: 1.4;
}

.description .highlight {
  color: #000;
}

.explore-button {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 16px;
  background-color: #000 !important;
  color: #fff;
  border-radius: var(--radius-buttons);
  padding: 12px 24px;
  width: 180px;
  height: 45px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.underline {
  text-decoration: underline;
}

.instagram-link {
  position: relative;
  top: 50px;
  right: 10px;
  z-index: 10;
  text-decoration: none;
}

.demo-button {
  margin-top: 10px;
  background-color: #ffffff !important;
  color: #000000;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: var(--radius-buttons);
  box-shadow: none !important;
}


.demo-dialog {
  /* Ensures dialog is centered and styled properly */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  overflow: hidden !important; /* Prevent scrolling */
  background-color: #ffffff !important;
}

.video-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  overflow: hidden;
  background-color: #ffffff !important;
  overflow: hidden !important; /* Prevent scrolling */
}

.demo-video {
  width: 100%; /* Makes the video responsive */
  height: auto; /* Maintains aspect ratio */
  border-radius: var(--radius-basic);
  object-fit: cover; /* Ensures proper scaling */
  box-shadow: none; /* Remove any shadows */
  background-color: #ffffff !important;
  overflow: hidden !important; /* Prevent scrolling */
}

.clickable {
  cursor: pointer; /* Displays the hand cursor to indicate clickability */
  /* Optional: Adds underline for clarity */
  color: black; /* Matches the icon color */
}

.clickable:hover {
  color: #555; /* Slight color change on hover for better feedback */
  text-decoration: none; /* Optional: Removes underline on hover */
}


.slide-frame{
width: 91.5vw;
max-width: 480px !important; 
margin-top: 10px;
margin-bottom:10px;
margin-left: 0px;
margin-right: 00px;
background-color: none;
border-radius: var(--radius-basic);
}


.slick-slide {
  height: 100%;
  color: #fff;
  font-size: 20px;
  background-color: none;
  border-radius: var(--radius-basic);
}

.slide-image {
  width: 100%; /* Make image take full width of the slide */
  max-height: 100%; /* Ensure image does not overflow slide's height */
  object-fit: contain; /* Ensure aspect ratio is maintained without cropping */
  border-radius: var(--radius-basic);
}

.ios-download-card {
  border-radius: var(--radius-basic);
  padding: 16px;
  text-align: center;
}

.ios-download-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ios-download-text {
  font-size: 14px;
  color: #555;
  padding: 10px 0;
}

.ios-download-actions {
  justify-content: center;
  padding-bottom: 10px;
}

.ios-download-btn {
  box-shadow: none;
  color: white;
  border-radius: var(--radius-buttons);
}





</style>
