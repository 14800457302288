import MiniSearch from 'minisearch';


export function performSearch(searchQuery, perfumes, type) {
    if (!searchQuery || typeof searchQuery !== 'string' || !searchQuery.trim()) {
        //console.log("No search query provided or query is invalid, displaying default dataset.");
        return perfumes; 
    }

    // Define a function to normalize text
    function normalizeText(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); 
    }

    // Check if the query contains any of the types and adjust boost for 'combined_types'
    const types = type;  // Using types from your Vue component or main file
    const queryContainsType = types.some(type => normalizeText(searchQuery).toLowerCase().includes(normalizeText(type).toLowerCase()));
    const combinedTypesBoost = queryContainsType ? 3 : 0.5;

    // Set of common stop words in perfume descriptions
    let stopWords = new Set([
        'and', 'or', 'to', 'in', 'a', 'the', 'la', 'le', 'des', 'du', 'un', 'une',
        /* Additional stop words as needed */
    ]);

    // Function to process terms including handling abbreviations and stop words
    function processTerm(term) {
        term = normalizeText(term).toLowerCase(); // Normalize and convert term to lower case
        term = term.replace(/\b1\b/g, 'one'); // Convert numeric '1' to 'one'
        return stopWords.has(term) ? null : term; // Filter out stop words
    }

    // Map of abbreviations to full brand names
    const brandAbbreviations = {
        'ck': 'calvin klein',
        'no5': 'n°5',
        'no 5': 'n°5',
        'chanel 5': 'n°5',
        'bulgari': 'bvlgari',
        'th': 'tommy hilfiger',
        'jc': 'jimmy choo',
        'ga': 'giorgio armani',
        'd&g': 'dolce & gabbana',
        'ysl': 'yves saint laurent',
        'mj': 'marc jacobs',
        'jpg': 'jean paul gaultier',
        'ch': 'carolina herrera',
        'pr': 'paco rabanne',
        'mm': 'maison margiela',
        'jml': 'jo malone london',
        'jm': 'jo malone',
        'cdg': 'comme des garçons',
        'adp': 'acqua di parma',
        'mfk': 'maison francis kurkdjian',
        'lv': 'louis vuitton',
        'cc': 'clive christian',
        'fm': 'frederic malle',
        'pdm': 'parfums de marly',
        'tf': 'tom ford'
    };

    // Replace abbreviations in the search query
    const queryWords = searchQuery.split(/\s+/);
    const processedQuery = queryWords.map(word => brandAbbreviations[normalizeText(word).toLowerCase()] || word).join(' ');

    // Detect if 'orange' is searched as an individual term for Etat Libre d'Orange problem
    const isOrangeQuery = processedQuery.trim().toLowerCase() === 'orange';

    // Adjust boost dynamically for hardcoded exception for Etat Libre d'Orange
    const brandBoost = isOrangeQuery ? 0.5 : 12.5; // Reduce brand boost when searching 'orange'
    const notesBoost = isOrangeQuery ? 5.0 : 1.5; // Increase notes boost for 'orange'


    // Initialize MiniSearch
    let miniSearch = new MiniSearch({
        fields: ['name', 'brand', 'notes', 'notes_strong', 'combined_types'], // Separate fields for notes
        storeFields: ['name', 'brand', 'notes', 'notes_strong', 'combined_types'],
        processTerm: processTerm, // Use the custom term processing function
        searchOptions: {
            fuzzy: term => term.length <= 3 ? 0 : term.length <= 5 ? 0.20 : term.length <= 9 ? 0.30 : 0.35,
            prefix: term => term.length > 2,
            combineWith: 'AND',
            boost: {
                name: 2.35,
                brand: brandBoost,
                notes: notesBoost,
                notes_strong: 1.51, // Smaller boost for notes_strong
                combined_types: combinedTypesBoost,  // Dynamic boosting
            },
            weights: {
                fuzzy: 0.06,
                prefix: 0.9
            }
        }
    });

  

    // Concatenate notes_medium and notes_strong for indexing
    function concatenateNotes(perfume) {
        return `${perfume.notes_medium}, ${perfume.notes_strong}`;
    }        
    
    // Index perfumes with normalized fields
    miniSearch.addAll(perfumes.map(perfume => ({
        id: perfume.id,
        name: normalizeText(perfume.name),
        brand: normalizeText(perfume.brand),
        notes: concatenateNotes(perfume), // Assuming 'notes' field does not need normalization for now
        notes_strong: normalizeText(perfume.notes_strong), // Separate field with lower boost
        combined_types: perfume.combined_types // Assuming 'notes' field does not need normalization for now
    })));

    
    if (searchQuery) {
        
            let miniResults = miniSearch.search(processedQuery);
            miniResults = miniResults.filter(result => result.score > 1);

            // Hardcoded exception for Etat Libre d'Orange
            if (isOrangeQuery) {
                miniResults = miniResults.filter(result => {
                    const perfume = perfumes.find(p => p.id === result.id);
                    return perfume && normalizeText(perfume.brand) !== "etat libre d'orange"; 
                });
            }
            
            if (miniResults.length > 0) {
                return miniResults.map(({ id }) => perfumes.find(perfume => perfume.id === id));
            } else {
                // Return an empty array if no results are found
                return [];
            }
        
}}