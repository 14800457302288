var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({ backgroundColor: _vm.$vuetify.theme.currentTheme.background })},[_c('v-main',[_c('v-container',{class:_vm.isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'},[_c('v-row',{class:_vm.isMobile ? 'px-1 pt-6 pl-3' : 'pb-3 pt-5',staticStyle:{"background-color":"none"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"searchInput",class:_vm.isMobile ? 'search-bar-mobile' : 'search-bar',staticStyle:{"border-radius":"var(--radius-search)"},attrs:{"dense":"","solo":"","flat":"","clearable":"","hide-details":"","background-color":"#efefef","prepend-inner-icon":"mdi-magnify","placeholder":"Search notes...","enterkeyhint":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.closeKeyboard.apply(null, arguments)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-row',{class:_vm.isMobile ? 'px-2' : 'px-0'},_vm._l((_vm.filteredNotes),function(note,index){return _c('v-col',{key:index,class:_vm.isMobile ? 'd-flex pa-2 pb-1' : 'd-flex pa-0 pb-4 image-inspiration',attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"custom-card",staticStyle:{"border-radius":"var(--radius-basic)"},attrs:{"flat":"","tile":""},on:{"click":function($event){return _vm.navigateToPerfumes(note)}}},[_c('v-lazy',{attrs:{"height":360,"options":{ rootMargin: '2000px 0px', threshold: 0.01 },"transition":"fade-transition"}},[_c('v-img',{staticClass:"square-image darken-image",class:_vm.isMobile ? 'square-image' : 'square-image darken-image',attrs:{"src":_vm.getImage(note),"eager":""}})],1),_c('v-card-title',{staticClass:"custom-card-title px-0 py-0"},[_vm._v(_vm._s(_vm.formatTitle(note)))])],1)],1)}),1),_c('v-btn',{staticClass:"transparent-btn",style:({
              bottom: _vm.isIOSApp() ? 'calc(' + _vm.buttonBottom + ' + 17px)' : _vm.buttonBottom,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              right: '20px',
              display: _vm.scrollY > 450 ? 'block' : 'none', /* Show only when scrolling down */
              }),attrs:{"fab":"","fixed":"","right":"","ripple":false},on:{"click":_vm.scrollToTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }