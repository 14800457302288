import { performSearch } from '@/utils/search.js';

export function filterPerfumes(scrollup = true, resetpagination = false) { //Search//
    let result = performSearch(this.searchQuery, this.perfumes, this.type); 

        if (this.selectedScentgender.length > 0) {
        result = result.filter(perfume => this.selectedScentgender.includes(perfume.scentgn));
        }

        if (this.selectedLongevity.length === 2) {
        const [minIndex, maxIndex] = this.selectedLongevity;
        const longevityFilters = this.longevityCategories.slice(minIndex - 1, maxIndex);
        result = result.filter(perfume => longevityFilters.includes(perfume.longevity));
        }
    
        if (this.selectedSillage.length === 2) {
        const [minIndex, maxIndex] = this.selectedSillage;
        const sillageFilters = this.sillageCategories.slice(minIndex - 1, maxIndex);
        result = result.filter(perfume => sillageFilters.includes(perfume.sillage));
        }

        if (this.selectedPrice.length === 2) {
        const [minIndex, maxIndex] = this.selectedPrice;
        const priceFilters = this.priceCategories.slice(minIndex - 1, maxIndex);
        result = result.filter(perfume => priceFilters.includes(perfume.price));
      }           
          
      if (this.selectedBrand.length > 0) {
      result = result.filter(perfume => this.selectedBrand.includes(perfume.brand));
    }
      
  // New logic that only allows 66% and 100% matches
  // New type filter logic with scoring
  const includedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'selected');
  const excludedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'excluded');

  // Apply scoring for included types
  if (includedTypes.length > 0) {
    result = result.map(perfume => {
      const perfumeTypes = [perfume.type1, perfume.type2, perfume.type3].filter(Boolean);
      let score = includedTypes.reduce((acc, type) => perfumeTypes.includes(type) ? acc + 1 : acc, 0);

      // Adjust score based on the number of included types
      if (includedTypes.length >= 3 && score === 1) {
        // Downgrade to score 0 if only one match found with 3 or more selections
        score = 0;
      } else if (includedTypes.length === 2 && score === 1) {
        // Set score to 0 when only 1 out of 2 matches
        score = 0;
      } else if (includedTypes.length === 2 && score === 2) {
        // Retain a score of 2 if two types are selected and both are matched
        score = 2;     
      } else if (score === 0) {
        // Ensure no score results are not manipulated
        score = 0;
      }

      return { ...perfume, score };
    });

    // Ensure score 0 is always filtered out
    const maxScore = Math.max(...result.map(perfume => perfume.score));
    result = result.filter(perfume => perfume.score === maxScore && perfume.score > 0);
  }

  // Exclude perfumes with any excluded type
  if (excludedTypes.length > 0) {
    result = result.filter(perfume => {
      const perfumeTypes = [perfume.type1, perfume.type2, perfume.type3].filter(Boolean);
      return !excludedTypes.some(type => perfumeTypes.includes(type));
    });
  }

  if (resetpagination) {
    this.resetPaginationAndScroll(); // Reset pagination
  }
  this.chipsKey += 1; // Update chipsKey to reset scroll position

    this.filteredPerfumes = result;
    this.sortPerfumes();    

    if (scrollup) {
      this.$nextTick(() => {
        setTimeout(() => {
          console.log('TopJump by filterPerfumes'); // Add console log
          window.scrollTo(0, 0);
        }, 10); // Delay by 10 milliseconds so that it works right after the Restored Scroll
      });
    }
    
  
}





export function previewFilteredPerfumes() {

  let result = performSearch(this.searchQuery, this.perfumes, this.type); 
  
  if (this.tempSelectedBrand.length > 0) {
    result = result.filter(perfume => this.tempSelectedBrand.includes(perfume.brand)); // Scent Gender filter
  }    
  if (this.tempSelectedScentgender.length > 0) {
    result = result.filter(perfume => this.tempSelectedScentgender.includes(perfume.scentgn)); // Scent Gender filter
  }   

  // Longevity Filtering Logic
  if (this.tempSelectedLongevity.length === 2) {
  const [minIndex, maxIndex] = this.tempSelectedLongevity;
  const longevityFilters = this.longevityCategories.slice(minIndex - 1, maxIndex);
  result = result.filter(perfume => longevityFilters.includes(perfume.longevity));
  }

// Projection (Sillage) Filtering Logic
  if (this.tempSelectedSillage.length === 2) {
  const [minIndex, maxIndex] = this.tempSelectedSillage;
  const sillageFilters = this.sillageCategories.slice(minIndex - 1, maxIndex);
  result = result.filter(perfume => sillageFilters.includes(perfume.sillage));
  }

  if (this.tempSelectedPrice.length === 2) {
      const [minIndex, maxIndex] = this.tempSelectedPrice;
      const priceFilters = this.priceCategories.slice(minIndex - 1, maxIndex); // Assuming minIndex and maxIndex are 1-based
      result = result.filter(perfume => priceFilters.includes(perfume.price));
  }
  
// New logic that does not allow 0% and 33% matches

    // New type filter logic with scoring
const includedTypes = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'selected');
const excludedTypes = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'excluded');

// Apply scoring for included types
if (includedTypes.length > 0) {
  result = result.map(perfume => {
    const perfumeTypes = [perfume.type1, perfume.type2, perfume.type3].filter(Boolean);
    let score = includedTypes.reduce((acc, type) => perfumeTypes.includes(type) ? acc + 1 : acc, 0);

      // Adjust score based on the number of included types
      if (includedTypes.length >= 3 && score === 1) {
      // Downgrade to score 0 if only one match found with 3 or more selections
      score = 0;
    } else if (includedTypes.length === 2 && score === 1) {
      // Set score to 0 when only 1 out of 2 matches
      score = 0;
    } else if (includedTypes.length === 2 && score === 2) {
      // Retain a score of 2 if two types are selected and both are matched
      score = 2;     
    } else if (score === 0) {
      // Ensure no score results are not manipulated
      score = 0;
    }

    return { ...perfume, score };
  });

  // Ensure score 0 is always filtered out
  const maxScore = Math.max(...result.map(perfume => perfume.score));
  result = result.filter(perfume => perfume.score === maxScore && perfume.score > 0);
}

// Exclude perfumes with any excluded type
if (excludedTypes.length > 0) {
  result = result.filter(perfume => {
    const perfumeTypes = [perfume.type1, perfume.type2, perfume.type3].filter(Boolean);
    return !excludedTypes.some(type => perfumeTypes.includes(type));
  });
}

  return result;
}